export function authHeader() {
    // return authorization header with jwt token

    let user = JSON.parse(localStorage.getItem('user'));

    let base64 = require('base-64');

    let username = "jestem";
    let password = "wolny";

    if (user && user.token) {
        let headers = new Headers();
        headers.append('Authorization', 'Basic ' + base64.encode(username + ":" + password) + ' Bearer ' + user.token)
        headers.append('Content-Type', 'application/json')
        headers.append('Access-Control-Allow-Origin', 'https://przewoz.cuslapy.pl')
        return headers
    } else {
        let headers = new Headers();
        console.log("aaa");
        return headers;
    }
}

export function authHeaderGet() {
    // return authorization header with jwt token

    let user = JSON.parse(localStorage.getItem('user'));

    let base64 = require('base-64');

    let username = "jestem";
    let password = "wolny";

    if (user && user.token) {
        let headers = new Headers();
        headers.append('Authorization', 'Basic ' + base64.encode(username + ":" + password) + ' Bearer ' + user.token)
        //headers.append('Content-Type', 'application/json')
        headers.append('Access-Control-Allow-Origin', 'https://przewoz.cuslapy.pl')
        return headers
    } else {
        let headers = new Headers();
        console.log("aaa");
        return headers;
    }
}


export function check_user() {
    // return authorization header with jwt token

    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.token) {
        return user
    }
    return false;
}



export function authBasicHeader() {
  let base64 = require('base-64');
  let headers = new Headers();
  let username = "jestem";
  let password = "wolny";
  headers.append('Authorization', 'Basic ' + base64.encode(username + ":" + password))
  headers.append('Content-Type', 'application/json')
  headers.append('Access-Control-Allow-Origin', 'https://przewoz.cuslapy.pl')
  return headers;
}


export function gotoLogin(obj) {
  localStorage.removeItem('user')
  obj.$router.push('/login/')
}

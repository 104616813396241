<template>
    <div class="home">


        <hr class="my-4">
        <div  class="text-left">
          <h5>Lista zamówień ({{items_length}}) <small>{{listing.username}}, strona: {{page}}</small></h5>
          <div class="clearfix" v-if="spinner">
            <b-spinner class="float-right" label="Floated Right"></b-spinner>
          </div>
          <b-list-group>
              <b-list-group-item v-bind:to="{ path: '/cms/order/' + item.public_uid }" :border-variant="item.state_color" class="flex-column align-items-start" v-for="item in items" :key="item.public_uid">

                <div  class="d-flex w-100 justify-content-between">
                  <h5 class="mb-1"><b-icon-arrow-right-square></b-icon-arrow-right-square> {{item.where.city}}, {{item.where.street}} {{item.where.nr}}</h5>
                  <!--<small><formatDate :value="item.created" fn="datetime"/></small>-->
                  <small><timeago :datetime="item.datetime_start"> </timeago> <b-icon-hourglass-split></b-icon-hourglass-split></small>

                </div>

                <p class="mb-1" >
                  <b-icon-stop></b-icon-stop> {{item.whence.city}}, {{item.whence.street}} {{item.whence.nr}}
                </p>

                <div v-if="item.original !== null"><b-icon-arrow-return-left></b-icon-arrow-return-left> przejazd powrotny</div>
                <div v-if="item.comeback.length !== 0"><b-icon-arrow-return-right></b-icon-arrow-return-right> posiada powrót</div>
              </b-list-group-item>

          </b-list-group>
        </div>
        <br>
        <b-button variant="light" v-on:click="prevFetchData"  :disabled="page == 1"> <b-icon-arrow-left-short></b-icon-arrow-left-short>Poprzednia strona</b-button>
        <b-button variant="light" v-on:click="nextFetchData" :disabled="items_length != 100">Następna strona <b-icon-arrow-right-short></b-icon-arrow-right-short></b-button>
        <br><br>
        <b-button onclick="window.history.go(-1); return false;" variant="outline-primary">Powrót</b-button>

    </div>
</template>


<script>
import { authHeaderGet } from '/src/_helpers/auth-header.js'



export default {
  name: 'cms',
  data () {
    return {
      items: [],
      show: false,
      listing: {},
      items_length: 0,
      spinner: true,
      offset: 0,
      page: 1,
    }
  },
  created () {
    this.fetchData()
  },
  watch: {
    '$route': 'fetchData'
  },
  methods: {
    fetchData () {
      this.spinner = true;
      var URL = "https://apiprzewoz.cuslapy.pl/transport/admin_transport/user/transports/" + this.$route.params.id + "?offset="+ this.offset;


      fetch(URL, {'headers': authHeaderGet()})
        .then(function(response) {
          if (response.status == 401) {
            console.log(response.status);
            localStorage.removeItem('user')
            location.reload()
          }
          else {
            response.json().then(function(json) {
              this.items = json.data["items"]
              this.listing = json.data["listing"]
              this.items_length = this.items.length
              this.show = true ? (this.items_length) : false
              this.spinner = false
            }.bind(this))
          }
        }.bind(this));
    },
    nextFetchData () {
      this.offset += 100
      this.fetchData()
      window.scrollTo(0,0);
      this.page += 1;
    },
    prevFetchData () {
      this.offset -= 100
      this.fetchData()
      window.scrollTo(0,0);
      this.page -= 1;
    }

  }

}
</script>

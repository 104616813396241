<script>
  import { check_user, authHeader } from '/src/_helpers/auth-header.js'
  import { quiz_answers } from '/src/_helpers/forms.js'


  export default {
    name: 'QuizTransport',
    data() {
      return {
        user: null,
        form: {
        },
        answer_options: quiz_answers(),
        errors: 0,
        success: 0,
        show: false,
        status: "",
        transport_url: "",
      }
    },
    mounted() {
      this.user = check_user()
      let URL = "https://apiprzewoz.cuslapy.pl/quiz/after_transport/"
      URL += this.$route.params.id+"/"
      this.transport_url = "/order/" + this.$route.params.id

      fetch(URL, {'headers': authHeader()})
        .then(function(response) {
          if (response.status == 401) {
            console.log(response.status);
            localStorage.removeItem('user')
            location.reload()
          }
          else if (response.status != 200) {
            response.json().then(function(json) {
              this.show = false
              this.status = json["message"]
            }.bind(this))
          }
          else {
            response.json().then(function(json) {
              this.show = true
            }.bind(this))
          }
        }.bind(this));

    },
    methods: {
     onSubmit(event) {
       event.preventDefault()

       let URL = "https://apiprzewoz.cuslapy.pl/quiz/after_transport/"
       URL += this.$route.params.id+"/";

       const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(this.form)
        };
        fetch(URL, requestOptions)
            .then(response => response.json())
            .then(json => {
              if (json.status != "ok") {
                this.errors = json.message;
                console.log(json.message)
                return;
              }
              console.log(json);
              this.errors = 0;
              this.success = 1;
              this.show = false;
              // this.user = json.user;
              // localStorage.setItem('user', JSON.stringify(json.user));
              // this.$router.push('/')
            });

         },
         onReset(event) {
           event.preventDefault()
           // Reset our form values
           // TODO
         },
   },
   computed: {
   },
  }

</script>

<template>

  <div class="text-left">

    <h3>
      Ankieta po zakończeniu usługi, oceń stopień zadowolenia z realizacji usługi.
    </h3>

    <b-button v-bind:to="transport_url" variant="outline-primary">Informacje o trasie przejazdu</b-button>

    <p>
    </p>

    <b-alert v-if="errors" show variant="danger">Błąd {{errors}}</b-alert>
    <b-alert v-if="status" show variant="info">{{status}}</b-alert>
    <b-alert show variant="success" v-if="success">Dziękujemy za wypełnienie ankiety, możesz dalej korzystać z serwisu.</b-alert>


    <b-form @submit="onSubmit" @reset="onReset" v-if="show">

      <b-form-group label="W jakim stopniu jest Pan/Pani zadowolona z realizacji usługi indywidualnego transportu door-to-door?" >
        <b-form-radio-group
          id="answer1"
          v-model="form.answer1"
          :options="answer_options"
          required
        ></b-form-radio-group>
      </b-form-group>
      <small>
        gdzie:<br>
          1 oznacza: nie jestem zadowolona,<br>
          5 oznacza: jestem bardzo zadowolona<br>
        <br>
      </small>

      <hr>

      <b-form-group label="W jakim stopniu usługa została dopasowana do Pani/Pana potrzeb?" >
        <b-form-radio-group
          id="answer2"
          v-model="form.answer2"
          :options="answer_options"
          required
        ></b-form-radio-group>
      </b-form-group>
      <small>
        gdzie:<br>
          1 oznacza: usługa nie została dopasowana do moich potrzeb<br>
          5 oznacza: usługa została dopasowana do moich potrzeb<br>
        <br>
      </small>

      <hr>

      <b-form-group label="Jak ocenia Pan/Pani jakość sprzętu używanego do realizacji usługi indywidualnego transportu door-to-door?" >
        <b-form-radio-group
          id="answer3"
          v-model="form.answer3"
          :options="answer_options"
          required
        ></b-form-radio-group>
      </b-form-group>
      <small>
        gdzie:<br>
          1 oznacza: bardzo źle<br>
          5 oznacza: bardzo dobrze<br>
        <br>
      </small>

      <hr>

      <b-form-group label="Jak ocenia Pan/Pani postępowanie obsługi pojazdu: kierowcy i asystenta?" >
        <b-form-radio-group
          id="answer4"
          v-model="form.answer4"
          :options="answer_options"
          required
        ></b-form-radio-group>
      </b-form-group>
      <small>
        gdzie:<br>
          1 oznacza: nie jestem zadowolona<br>
          5 oznacza: jestem bardzo zadowolona<br>
        <br>
      </small>


      <p></p>

      <b-button type="submit" variant="primary">Wyślij ankietę</b-button>
      <!--<b-button type="reset" variant="danger">Wyczyść</b-button>-->
    </b-form>
  
  </div>
</template>

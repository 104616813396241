<template>
    <div class="home">


        <div  class="text-left">


          <b-form-select v-model="filters.year_selected" :options="filters.year_options"></b-form-select>

          <b-form-select v-model="filters.month_selected" :options="filters.month_options" class="mt-3"></b-form-select>
          <hr>
          <p>
          <b-form-input v-model="filters.username" placeholder="Nazwa użytkownika"></b-form-input>
            </p>
          <b-form-input v-model="filters.email" placeholder="Email"></b-form-input>

          <p></p>
          <b-button v-on:click="onSearch()"  variant="secondary">Wyszukaj</b-button>

          <hr class="my-4">

          <h5>Użytkownicy ({{items_length}})</h5>

          <b-list-group>
              <b-list-group-item  v-bind:to="{ path: '/cms/user/transports/' + item.public_id }" :border-variant="item.state_color" class="flex-column align-items-start" v-for="item in items" :key="item.public_id">

                <div  class="d-flex w-100 justify-content-between">
                  <h5 class="mb-1"><b-icon-person></b-icon-person> <b>{{item.username}}</b></h5><!--dodany ręcznie: {{item.admin_add}}-->
                  <small>rejestracja: <formatDate :value="item.registered_on" fn="datetime"/> <b-icon-calendar2-date></b-icon-calendar2-date>
                    <br>
                    <!-- czy potwierdził rejestację: {{item.confirmed}}-->
                  </small>

                </div>

                <p class="mb-1" >
                  <b-icon-info></b-icon-info> wiek {{item.age}} ({{item.sex_text}}), <b-icon-telephone></b-icon-telephone> {{item.phone}} <span v-if="item.email">({{item.email}})</span>
                </p>



              </b-list-group-item>
          </b-list-group>
        </div>

    </div>
</template>


<script>
import { authHeaderGet } from '/src/_helpers/auth-header.js'



export default {
  name: 'CmsUsers',
  data () {
    return {
      items: [],
      filters: {
        month_selected: null,
        year_selected: null,
        month_options: [
            { value: null, text: 'Wybierz miesiąc' },
            { value: 1, text: 1},
            { value: 2, text: 2 },
            { value: 3, text: 3 },
            { value: 4, text: 4},
            { value: 5, text: 5 },
            { value: 6, text: 6 },
            { value: 7, text: 7},
            { value: 8, text: 8 },
            { value: 9, text: 9 },
            { value: 10, text: 10 },
            { value: 11, text: 11 },
            { value: 12, text: 12 },
        ],
        year_options: [
            { value: null, text: 'Wybierz rok' },
            { value: 2021, text: '2021' },
            { value: 2022, text: '2022' },
          ],
        username: "",
        email: ""
      }


    }
  },
  created () {
    this.fetchData()
  },
  watch: {
    '$route': 'fetchData'
  },
  methods: {
    onSearch(event) {
      this.fetchData()
    },
    fetchData() {
      var paramsArray = {
        username: this.filters.username,
        email: this.filters.email,
        year: this.filters.year_selected,
        month: this.filters.month_selected,
      }
      let URL = "https://apiprzewoz.cuslapy.pl/user/admin/users/"
      let params = "";
      for (var key in paramsArray) {
        if (paramsArray[key]) {
          params += key + "=" + paramsArray[key] + "&"
        }
      }
      if (params) {
        params = params.slice(0,-1)
        URL += "?" + params
      }


      fetch(URL, {'headers': authHeaderGet()})
        .then(function(response) {
          if (response.status == 401) {
            console.log(response.status);
            localStorage.removeItem('user')
            location.reload()
          }
          else {
            response.json().then(function(json) {
              console.log(json)
              this.items = json["data"]
              // this.listing = json.data["listing"]
              // this.listing.state_name = this.listing.state_name.toLowerCase()
              this.items_length = this.items.length
            }.bind(this))
          }
        }.bind(this));
    }

  }

}
</script>

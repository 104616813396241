<script>

  import { authBasicHeader } from '/src/_helpers/auth-header.js'
  let URL = "https://apiprzewoz.cuslapy.pl/user/register/"

  export default {
    name: 'Confirm',
    data() {
      return {
        success: false,
        errors: false,

      }
    },
    mounted() {
      URL += this.$route.query.token;


      fetch(URL, {'headers': authBasicHeader()})
        .then(response => response.json())
        .then(json => {
            if (json["status"] == "fail") {
              this.errors = true
              this.success = false
              this.message = json["message"]
            }
            else {
              this.errors = false
              this.success = true
              this.message = json["message"]
          }
        })


    },

  }

</script>

<template>

    <div class="text-left">
      <b-alert v-if="errors" show variant="danger">{{message}}</b-alert>
      <b-alert show variant="success" v-if="success">{{message}}</b-alert>

    </div>




</template>



export function quiz_answers() {
  return [
    { text: '1', value: 1},
    { text: '2', value: 2},
    { text: '3', value: 3},
    { text: '4', value: 4},
    { text: '5', value: 5}
  ]
}

export function type_help_options() {
  return [
    {text: "samodzielnie", value: 1},
    {text: "z opiekunem/opiekunką asystentem/asystentką osoby z niepełnosprawnościami, osobą zależną od użytkownika/użytkowniczki:", value: 2},
    {text: "z psem asystującym", value: 3},
  ]
}


export function destination_options() {
  return [
    {text: "społecznym, których celem jest nabycie, przywrócenie lub wzmocnienie kompetencji społecznych, zaradności, samodzielności i aktywności społecznej, m.in. poprzez udział w zajęciach w Centrum Integracji Społecznej (CIS), Klubie Integracji Społecznej (KIS), Dzienny Dom SENIOR+, Klub SENIOR+, Środowiskowy Dom Samopomocy, Uniwersytet III Wieku, Centrum opiekuńczo-mieszkalne, dostęp do kultury (kino, teatr itp.), spotkania integracyjne, inne;", value: 1},
    {text: "zawodowym, których celem jest pomoc w podjęciu decyzji dotyczącej wyboru lub zmiany zawodu, wyposażenie w kompetencje i kwalifikacje zawodowe oraz umiejętności pożądane na rynku pracy (poprzez m.in. udział w zajęciach w CIS, KIS, kursy i szkolenia zawodowe), pomoc w utrzymaniu zatrudnienia;", value: 2},
    {text: "edukacyjnym, których celem jest wzrost poziomu wykształcenia, dostosowanie wykształcenia do potrzeb rynku pracy (m.in. edukacja formalna);", value: 3},
    {text: "zdrowotnym, których celem jest wyeliminowanie lub złagodzenie barier zdrowotnych utrudniających funkcjonowanie w społeczeństwie lub powodujących oddalenie od rynku pracy.", value: 4},
  ]
}


export function need_help_in_deliver_options() {
  return [
    { text: 'Tak', value: true},
    { text: 'Nie', value: false},
  ]
}

export function rules1_options() {
  return [
    { text: 'Tak', value: true},
    { text: 'Nie', value: false},
  ]
}

export function true_false_options() {
  return [
    { text: 'Tak', value: true},
    { text: 'Nie', value: false},
  ]
}

export function man_woman_options() {
  return [
    { text: 'Kobieta', value: 1},
    { text: 'Mężczyzna', value: 2},
  ]
}

export function register_question1_options() {
  return [
    { text: 'Gazeta Łapska', value: 1 },
    { text: 'strona internetowa: www.lapy.pl', value: 2 },
    { text: 'strona internetowa: www.mopslapy.pl', value: 3 },
    { text: 'ulotki, plakaty', value: 4 },
    { text: 'pracownicy UM, MOPS', value: 5 },
    { text: 'radni Rady Miejskiej, sołtysi', value: 6 },
    { text: 'inne (jakie?)', value: 7 },
  ]
}

export function destination_name_options() {
  return [
    { text: 'Centrum Integracji Społecznej (CIS)', value: "1" },
    { text: 'Klub Integracji Społecznej (KIS)', value: "2" },
    { text: 'Dzienny Dom Senior +', value: "3" },
    { text: 'Klub Senior +', value: "4" },
    { text: 'Środowiskowy Dom Samopomocy (ŚDS)', value: "12" },
    { text: 'Uniwersytet III Wieku', value: "5" },
    { text: 'Centrum opiekuńczo - mieszkalne', value: "6" },
    { text: 'OKF', value: "7" },
    { text: 'Placówki służby zdrowia', value: "8" },
    { text: 'Placówki kulturalne (Dom Kultury, kino, teatr)', value: "9" },
    { text: 'Placówki edukacyjne', value: "10" },
    { text: 'Inne', value: "11" },
  ]
}


export function transport_user_options() {
  return [
    { text: 'Wyszukaj', value: true},
    { text: 'Dodaj nowy', value: false},
  ]
}

<template>
    <div class="home">

      <b-container>
    <b-row class="justify-content-md-center" >
      <b-col col lg="2"><img :src="require('../assets/FE_POWER.jpg')"/></b-col>
      <b-col cols="10" md="auto"><img :src="require('../assets/logo_polska_flaga.png')"/></b-col>
      <b-col col lg="2"><img :src="require('../assets/UE_EFS.jpg')"/></b-col>
    </b-row>
    </b-container>

        <b-jumbotron  header-level=6 header="Świadczenie usług transportowych DOOR-TO-DOOR" lead="">
          <p>w ramach projektu pt.</p>
          <p><b>„Usługi indywidualnego transportu door-to-door w Gminie Łapy”.</b></p>

          <hr class="my-4">
          <p>
            Projekt realizowany w ramach Umowy o przyznanie grantu w ramach projektu Państwowego Funduszu Rehabilitacji Osób Niepełnosprawnych pn. „Usługi indywidualnego transportu door-to-door oraz poprawa dostępności architektonicznej wielorodzinnych budynków mieszkalnych” dofinansowanego ze środków Programu Operacyjnego Wiedza Edukacja Rozwój 2014-2020 Działanie 2.8 Rozwój usług społecznych świadczonych w środowisku lokalnym.
          </p>
          <b-button variant="primary" href="/docx/regulamin_dtd2.docx">Regulamin</b-button>
          <b-button v-if="!user" variant="success" to="/register">Zarejestruj się</b-button>
        </b-jumbotron>

    </div>
</template>


<script>
import { check_user } from '/src/_helpers/auth-header.js'

export default {
  name: 'home',
  data () {
    return {
      user: {},
    }
  },
  mounted () {
    this.user = check_user()
  },
}
</script>

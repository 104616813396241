<script>
  import { check_user, authHeader, authHeaderGet } from '/src/_helpers/auth-header.js'
  import { transport_user_options, help_options, destination_name_options, destination_options, need_help_in_deliver_options, rules1_options, type_help_options, rue_false_options, man_woman_options, register_question1_options, true_false_options } from '/src/_helpers/forms.js'
  import _ from 'lodash'

  export default {
    name: 'CmsNew',
    data() {
      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      const minDate = new Date(today)

      return {
        user: null,
        form: {
          whence: {},
          where: {},
          info: '',
          date_start: '',
          hour_start: '',
          type_help_list: [],
          destination_list: [],
          address: {street: " "}
        },
        errors: 0,
        success: 0,
        show: true,
        sex_options: man_woman_options(),
        type_help_selected: null,
        type_help_options: type_help_options(),
        need_help_in_deliver_options: need_help_in_deliver_options(),
        destination_selected: null,
        destination_options: destination_options(),
        question1_options: register_question1_options(),
        niepelno_options: true_false_options(),
        destination_name_options: destination_name_options(),
        min: minDate,
        items: [],
        show_transports: false,
        users_options: [],
        transport_user_options: transport_user_options(),
        h2_label: "Dodaje ręcznie transport",
        date_start_label: "Na kiedy transport (data)",
        hour_start_label: "Na kiedy transport (godzina)",
        user_assigned: false,
        public_uid: null,
      }
    },
    mounted() {
      this.user = check_user()

      this.public_uid = this.$route.params.id
      if (this.public_uid) {
        this.getTransport(this.public_uid)
      }
    },
    methods: {
      getTransport(public_uid) {
        let URL = "https://apiprzewoz.cuslapy.pl/transport/admin_transport/for_return/"+public_uid+"/"
        fetch(URL, {'headers': authHeader()})
          .then(function(response) {
            if (response.status != 200) {
              response.json().then(function(json) {
                this.show = false
                this.status = json["message"]
              }.bind(this))
            }
            else {
              response.json().then(function(json) {
                this.form.need_help_in_deliver = json["need_help_in_deliver"]
                this.form.date_start = json["date_start"]
                let whence = json["whence"]
                let where = json["where"]
                let hour_start = json["hour_start"]
                this.form.whence = where
                if (!where.street) {
                  this.form.whence.street = ""
                }
                this.form.where = whence
                if (!whence.street) {
                  this.form.where.street = ""
                }
                this.form.hour_start = ""
                this.form.origin_public_uid = public_uid
                this.date_start_label = "Dzień powrotu"
                this.hour_start_label = "Godzina powrotu"
                this.label_h2 = "Dodaj powrót"
                this.form.destination_name = json["destination_name"]
                this.form.destination_list = json["destination_list"]
                this.form.type_help_list = json["type_help_list"]
                this.form.type_help_text = json["type_help_text"]
                this.user_assigned = true
                this.form.user = json["client"]
                this.form.is_user = true
                this.form.info = json["info"]
              }.bind(this))
            }
          }.bind(this));
      },
      fetchTransportOnThisDay  () {
        this.spinner = true
        let URL = "https://apiprzewoz.cuslapy.pl/transport/admin_transport/transport_day/?date_start=" + this.form.date_start

        fetch(URL, {'headers': authHeaderGet()})
          .then(function(response) {
            response.json().then(function(json) {
              this.items = json.data["items"]
              this.items_length = this.items.length
              this.spinner = false
              this.show_transports = true
            }.bind(this))

          }.bind(this));


      },
     onSubmit(event) {
       event.preventDefault()
       //alert(JSON.stringify(this.form))

       if (this.public_uid)
          var URL = "https://apiprzewoz.cuslapy.pl/transport/admin_transport/for_return/" + this.public_uid + "/"
       else {
          var URL = "https://apiprzewoz.cuslapy.pl/transport/admin_transport/"
       }

       const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(this.form)
        };
        fetch(URL, requestOptions)
            .then(response => response.json())
            .then(json => {
              console.log("json:", json);
              if (json.status != "ok") {
                this.errors = json.message;
                return;
              }
              console.log(json);
              this.errors = 0;
              this.success = 1;
              this.show = false;
              // this.user = json.user;
              // localStorage.setItem('user', JSON.stringify(json.user));
              // this.$router.push('/')
            });
         },
         onReset(event) {
           event.preventDefault()
           // Reset our form values
           this.form = {
             whence: {},
             where: {},
             info: '',
             date_start: '',
             hour_start: '',
             type_help_list: [],
             destination_list: [],
             address: {}
           }
         },
         encode_utf8(s) {
            return unescape(encodeURIComponent(s));
          },
         onSearchUser(search, loading) {
            if(search.length) {
              loading(true);
              this.search(loading, search, this);
            }
          },
          search: _.debounce((loading, search, vm) => {
            var encode_utf8 = `${escape(search)}`
            fetch(
              `https://apiprzewoz.cuslapy.pl/user/admin/search/${search}`, {'headers': authHeaderGet()}
            ).then(res => {
              res.json().then(json => (vm.users_options = json.data));
              loading(false);
            });
          }, 350)
   },
   computed: {
  }
  }

</script>

<template>

  <div class="text-left">
    <h2>{{h2_label}}</h2>
    <br>
    <b-alert v-if="errors" show variant="danger">Błąd {{errors}}</b-alert>
    <b-alert show variant="success" v-if="success">Zlecenie zostało dodane i znajduje się w zakładce 'Nowe'</b-alert>

    <b-form @submit="onSubmit" @reset="onReset" v-if="show">

      <b-form-group id="input-group-2" :label="date_start_label" label-for="date_start" description="Podaj datę wyjazdu">
        <b-form-datepicker
          id="date_start"
          v-model="form.date_start"
          placeholder=""
          class="mb-2"
          locale="pl"
          required
        ></b-form-datepicker>
      </b-form-group>

      <div>
          <b-button size="sm" v-on:click="fetchTransportOnThisDay()">Pokaż zlecenia w tym dniu</b-button>
          <div v-if="show_transports">
            <br>
              <h5>Lista przejazdów ({{items_length}}):</h5>

              <b-list-group>
                  <b-list-group-item class="flex-column align-items-start" v-for="t in items" :key="t.public_uid">
                    <b><formatDate :value="t.datetime_start" fn="time"/></b> | {{t.where.city}} | {{t.where.street}} {{t.where.nr}} {{t.where.second_number}} | <small>{{t.status.text}}</small>
                  </b-list-group-item>

              </b-list-group>
          </div>
      </div>
      <br>

      <b-form-group id="input-group-2" :label="hour_start_label" label-for="hour_start" description="Podaj godzinę wyjazdu">
        <b-form-timepicker
          id="hour_start"
          v-model="form.hour_start"
          placeholder=""
          class="mb-2"
          locale="pl"
          required
        ></b-form-timepicker>
      </b-form-group>


      <b-card bg-variant="light">
        <b-form-group
          label-cols-lg="3"
          label="Skąd"
          label-size="lg"
          label-class="font-weight-bold pt-0"
          class="mb-0"
        >

          <b-form-group
            label="Miejscowość:"
            label-for="nested-street"
            label-cols-sm="3"
            label-align-sm="right"
          >
          <b-form-input
            id="city"
            v-model="form.whence.city"
            type="text"
            placeholder=""
            required
          ></b-form-input>

          </b-form-group>


          <b-form-group
            label="Ulica"
            label-for="nested-street"
            label-cols-sm="3"
            label-align-sm="right"
          >
          <b-form-input
            id="street"
            v-model="form.whence.street"
            type="text"
            placeholder=""
          ></b-form-input>
            </b-form-group>


            <b-form-group
              label="Numer:"
              label-for="nested-nr"
              label-cols-sm="3"
              label-align-sm="right"
            >
            <b-form-input
              id="nr"
              v-model="form.whence.nr"
              type="text"
              placeholder=""
              required
            ></b-form-input>
              </b-form-group>

              <b-form-group
                label="Numer lokalu:"
                label-for="nested-nr"
                label-cols-sm="3"
                label-align-sm="right"
              >
              <b-form-input
                id="second_number"
                v-model="form.whence.second_number"
                type="text"
                placeholder=""
              ></b-form-input>
              </b-form-group>

        </b-form-group>
       </b-card>

       <p></p>

       <b-card bg-variant="light">
         <b-form-group
           label-cols-lg="3"
           label="Dokąd"
           label-size="lg"
           label-class="font-weight-bold pt-0"
           class="mb-0"
         >


           <b-form-group
             label="Miejscowość:"
             label-for="nested-street"
             label-cols-sm="3"
             label-align-sm="right"
           >
           <b-form-input
             id="city"
             v-model="form.where.city"
             type="text"
             placeholder=""
             required
           ></b-form-input>

           </b-form-group>

           <b-form-group
             label="Ulica:"
             label-for="nested-street"
             label-cols-sm="3"
             label-align-sm="right"
           >
           <b-form-input
             id="street"
             v-model="form.where.street"
             type="text"
             placeholder=""
           ></b-form-input>
             </b-form-group>

             <b-form-group
               label="Numer budynku:"
               label-for="nested-street"
               label-cols-sm="3"
               label-align-sm="right"
             >
             <b-form-input
               id="nr"
               v-model="form.where.nr"
               type="text"
               placeholder=""
               required
             ></b-form-input>
               </b-form-group>


               <b-form-group
                 label="Numer lokalu:"
                 label-for="nested-street"
                 label-cols-sm="3"
                 label-align-sm="right"
               >
               <b-form-input
                 id="second_number"
                 v-model="form.where.second_number"
                 type="text"
                 placeholder=""
               ></b-form-input>
            </b-form-group>



         </b-form-group>
        </b-card>

      <hr>

      <b-form-group label="Czy potrzebna jest pomoc w dotarciu z mieszkania do pojazdu" >
        <b-form-radio-group
          id="need_help_in_deliver"
          v-model="form.need_help_in_deliver"
          :options="need_help_in_deliver_options"
          required
        ></b-form-radio-group>
      </b-form-group>

      <b-form-group
     label="Czy osoba z potrzebą wsparcia w zakresie mobilności będzie korzystać z usługi transportowej:"
     v-slot="{ ariaDescribedby }"
   >
     <b-form-checkbox-group
       v-model="form.type_help_list"
       :options="type_help_options"
       :aria-describedby="ariaDescribedby"
       name="flavour-2a"
       stacked
     ></b-form-checkbox-group>
   </b-form-group>

   <b-form-group  id="input-group-2" label="Dane osoby (imię i nazwisko)" label-for="info" v-if="form.type_help_list.includes(2)">
     <b-form-input
       id="type_help_text"
       v-model="form.type_help_text"
       placeholder=""
       type="text"
     ></b-form-input>
   </b-form-group>

      <b-form-group id="input-group-2" label="Dodatkowe informacje (widoczne tylko dla admin i kierowcy)" label-for="info">
        <b-form-input
          id="info"
          v-model="form.info"
          placeholder=""
        ></b-form-input>
      </b-form-group>

      <b-form-group
     label="Przypisz cel podróży do możliwych form aktywizacji społeczno-zawodowej / usług aktywnej integracji"
     v-slot="{ ariaDescribedby }"
   >
     <b-form-checkbox-group
       v-model="form.destination_list"
       :options="destination_options"
       :aria-describedby="ariaDescribedby"
       name="flavour-2a"
       stacked
     ></b-form-checkbox-group>
   </b-form-group>

   <b-form-group label="Podaj miejsce docelowe:" >
     <b-form-radio-group
       id="destination_name"
       v-model="form.destination_name"
       :options="destination_name_options"
       required
     ></b-form-radio-group>
   </b-form-group>

   <br>
   <hr>

   <h4><b-icon-person-fill></b-icon-person-fill> Użytkownik</h4><br>

   <div v-if="user_assigned">
     {{ form.user.username }} ({{form.user.address.city}})

   </div>
   <div v-else>
   <b-form-group label="" >
     <b-form-radio-group
       id="is_user"
       v-model="form.is_user"
       :options="transport_user_options"
       required
     ></b-form-radio-group>
   </b-form-group>

   <label v-if="form.is_user == true">Wyszukaj pasażera</label>

   <v-select v-if="form.is_user == true" label="name" :filterable="false" v-model="form.user" :options="users_options" @search="onSearchUser">
   <template slot="no-options">
     wpisz imię lub nazwisko  lub email
   </template>
   <template slot="option" slot-scope="option">
     <div class="d-center">
       {{ option.preview }}
     </div>
   </template>
   <template slot="selected-option" slot-scope="option">
     <div class="selected d-center">
       {{ option.preview }}
     </div>
   </template>
 </v-select>

      <hr>

      <b-card bg-variant="light" v-if="form.is_user == false">

        <b-form-group
          label-cols-lg="3"
          label="Pasażer"
          label-size="lg"
          label-class="font-weight-bold pt-0"
          class="mb-0"
        >

        <b-form-group
          id="input-group-1"
          label="Imię i nazwisko"
          label-for="username"
          description=""
        >
          <b-form-input
            id="username"
            v-model="form.username"
            type="text"
            placeholder=""
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-2"
          label="Email/login"
          label-for="email"
          description="Wymagany do logowania się w serwisie."
        >
          <b-form-input
            id="email"
            v-model="form.email"
            type="email"
            placeholder=""
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-2"
          label="Wiek"
          label-for="age"
          description=""
        >
          <b-form-input
            id="age"
            v-model="form.age"
            type="number"
            placeholder=""
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-2"
          label="Telefon"
          label-for="phone"
          description=""
        >
          <b-form-input
            id="phone"
            v-model="form.phone"
            type="tel"
            placeholder=""
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Czy jesteś osobą niepełnosprawną?" >
          <b-form-radio-group
            id="niepelno"
            v-model="form.is_disab"
            :options="niepelno_options"
            required
          ></b-form-radio-group>
        </b-form-group>


        <b-form-group
          id="input-group-2"
          label=""
          label-for="disab"
          description=""
          v-if="form.is_disab == true"
        >
          <b-form-input
            id="disab"
            v-model="form.disab"
            type="text"
            placeholder="Podaj kod niepełnosprawności "
            description="Wskazanie potrzeby wsparcia w zakresie mobilności uzasadniającej skorzystanie z usługi (np. niepełnosprawność lub inne)."
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-2"
          label=""
          label-for="disab"
          description=""
          v-if="form.is_disab == false"
        >
          <b-form-input
            id="disab"
            v-model="form.disab"
            type="text"
            placeholder="Podaj inne ograniczenia w zakresie poruszania się"
            description="Wskazanie potrzeby wsparcia w zakresie mobilności uzasadniającej skorzystanie z usługi (np. niepełnosprawność lub inne)."
            required
          ></b-form-input>
        </b-form-group>


        <b-form-group label="Czy osoba zamawiająca porusza się na wózku inwalidzkim?" >
          <b-form-radio-group
            id="wheelchair"
            v-model="form.is_wheelchair"
            :options="niepelno_options"
            required
          ></b-form-radio-group>
        </b-form-group>

        <b-form-group label="Płeć" v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            id="sex"
            v-model="form.sex"
            :options="sex_options"
            :aria-describedby="ariaDescribedby"
            name="radio-options"
            required
          ></b-form-radio-group>
        </b-form-group>

        <b-form-group label="Skąd dowiedziałeś/dowiedziałaś się o usłudze door-to-door (proszę o zaznaczenie właściwej odpowiedzi):" v-slot="{ ariaDescribedby }">
          <b-form-checkbox-group
            id="checkbox-group-1-question_1"
            v-model="form.answers_list"
            :options="question1_options"
            :aria-describedby="ariaDescribedby"
            name="flavour-1"
            stacked
          ></b-form-checkbox-group>
        </b-form-group>

        <b-form-group
          id="checkbox-group-1-question_1_addional"
          label=""
          label-for="question_1_additional"
          description=""
        >
          <b-form-input
            id="question_1_additional"
            v-model="form.answers_additional"
            type="text"
            placeholder=""
          ></b-form-input>
        </b-form-group>

        </b-form-group>
      </b-card>

      <b-card bg-variant="light" v-if="form.is_user == false">
            <b-form-group
              label-cols-lg="3"
              label="Pasażer - adres zamieszkania"
              label-size="lg"
              label-class="font-weight-bold pt-0"
              class="mb-0"
            >

            <b-form-group
              label="Miejscowość:"
              label-for="nested-street"
              label-cols-sm="3"
              label-align-sm="right"
            >
            <b-form-input
              id="city"
              v-model="form.address.city"
              type="text"
              placeholder=""
              required
            ></b-form-input>
            </b-form-group>

            <b-form-group
              label="Ulica:"
              label-for="nested-street"
              label-cols-sm="3"
              label-align-sm="right"
            >
            <b-form-input
              id="street"
              v-model="form.address.street"
              type="text"
              placeholder=""

            ></b-form-input>
              </b-form-group>

            <b-form-group
              label="Numer budynku:"
              label-for="nested-street"
              label-cols-sm="3"
              label-align-sm="right"
            >
            <b-form-input
              id="number"
              v-model="form.address.number"
              type="text"
              placeholder=""
              required
            ></b-form-input>
            </b-form-group>


            <b-form-group
              label="Nume lokalu:"
              label-for="nested-street"
              label-cols-sm="3"
              label-align-sm="right"
            >
            <b-form-input
              id="second_number"
              v-model="form.address.second_number"
              type="text"
              placeholder=""
            ></b-form-input>
            </b-form-group>

              <b-form-group
                label="Gmina:"
                label-for="nested-street"
                label-cols-sm="3"
                label-align-sm="right"
              >
              <b-form-input
                id="code"
                v-model="form.address.code"
                type="text"
                placeholder=""
                required
              ></b-form-input>
              </b-form-group>

            </b-form-group>
           </b-card>
      </div>

      <br>
      <br>
      <b-button type="submit" variant="primary">Dodaj</b-button>
      <b-button type="reset" variant="danger">Wyczyśc pola</b-button>
    </b-form>



  </div>
</template>

<template>
    <div class="text-left">

      <b-button v-b-toggle.stats1 >Statystyki kierowcy:</b-button>
      <b-collapse id="stats1" class="mt-2">
        <b-card>
          <h5>Średnia wszystkich <b-badge>{{results.stats1.dimension_km}}</b-badge> km</h5>
          <h5>Suma kilometrów <b-badge>{{results.stats1.all_km}}</b-badge> km</h5>
          <h5>Najdłuższa trasa <b-badge>{{results.stats1.max_km}}</b-badge> km</h5>
          <h5>Najkrótsza trasa <b-badge>{{results.stats1.min_km}}</b-badge> km</h5>
          <hr>
          <h5>Suma czasu przejazdów <b-badge>{{results.stats1.all_time}}</b-badge> min</h5>
          <h5>Najdłuższy czasu przejazdu <b-badge>{{results.stats1.max_time}}</b-badge> min</h5>
          <h5>Nakrótszy czasu przejazdu <b-badge>{{results.stats1.min_time}}</b-badge> min</h5>
          <hr>
          <h5>Suma czasu oczekiwania <b-badge>{{results.stats1.all_time_waiting}}</b-badge> min</h5>
          <h5>Najdłuższy czasu oczekiwania <b-badge>{{results.stats1.max_time_waiting}}</b-badge> min</h5>
          <h5>Nakrótszy czasu oczekiwania <b-badge>{{results.stats1.min_time_waiting}}</b-badge> min</h5>
        </b-card>
      </b-collapse>


      <hr>

      <b-button v-b-toggle.stats2 >Statystyki kierowcy, przejechane kilomety w miesiącu:</b-button>
      <b-collapse id="stats2" class="mt-2">
        <b-card>
          <h3>Miesiąc</h3>
          <ul>
            <li v-for="(year, month) in results.stats2.months" :key="year"> <h6>{{ month }}.{{year.year}} - wszystkich: {{year.distance}} km, min: {{year.distance_min}} km, max: {{year.distance_max}} km, liczba przejazdów: {{year.num}}</h6></li>
        </ul>
        </b-card>
      </b-collapse>



      <div class="text-center" v-if="spinner">
          <b-spinner variant="primary" label="Text Centered"></b-spinner>
      </div>
    </div>
</template>


<script>

import { authHeaderGet } from '/src/_helpers/auth-header.js'

export default {
  name: 'StatsByDrivers',
  data () {
    return {
      user: {},
      spinner: false,
      stats1: {},
      results: {"stats1": {}, "stats2": {}, "stats3": {"answers": {}, "answers_text": []}, "stats4": {}, "stats5": {}},
    }
  },
  mounted() {
   this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
     if ( collapseId=="stats1" && isJustShown) {
        var url = "https://apiprzewoz.cuslapy.pl/stats/admin/stats_by_driver/km/"
        this.fetchStats("stats1", url)
     }
     if ( collapseId=="stats2" && isJustShown) {
        var url = "https://apiprzewoz.cuslapy.pl/stats/admin/stats_by_driver_by_month/km/"
        this.fetchStats("stats2", url)
     }
   })
 },
  methods: {
    fetchStats(name_stats, url) {
      this.spinner = true
      fetch(url, {'headers': authHeaderGet()})
        .then(function(response) {
          response.json().then(function(json) {
            console.log(json.data);
            this.spinner = false
            this.results[name_stats] = json
          }.bind(this))

        }.bind(this));


    }

  }

}
</script>

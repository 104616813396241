<template>
    <div class="text-left">

      <div class="text-center" v-if="spinner">
          <b-spinner variant="primary" label="Text Centered"></b-spinner>
      </div>

      <h3>Rok</h3>
      <ul>
        <li v-for="(year, count) in items.years" :key="year"> <h6>{{ count }} <b-badge>{{year}}</b-badge></h6></li>
    </ul>

    <h3>Miesiąc</h3>
    <ul>
      <li v-for="(year, count) in items.months" :key="year"> <h6>{{ count }} <b-badge>{{year}}</b-badge></h6></li>
  </ul>

  <h3>Dzień</h3>
  <ul>
    <li v-for="(year, count) in items.days" :key="year"> <h6>{{ count }} <b-badge>{{year}}</b-badge></h6></li>
</ul>

    </div>
</template>


<script>

import { authHeaderGet } from '/src/_helpers/auth-header.js'

export default {
  name: 'StatsByDays',
  data () {
    return {
      user: {},
      spinner: true,
      items: []
    }
  },
  created () {
    this.fetchData()
  },
  watch: {
    '$route': 'fetchData'
  },
  methods: {
    fetchData  () {
      let URL = "https://apiprzewoz.cuslapy.pl/stats/admin/stats_by_years/"
      fetch(URL, {'headers': authHeaderGet()})
        .then(function(response) {
          if (response.status == 401) {
            console.log(response.status);
            localStorage.removeItem('user')
            location.reload()
          }
          else {
            response.json().then(function(json) {
              this.items = json
              console.log(json.data);
              this.spinner = false
            }.bind(this))
          }
        }.bind(this));


    }

  }

}
</script>

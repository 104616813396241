<template>
    <div class="home">

        <hr class="my-4">
        <div v-if="show" class="text-left">
          <h5>Lista archiwalnych zamówień ( strona {{page}}) </h5>
          <b-list-group>
              <b-list-group-item :variant="item.status.color" v-bind:to="get_order_url + item.public_uid" :border-variant="item.state_color" class="flex-column align-items-start" v-for="item in items" :key="item.public_uid">

                <div  class="d-flex w-100 justify-content-between">
                  <h5 class="mb-1"><b-icon-arrow-right-square></b-icon-arrow-right-square> {{item.where.city}}, {{item.where.street}}</h5>
                  <small><timeago :datetime="item.datetime_start"></timeago> <b-icon-hourglass-split></b-icon-hourglass-split></small>
                </div>

                <div  class="d-flex w-100 justify-content-between">
                  <h6 class="mb-1"><b-icon-stop></b-icon-stop>  {{item.whence.city}}, {{item.whence.street}} {{item.whence.nr}}</h6>
                  <span>{{item.status.text}}</span>
                </div>
                <p>{{item.info}}</p>

              </b-list-group-item>
          </b-list-group>

        </div>
        <div v-else>
          Nie posiadasz nic w archiwum.
        </div>

        <br>
        <div v-if="show">
        <b-button variant="light" v-on:click="prevFetchData"  :disabled="page == 1"> <b-icon-arrow-left-short></b-icon-arrow-left-short>Poprzednia strona</b-button>
        <b-button variant="light" v-on:click="nextFetchData" :disabled="items_length != 50">Następna strona <b-icon-arrow-right-short></b-icon-arrow-right-short></b-button>
        </div>
        <br><br>
    </div>
</template>


<script>
import { authHeader, check_user, authHeaderGet } from '/src/_helpers/auth-header.js'







export default {
  name: 'Archiwum',
  data () {
    return {
      items: [],
      show: false,
      user: {},
      offset: 0,
      items_length: 0,
      page: 1,
    }
  },
  mounted () {
      this.user = check_user()
      if (this.user.account_type == 2) {
        window.location.href = '/cms/delivers/1/'
      }
    },
    computed: {
      get_order_url() {
        if (this.user.account_type == 3) {
          return "/driver_order/"
        }
        return "/order/"
      },
      can_add_order() {
        return this.user.account_type == 1
      }
    },
    watch: {
      '$route': 'fetchData'
    },
    created () {
      this.fetchData()
    },
    methods: {
      fetchData () {
        let URL = "https://apiprzewoz.cuslapy.pl/transport/client_transport_archive/?offset=" + this.offset

        fetch(URL, {'headers': authHeaderGet()})
          .then(function(response) {
            if (response.status == 401) {
              console.log(response.status);
              localStorage.removeItem('user')
              location.reload()
            }
            else {
              response.json().then(function(json) {

                this.items = json.data
                this.items_length = this.items.length

                this.show = true ? (json.data.length > 0) : false
              }.bind(this))
            }
          }.bind(this));
      },
      nextFetchData () {
        this.offset += 50
        this.fetchData()
        window.scrollTo(0,0);
        this.page += 1;
      },
      prevFetchData () {
        this.offset -= 50
        this.fetchData()
        window.scrollTo(0,0);
        this.page -= 1;
      }
    }
}
</script>

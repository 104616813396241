// routes.js
import home from '@/components/home'
import Question from '@/components/Question'
import Legal from '@/components/Legal'
import Clausure from '@/components/Clausure'
import Order from '@/components/Order'
import Login from '@/components/Login'
import Register from '@/components/Register'
import New from '@/components/New'
import Confirm from '@/components/Confirm'
import cms from '@/components/cms'
import CmsOrder from '@/components/CmsOrder'
import CmsNew from '@/components/CmsNew'
import CmsUsers from '@/components/CmsUsers'
import CmsDrivers from '@/components/CmsDrivers'
import QuizTransport from '@/components/QuizTransport'
import RemarkTransport from '@/components/RemarkTransport'
import PropositionTransport from '@/components/PropositionTransport'
import DriverOrder from '@/components/DriverOrder'
import Archiwum from '@/components/Archiwum'
import CmsRemarks from '@/components/CmsRemarks'
import CmsPropositions from '@/components/CmsPropositions'
import CmsQuizAfterTransport from '@/components/CmsQuizAfterTransport'
import ChangePassword from '@/components/ChangePassword'
import ChangeData from '@/components/ChangeData'
import ResetPassword from '@/components/ResetPassword'
import ResetPasswordAction from '@/components/ResetPasswordAction'
import Delivers from '@/components/Delivers'
import StatsByDays from '@/components/StatsByDays'
import StatsByUsers from '@/components/StatsByUsers'
import CmsUserTransport from '@/components/CmsUserTransport'
import Contact from '@/components/Contact'
import StatsByTransports from '@/components/StatsByTransports'
import DriverDelivers from '@/components/DriverDelivers'
import StatsByDrivers from '@/components/StatsByDrivers'


export const routes = [
  {
    path: "/",
    name: "home",
    component: home
  },
  {
    path: "/pytania-i-odpowiedzi",
    name: "question",
    component: Question
  },
  {
    path: "/kontakt",
    name: "contact",
    component: Contact
  },
  {
    path: "/rejestracja-klauzura",
    name: "clauserr",
    component: Clausure
  },
  {
    path: "/polityka-prywatnosci-i-cookies",
    name: "legal",
    component: Legal
  },
  {
    path: "/przejazdy/",
    name: "deliver",
    component: Delivers
  },
  {
    path: "/zlecenia/",
    name: "driverdeliver",
    component: DriverDelivers
  },
  {
    path: "/archiwum/",
    name: "Archiwum",
    component: Archiwum
  },
  {
    path: "/zmien_haslo/",
    name: "ZmienHaslo",
    component: ChangePassword
  },
  {
    path: "/resetuj_haslo/",
    name: "ResetujHaslo",
    component: ResetPassword
  },
  {
    path: "/resetowanie_hasla/",
    name: "ResetujHasloAction",
    component: ResetPasswordAction
  },
  {
    path: "/konto/",
    name: "ZmienUstawienia",
    component: ChangeData
  },
  {
    path: "/orders",
    name: "orders",
    component: Order
  },
  {
    path: "/order/:id",
    name: "order",
    component: Order
  },
  {
    path: "/driver_order/:id",
    name: "driver_order",
    component: DriverOrder
  },
  {
    path: "/cms/delivers/:id",
    name: "cms_delivers",
    component: cms
  },
  {
    path: "/cms/user/transports/:id",
    name: "cms_user_transports",
    component: CmsUserTransport
  },
  // {
  //   path: "/cms/driver/transports/:id",
  //   name: "cms_driver_transports",
  //   component: CmsDriverTransport
  // },
  {
    path: "/cms/order/:id",
    name: "cms_order",
    component: CmsOrder
  },
  {
    path: "/quiz_for_transport/:id",
    name: "quiz_for_transport",
    component: QuizTransport
  },
  {
    path: "/ankieta_po_zakonczeniu/:id",
    name: "quiz_for_transport",
    component: QuizTransport
  },
  {
    path: "/remark_for_transport/:id",
    name: "remark_for_transport",
    component: RemarkTransport
  },
  {
    path: "/proposition_for_transport/",
    name: "proposition_for_transport",
    component: PropositionTransport
  },
  {
    path: "/cms/users/",
    name: "cms_users",
    component: CmsUsers
  },
  {
    path: "/cms/drivers/",
    name: "cms_drivers",
    component: CmsDrivers
  },
  {
    path: "/cms/remarks/",
    name: "cms_remarks",
    component: CmsRemarks
  },
  {
    path: "/cms/propositions/",
    name: "cms_propositions",
    component: CmsPropositions
  },
  {
    path: "/cms/quizes_after_transport/",
    name: "cms_quizes_after_transport",
    component: CmsQuizAfterTransport
  },
  {
    path: "/register/confirm",
    name: "confirm",
    component: Confirm
  },
  {
    path: "/cms/stats_by_days/",
    name: "cms_stats_by_days",
    component: StatsByDays
  },
  {
    path: "/cms/stats_by_users/",
    name: "cms_stats_by_users",
    component: StatsByUsers
  },
  {
    path: "/cms/stats_by_transports/",
    name: "cms_stats_by_transports",
    component: StatsByTransports
  },
  {
    path: "/cms/stats_by_drivers/",
    name: "cms_stats_by_drivers",
    component: StatsByDrivers
  },
  { path: '/cms/new', component: CmsNew, name: "cms_new" },
  { path: '/cms/order/return/:id', component: CmsNew, name: "cms_return_new" },
  { path: '/new', component: New, name: "new" },
  { path: '/powrot/:id', component: New, name: "new_comeback" },
  { path: '/login', component: Login, name: "login" },
  { path: '/register', component: Register, name: "register" },
  { path: '*', redirect: '/' }
]



<template>

<div v-if="item.date_start">

    <b-jumbotron  header-level="5">
        <template #header>Zlecenie #{{item.id}} {{item.testy}}</template>

        <hr class="my-4">

        <p>
            Adres początkowy: <b>{{ item.whence.city }}, {{ item.whence.street }} {{ item.whence.nr }} </b> <span v-if="item.whence.second_number">nr. lokalu: {{ item.whence.second_number }}</span>
            <b-icon-arrow-right-square></b-icon-arrow-right-square>
        </p>

        <hr class="my-4">

        <p>
            Adres docelowy: <b>{{ item.where.city }}, {{ item.where.street }} {{ item.where.nr }} </b> <span v-if="item.whence.second_number">nr. lokalu: {{ item.where.second_number }}</span>
            <b-icon-stop></b-icon-stop>
        </p>

        <hr class="my-4">

        <p>
            Data wyjazdu: <b> {{item.date_start}} </b>
            <b-icon-calendar2-date></b-icon-calendar2-date>
        </p>

        <p>
            Godzina: {{item.hour_start}}
            <b-icon-clock></b-icon-clock>
        </p>

        <p v-if="comeback_button()">
            Powrót: <b-button  variant="outline-primary" :to="{ path: comeback_button() }" size="sm">Zobacz powrót</b-button>

        </p>
        <p v-if="show_comeback_button()" >
          <b-button variant="outline-primary" :to="{ path: '/powrot/' + item.public_uid }" size="sm">Dodaj powrót</b-button>
        </p>
        <p v-if="original_button()">
            Powrót: <b-button  variant="outline-primary" :to="{ path: original_button() }" size="sm">Zobacz pierwotny przyjazd</b-button>

        </p>

        <hr class="my-4">

        <p>
            <b>Status:</b>
            <b-alert :variant="item.state_color" show>{{item.state_text}}</b-alert>

        </p>

        <p v-if="item.state==4">
            <b-button :to="after_transport_url" variant="outline-primary">Wypełnij ankietę</b-button>&nbsp;
            <b-button :to="remark_url" variant="outline-primary">Złoż reklamację</b-button>

        </p>

        <div v-if="item.all_annotations.length">
            <h5 v-if="item.all_annotations.length">Powód rezygnacji:</h5>
            <b-container class="bv-example-row">
            <b-row>
              <b-col></b-col>
              <b-col>
                <b-list-group>
                    <b-list-group-item class="flex-column align-items-start" v-for="item in item.all_annotations" :key="item.id">
                        <p class="mb-1">
                          <formatDate :value="item.added" fn="datetime"/> <b>{{ item.user.username }}</b>
                          <br>
                          {{ item.text }}
                      </p>
                    </b-list-group-item>

                </b-list-group>
              </b-col>
              <b-col></b-col>
            </b-row>
          </b-container>

        </div>
        <p v-if="item.user_can_add_annotation">
            <b-button v-b-modal.modal-prevent-closing>Anuluj zgłoszenie</b-button>
        </p>

        <b-modal id="modal-prevent-closing" ref="modal" title="Podaj powód anulacji zgłoszenia." @show="resetModal" @hidden="resetModal" @ok="handleOk">
            <form ref="form_cancel" @submit.stop.prevent="handleSubmit">
                <b-form-group label="Tutaj wpisz treść" label-for="name-input" invalid-feedback="Podaj powód rezygnacji" :state="annotation_text_state">
                    <b-form-input id="name-input" v-model="form.annotation_text" :state="annotation_text_state" required></b-form-input>
                </b-form-group>
            </form>
        </b-modal>

        <!-- <b-button v-if="show_accept_work" v-on:click="onAcceptWork" variant="success">Bierz zadanie</b-button>
      <b-button v-if="show_finish_work" v-on:click="onFinishWork" variant="danger">Zakończ zadanie</b-button> -->
    </b-jumbotron>

    <b-button onclick="window.history.go(-1); return false;" variant="outline-primary">Powrót</b-button>

</div>
<div v-else>
    <div class="text-center">
        <b-spinner variant="primary" label="Text Centered"></b-spinner>
    </div>
</div>

</template>

<script>

import {
    authHeader
}
from '/src/_helpers/auth-header.js'


export default {
    name: 'Order',
    data() {
        return {
            show: true,
            title: '...',
            address: null,
            show_finish_work: true,
            show_accept_work: true,
            item: {
                date_start: null,
                all_annotations: [],
                user_can_add_annotation: false
            },
            form: {
                annotation_text: ""
            },
            annotation_text_state: null,
            transport_public_uid: null,
        }
    },
    watch: {
        // call again the method if the route changes
        '$route': 'fetchData'
    },
    created() {
        this.fetchData()
    },
    methods: {
            handleOk(bvModalEvt) {
                bvModalEvt.preventDefault()
                this.handleSubmit()
            },
            resetModal() {
                this.form.annotation_text = ""
                this.annotation_text_state = null
            },
            handleSubmit() {
                // Exit when the form isn't valid
                if (!this.checkFormValidity()) {
                    return
                }
                console.log("ddd=2")
                this.sendCancelData()
                this.$nextTick(() => {
                    this.$bvModal.hide('modal-prevent-closing')
                })
            },
            checkFormValidity() {
                const valid = this.$refs.form_cancel.checkValidity()
                this.annotation_text_state = valid
                return valid
            },
            sendCancelData() {
                const URL = "https://apiprzewoz.cuslapy.pl/transport/client_transport/"+ this.transport_public_uid +"/cancel/"
                const requestOptions = {
                    method: 'POST',
                    headers: authHeader(),
                    body: JSON.stringify({
                        "annotation_text": this.form.annotation_text,
                        "transport_publid_uid": this.transport_public_uid
                    })
                };
                fetch(URL, requestOptions)
                    .then(response => response.json())
                    .then(json => {
                      if (json.status != "ok") {
                        this.errors = json.message;
                        console.log(json.message)
                        return;
                      }
                      this.fetchData()
                      this.item.user_can_add_annotation = false
                });

            },
            fetchData() {

                var URL = "https://apiprzewoz.cuslapy.pl/transport/client_transport/"

                this.transport_public_uid = this.$route.params.id
                URL += this.transport_public_uid;


                fetch(URL, {
                        'headers': authHeader()
                    })
                    .then(function(response) {
                        if (response.status == 401) {
                            localStorage.removeItem('user')
                            location.reload()
                        } else if (response.status != 200) {
                            console.log(response.json())
                        } else {
                            response.json().then(function(json) {

                                this.item.id = json["id"];
                                this.item.public_uid = json["public_uid"];
                                this.item.date_start = json["date_start"]
                                this.item.hour_start = json["hour_start"]
                                this.item.hour_end = json["hour_end"]
                                this.item.where = json["where"]
                                this.item.whence = json["whence"]
                                this.item.state = json["state"]
                                this.item.state_text = json["state_text"]
                                this.item.state_color = json["state_color"]
                                // this.remark_url = "/remark_for_transport/" + json["public_uid"]
                                this.item.all_annotations = json["all_annotations"]
                                this.item.user_can_add_annotation = json["user_can_add_annotation"]
                                this.item.comeback = json["comeback"]
                                this.item.original = json["original"]
                                //console.log(this.item)
                            }.bind(this))
                        }
                    }.bind(this));
            },
            show_comeback_button() {
              return this.item.original == null && this.item.comeback.length == 0 && this.item.state != 4
            },
            comeback_button() {
              if (this.item.comeback.length == 0) {
                return false;
              }
              return "/order/" + this.item.comeback[0]["public_uid"]
            },
            original_button() {
              if (this.item.original == null) {
                return false;
              }
              return "/order/" + this.item.original.public_uid
            }

    },
    computed: {
      remark_url() {
        return "/remark_for_transport/" + this.item.public_uid
      },
      after_transport_url() {
        return "/ankieta_po_zakonczeniu/" + this.item.public_uid
      },
    },

}

</script>

import Vue from 'vue'
import App from './App.vue'
import Router from 'vue-router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import formatComponent from "@/components/Utils"
import {routes} from './routes'
import VueTimeago from 'vue-timeago'
import vSelect from 'vue-select'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'

Vue.config.productionTip = false

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-select/dist/vue-select.css';

import moment from 'moment'

Vue.use(Router)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueTimeago, {
  name: 'Timeago', // Component name, `Timeago` by default
  locale: 'pl-PL', // Default locale
  locales: {
    'pl-PL': require('date-fns/locale/pl'),
  }
})
Vue.use(VueLodash, { name: 'custom' , lodash: lodash })



const router = new Router(
    {routes: routes, mode:'history'}
)


router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login', '/register', '/register/confirm', '/resetuj_haslo/', '/resetowanie_hasla/', '/', '/polityka-prywatnosci-i-cookies', '/pytania-i-odpowiedzi', '/rejestracja-klauzura', '/kontakt'];
  //let url = getMainPath(to.path)
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  console.log(to.path);
  if (authRequired && !loggedIn) {
    localStorage.setItem('redirect_url', to.path)
    return next('/login');
  }

  next();
})


Vue.component('formatDate', formatComponent);
Vue.component('v-select', vSelect)
//Vue.component("v-select", VueSelect.VueSelect);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

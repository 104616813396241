<script>
import { authHeader } from '/src/_helpers/auth-header.js'



  export default {
    name: 'DriverOrder',
    data() {
      return {
        show: true,
        form: {annotation_text: "Nieobecność użytkownika"},
        title: '...',
        address: null,
        show_finish_work: true,
        show_accept_work: true,
        item: {client: {}, date_start: null, destination_list: "", distance:"", time_trevel: "", waiting_time_for_person: ""},
        distance: "",
        errors: false,
        status: false,
        spinner: false,
        annotation_text_state: null,
      }
    },
    watch: {
      '$route': 'fetchData',
    },
    created() {
      this.fetchData()
    },
    methods: {
          handleOk(bvModalEvt) {
              bvModalEvt.preventDefault()
              this.handleSubmit()
          },
          resetModal() {
              //this.form.annotation_text = ""
              this.annotation_text_state = null
          },
          handleSubmit() {
              // Exit when the form isn't valid
              if (!this.checkFormValidity()) {
                  return
              }
              this.sendCancelData()
              this.$nextTick(() => {
                  this.$bvModal.hide('modal-prevent-closing')

              })

          },
          checkFormValidity() {
              const valid = this.$refs.form_cancel.checkValidity()
              this.annotation_text_state = valid
              return valid
          },
          sendCancelData() {
              this.status = ""
              const URL = "https://apiprzewoz.cuslapy.pl/transport/admin_transport/" + self.public_uid + "/cancel/"
              const requestOptions = {
                  method: 'POST',
                  headers: authHeader(),
                  body: JSON.stringify({
                      "annotation_text": this.form.annotation_text
                  })
              };
              fetch(URL, requestOptions)
                  .then(response => response.json())
                  .then(json => {
                      if (json.status != "ok") {
                          this.errors = json.message
                          return;
                      }
                      this.status = "Anulowano zgłoszenie - OK"
                      this.item.user_can_add_annotation = false
                      this.fetchData();
                  });



          },

      onChangeStatus(event) {
        this.status = ""
        this.spinner = true
        let waiting_time_for_person = 0
        if (this.item.waiting_time_for_person)
          waiting_time_for_person = parseInt(this.item.waiting_time_for_person)

        let URL = "https://apiprzewoz.cuslapy.pl/transport/admin_transport/change_status/"+self.public_uid+"/"
        const requestOptions = {
         method: 'PUT',
         headers: authHeader(),
         body: JSON.stringify({"state": this.item.status.value, "distance": parseInt(this.item.distance), "waiting_time_for_person": waiting_time_for_person, "time_trevel": parseInt(this.item.time_trevel)})
         };
         fetch(URL, requestOptions)
             .then(response => response.json())
             .then(json => {
               // console.log("json:", json);
               this.spinner = false
               if (json.status != "ok") {
                 this.status = json.message
                 this.$forceUpdate()
                 return;
               }
               this.status = "Status zmieniony";
               this.item.status = json["data"]
               this.$forceUpdate()
          });
      },
      fetchData() {
        this.status = ""
        this.spinner = true

        self.public_uid = this.$route.params.id
        let URL = "https://apiprzewoz.cuslapy.pl/transport/client_transport/"

        URL += self.public_uid;

        fetch(URL, {'headers': authHeader()})
          .then(function(response) {
            this.spinner = false
            if (response.status == 401) {
              console.log(response.status);
              localStorage.removeItem('user')
              location.reload()
            }
            else if (response.status != 200) {
              console.log(response.json())
            }
            else {
              response.json().then(function(json) {
                this.item.id = json["id"];
                this.item.date_start = json["date_start"]
                this.item.hour_start = json["hour_start"]
                this.item.hour_end = json["hour_end"]
                this.item.whence = json["whence"]
                this.item.where = json["where"]
                this.item.status = json["status"]
                this.item.user = json["client"]
                this.item.rules1 = this.response_status(json["rules1"])
                this.item.destination_list = json["destination_list"]
                this.item.type_help_list = json["type_help_list"]
                this.item.need_help_in_deliver = this.response_status(json["need_help_in_deliver"])
                this.item.type_help_text = json["type_help_text"]
                this.item.distance = json["distance"]
                this.item.time_trevel = json["time_trevel"]
                this.item.waiting_time_for_person = json["waiting_time_for_person"]
                this.item.info = json["info"]
                this.item.destination_name = json["destination_name"]

                this.item.type_help_list_human = json["type_help_list_human"]
                this.item.destination_list_human = json["destination_list_human"]
                this.item.destination_name_human = json["destination_name_human"]
                this.$forceUpdate()
              }.bind(this))
            }
          }.bind(this));
      },

      response_status(state) {
        if (Boolean(state)) {
          return "tak"
        }
        return "nie"
      }
    },
    computed: {
      sms_message() {
        return "sms:"+this.item.user.phone+";?&body=Twój transport CUS Łapy, właśnie czeka na Ciebie pod umówionym adresem: "+ this.item.whence.city +", "+ this.item.whence.street +" "+ this.item.whence.nr
      },
    },
  }

</script>

<template>

  <div v-if="item.date_start">

    <b-alert v-if="errors" show variant="danger">{{errors}}</b-alert>
    <b-alert v-if="status" show variant="info">{{status}}</b-alert>

    <div class="text-center" v-if="spinner">
        <b-spinner variant="primary" label="Text Centered"></b-spinner>
    </div>

    <b-container class="bv-example-row">
      <b-row>
        <b-col>
          <b-jumbotron header-level="5">
            <template #header >Zlecenie #{{item.id}} {{item.testy}}</template>

            <template #lead>
             {{ item.title }}
           </template>

            <hr class="my-4">

            <p>
              <b>Adress początkowy:</b> {{ item.whence.city }}, {{ item.whence.street }} {{ item.whence.nr }}
            </p>

            <hr class="my-4">

            <p>
              <b>Adress końcowy:</b> {{ item.where.city }}, {{ item.where.street }}  {{ item.where.nr }}
            </p>

            <hr class="my-4">

            <p>
              <b>Dzień:</b> {{item.date_start}}
            </p>

            <p>
              <b>Godzina:</b> {{item.hour_start}}
            </p>

            <p>
              <b>Powrót:</b> {{item.hour_end}}
            </p>

            <hr class="my-4">

            <p>
                Czy potrzebna jest pomoc w dotarciu z mieszkania do pojazdu: <b>{{item.need_help_in_deliver}}</b>
            </p>

            <p>
              Czy osoba z potrzebuje wsparcia
              <b>{{item.type_help_list_human}}</b>
            </p>

            <p>
              Opiekun: <b>{{item.type_help_text}}</b>
            </p>


            <p>
              Przypisz cel podróży do możliwych form aktywizacji społeczno-zawodowej
              <b>{{item.destination_list_human}}</b>
            </p>

            <p>
                Cel podróży/nazwa instytucji:
                <b>{{item.destination_name_human}}</b>
            </p>


            <!-- <b-button v-if="show_accept_work" v-on:click="onAcceptWork" variant="success">Bierz zadanie</b-button>
            <b-button v-if="show_finish_work" v-on:click="onFinishWork" variant="danger">Zakończ zadanie</b-button> -->
          </b-jumbotron>
        </b-col>
        <b-col>
          <p>
            <b>Status:</b>
            <b-alert :variant="item.status.color" show>{{item.status.text}}</b-alert>

          </p>
          <b-jumbotron bg-variant="light" text-variant="grey" header-level=5  class="text-left">
            <template #header >Użytkownik</template>

            <b-container class="bv-example-row">
              <b-row>
                <b-col>Użytkownik:</b-col>
                <b-col>{{item.user.username}}</b-col>
              </b-row>
              <b-row>
                <b-col>Email:</b-col>
                <b-col>{{item.user.email}}</b-col>
              </b-row>
              <b-row>
                <b-col>tel:</b-col>
                <b-col>{{item.user.phone}} </b-col>
              </b-row>
              <b-row>
                <b-col>wiek</b-col>
                <b-col>{{item.user.age}}</b-col>
              </b-row>
              <b-row>
                <b-col>płeć</b-col>
                <b-col>{{item.user.sex_text}}</b-col>
              </b-row>
              <b-row>
                <b-col>Niepełnospr.:</b-col>
                <b-col>{{item.user.disab}}</b-col>
              </b-row>
              <b-row>
                <b-col>Admin</b-col>
                <b-col>{{item.user.admin_add}}</b-col>
              </b-row>

              <b-row>
                <b-col><b>Informacja od CUS</b></b-col>
                <b-col>{{item.info}}</b-col>
              </b-row>

            </b-container>

          </b-jumbotron>

          <b-jumbotron  bg-variant="light" text-variant="grey" header-level=5>
            <template #header >Zarządzaj</template>

            <b-alert v-if="errors" show variant="danger">{{errors}}</b-alert>
            <b-alert v-if="status" show variant="info">{{status}}</b-alert>

            <b-form-group
              label="Dystans"
              label-for="name"
              label-cols-sm="3"
              label-align-sm="right"
            >
            <b-form-input
              id="name"
              v-model="item.distance"
              type="number"
              placeholder="km"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="Czas przejazdu"
              label-for="name"
              label-cols-sm="3"
              label-align-sm="right"
            >
            <b-form-input
              id="name"
              v-model="item.time_trevel"
              type="number"
              placeholder="minuty"

              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="Czas oczekiwania na osobę"
              label-for="name"
              label-cols-sm="3"
              label-align-sm="right"
            >
            <b-form-input
              id="name"
              v-model="item.waiting_time_for_person"
              type="number"
              placeholder="minuty"

              ></b-form-input>
            </b-form-group>

            <b-button v-if="item.status.button_text" v-on:click="onChangeStatus" variant="success">{{item.status.button_text}}</b-button>
            <b-button v-if="item.user.phone" v-bind:href="sms_message" >Wyślij sms o przybyciu</b-button>

            <div style="margin-top:30px">
            </div>
            <p>
              <b>Status:</b>
              <b-alert :variant="item.status.color" show>{{item.status.text}}</b-alert>

            </p>

            <br>
            <b-button variant="danger" v-b-modal.modal-prevent-closing>Anuluj zgłoszenie</b-button>

            <hr>

          </b-jumbotron>

        </b-col>

      </b-row>
    </b-container>

    <div>
        <b-modal id="modal-prevent-closing" ref="modal" title="Podaj powód anulacji zgłoszenia." @show="resetModal" @hidden="resetModal" @ok="handleOk">
            <form ref="form_cancel" @submit.stop.prevent="handleSubmit">
                <b-form-group label="Tutaj wpisz treść" label-for="name-input" invalid-feedback="Podaj powód rezygnacji" :state="annotation_text_state">
                    <b-form-input id="name-input" v-model="form.annotation_text" :state="annotation_text_state" required></b-form-input>
                </b-form-group>
            </form>
        </b-modal>
    </div>


    <b-button  to="/" variant="outline-primary">Powrót</b-button>

  </div>
  <div v-else>
    <div class="text-center">
      <b-spinner variant="primary" label="Text Centered"></b-spinner>
    </div>
  </div>

</template>

<template>
    <div class="home">


        <div  class="text-left">

          <hr class="my-4">

          <h5>Zgłoszone uwagi ({{items_length}})</h5>

          <b-list-group>
              <b-list-group-item  class="flex-column align-items-start" v-for="item in items" :key="item.id">

                <div  class="d-flex w-100 justify-content-between">
                  <h5 class="mb-1"><b-icon-person></b-icon-person> <b-link href="#foo">{{item.user.username}}</b-link></h5> <b-link href="#foo"></b-link>
                  <small>dodany: <formatDate :value="item.added" fn="datetime"/> <b-icon-calendar2-date></b-icon-calendar2-date></small>
                </div>
                <p v-if="item.transport">
                  Dotyczy przejazdu: <b-link v-bind:to="{ path: '/cms/order/' + item.transport.public_uid }">{{item.transport.public_uid}}</b-link>
                </p>

                <p class="mb-1" >
                  <b-icon-chat-left-text></b-icon-chat-left-text>
                  <ul id="example-2">
                    <li><b>{{item.text1_label}}</b> {{item.text1}}</li>
                    <li><b>{{item.text2_label}}</b> {{item.text2}}</li>
                    <li><b>{{item.text3_label}}</b> {{item.text3}}</li>
                  </ul>
                </p>

                  <p class="mb-1" v-if="item.address">
                    ulica: {{item.address.street}}, miejscowość: {{item.address.city}}, imię i nazwisko: {{item.address.name}}, tel: {{item.address.phone}}, email: {{item.address.email}}
                  </p>


              </b-list-group-item>
          </b-list-group>
        </div>

    </div>
</template>


<script>
import { authHeaderGet } from '/src/_helpers/auth-header.js'



export default {
  name: 'CmsRemarks',
  data () {
    return {
      items: [],
      items_length: 0
    }
  },
  created () {
    this.fetchData()
  },
  watch: {
    '$route': 'fetchData'
  },
  methods: {
    onSearch(event) {
      this.fetchData()
    },
    fetchData() {
      let URL = "https://apiprzewoz.cuslapy.pl/quiz/admin/remarks/"

      fetch(URL, {'headers': authHeaderGet()})
        .then(function(response) {
          if (response.status == 401) {
            console.log(response.status);
            localStorage.removeItem('user')
            location.reload()
          }
          else {
            response.json().then(function(json) {
              console.log(json)

              this.items = json["data"]
              // this.listing = json.data["listing"]
              // this.listing.state_name = this.listing.state_name.toLowerCase()
              this.items_length = this.items.length
            }.bind(this))
          }
        }.bind(this));
    }

  }

}
</script>

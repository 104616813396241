<template>
    <div class="text-left">
	     <h3><b-icon-question-circle></b-icon-question-circle> Najczęściej zadawane pytania</h3>

       <ol>
         <li><a href="#q1" >Kto może skorzystać z usługi?</a></li>
         <li><a href="#q2" >Czy uczestnikiem programu usługi door-to-door może być osoba niepełnosprawna intelektualnie, ale sprawna ruchowo?</a></li>
         <li><a href="#q3" >Czy z transportu może korzystać niepełnosprawne dziecko?</a></li>
         <li><a href="#q4" >W jaki sposób można zamówić transport?</a></li>
         <li><a href="#q5" >Gdzie można pojechać transportem door-to-door?</a></li>
         <li><a href="#q6" >W jakim celu można podróżować transportem door-to-door?</a></li>
         <li><a href="#q7" >Czy przejazd jest płatny?</a></li>
         <li><a href="#q8" >Czy z osobą niepełnosprawną może podróżować opiekun?</a></li>
         <li><a href="#q9" >Czy osoba niepełnosprawna może podróżować z psem asystującym?</a></li>
         <li><a href="#q10" >Jak długo trzeba czekać na zatwierdzenie przejazdu?</a></li>
         <li><a href="#q11" >Czy można odwołać zamówioną usługę?</a></li>
       </ol>

<br><br>
<h3 id="q1">1. Kto może skorzystać z usługi?</h3>
<p>Odbiorcami usługi są mieszkańcy Gminy Łapy, którzy ukończyli 18 rok życia i mają trudności w samodzielnym przemieszczaniu się np. ze względu na ograniczoną sprawność (w tym: poruszające się na wózkach inwalidzkich, o kulach, niewidome, słabowidzące i in.) posiadające orzeczenie o stopniu niepełnosprawności (lub równoważne), jak i osoby nieposiadające takiego orzeczenia (w szczególności osoby mające trudności w poruszaniu się).<p>
<br>


<h3 id="q2">2. Czy uczestnikiem programu usługi door-to-door może być osoba niepełnosprawna intelektualnie, ale sprawna ruchowo?</h3>

<p>Zgodnie z regulaminem osobą „z potrzebą wsparcia w zakresie mobilności” jest osoba, która ma trudności w samodzielnym przemieszczaniu się np. ze względu na ograniczoną sprawność (w tym poruszająca się na wózku inwalidzkim, o kulach, niewidoma, słabowidząca i inne). Posiadająca orzeczenie o niepełnosprawności , jak też nieposiadająca takiego dokumentu.
Jeżeli osoby, które wskazano w pytaniu, potrzebują wsparcia w zakresie mobilności, to są adresatami wsparcia w ramach usługi.</p>
<br>


<h3 id="q3">3. Czy z transportu może korzystać niepełnosprawne dziecko?</h3>
<p>Z usługi mogą korzystać osoby z potrzebą wsparcia w zakresie mobilności, które ukończyły 18 rok życia.</p>
<br>


<h3 id="q4">4. W jaki sposób można zamówić transport?</h3>
<p>Usługę można zamówić poprzez rejestrację w aplikacji internetowej  https://przewoz.cuslapy.pl, telefonicznie pod nr <b>786-044-041</b>, <b>85 715 25 50</b> lub <b>e-mailem</b> przewoz@cuslapy.pl. Można też złożyć zamówienie osobiście w Miejskim Ośrodku Pomocy Społecznej w Łapach, przy ul. Głównej 50.</p>
<br>


<h3 id="q5">5. Gdzie można pojechać transportem door-to-door?</h3>
<p>Usługa realizowana jest przede wszystkim na terenie Miasta i Gminy Łapy. W szczególnie uzasadnionych przypadkach dopuszcza się realizację usługi poza granicami Gminy Łapy. Decyzję w tym zakresie podejmuje dyrektor Miejskiego Ośrodka pomocy Społecznej w Łapach lub inna osoba upoważniona przez dyrektora. Należy jednak pamiętać, iż celem podróży musi być aktywizacja społeczno-zawodowa.</p>
<br>

<h3 id="q6">6. W jakim celu można podróżować transportem door-to-door?</h3>

<p>Cel przejazdu powinien być związany z aktywacją społeczno- zawodową użytkownika/użytkowniczki.<p>
<p>Aktywizacja społeczno-zawodowa rozumiana jest jako rozwijanie aktywności w życiu publicznym, społecznym i zawodowym przez osoby z potrzebą wsparcia w zakresie mobilności poprzez niwelowanie barier związanych z mobilnością tych osób. Aktywizacja ma przyczynić się m.in. do zwiększenia szans rozwoju tych osób, udziału w edukacji, korzystania z infrastruktury społecznej i zwiększenia aktywności zawodowej.
Usługi aktywnej integracji definiują cel realizacji usług transportu door-to-door. <b>Usługi aktywnej integracji</b> rozumiane są jako usługi, których celem jest: odbudowa i podtrzymanie umiejętności uczestniczenia w życiu społeczności lokalnej i pełnienia ról społecznych w miejscu pracy, zamieszkania lub pobytu (reintegracja społeczna), lub odbudowa i podtrzymanie zdolności do samodzielnego świadczenia pracy na rynku pracy (reintegracja zawodowa), lub zapobieganie procesom ubóstwa, marginalizacji i wykluczenia społecznego.</p>
<br>

<h3 id="q7">7. Czy przejazd jest płatny?</h3>
<p>Nie. Przejazd w ramach usługi door-to-door jest nieodpłatny.</p>
<br>

<h3 id="q8">8. Czy z osobą niepełnosprawną może podróżować opiekun?</h3>
<p>Osoba z potrzeba wsparcia w zakresie mobilności mająca kłopoty w samodzielnym poruszaniu się, może korzystać z usługi transportowej wraz z opiekunem/opiekunką, asystentem/asystentką osoby z niepełnosprawnościami, osobą zależną od użytkownika/użytkowniczki.</p>
<br>

<h3 id="q9">9. Czy osoba niepełnosprawna może podróżować z psem asystującym?</h3>

<p>Tak. Dopuszczalny jest przejazd psa asystującego.</p>
<br>

<h3 id="q10">10. Jak długo trzeba czekać na zatwierdzenie przejazdu?</h3>
<p>Użytkownik/użytkowniczka otrzyma odpowiedź na swoje zgłoszenie niezwłocznie, najpóźniej do 3 dni roboczych.</p>
<br>


<h3 id="q11">11. Czy można odwołać zamówioną usługę?</h3>
<p>Jest możliwość odwołania lub rezygnacji z usługi transportowej door-to-door najpóźniej dzień przed realizacją usługi bez konsekwencji. W przypadku notorycznego odwoływania po wyżej wskazanym terminie użytkownik/użytkowniczka może ponieść konsekwencje, np.: odmowa udzielenia wsparcia w ramach usługi transportowej w innym terminie. W przypadku stwierdzenia, że z usługi transportu skorzystała osoba nieuprawniona - osoba taka powinna zwrócić koszt przejazdu gminie.</p>
<br>

    </div>
</template>


<script>

export default {
  name: 'Question',
  data () {
    return {
      user: {},
    }
  },

}
</script>

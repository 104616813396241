<script>

var formatter = {
    date: function (value, format) {
        if (value) {
            return moment(String(value)).format(format || 'MM/DD/YY')
        }
    },
    time: function (value, format) {
        if (value) {
            return moment(String(value)).format(format || 'HH:mm');
        }
    },
    datetime: function (value, format) {
        if (value) {
            return moment(String(value)).format(format || 'YYYY-MM-DD H:mm');
        }
    }
};

import moment from 'moment'


export default {
    name: "formatComponent",
    template: `<span>{{ formatter[fn](value, format) }}</span>`,
    props: ['value', 'fn', 'format'],
    computed: {
        formatter() {
            return formatter;
        }
    }
};


</script>

<template>
  <div id="app">
      <b-container v-bind:style="{ fontSize: fontSize + 'rem' }">
      <b-row class="text-center">
        <b-col >
          <p><img width="220" :src="require('./assets/logo5.png')"/><br><small> W ramach projektu pt. „Usługi indywidualnego transportu door-to-door w Gminie Łapy”. </small></p>
          <!--<p style="font-weight:bold; font-size:20px">ŚWIADCZENIA USŁUG TRANSPORTOWYCH DOOR-TO-DOOR </p>-->

            <hr>
        </b-col>
      </b-row>


      <b-card title="Card Title" no-body>
        <b-card-header header-tag="nav">
        <b-nav card-header tabs>

        <b-nav-item exact exact-active-class="active" to="/">START</b-nav-item>
        <b-nav-item v-if="login_type === 1" exact exact-active-class="active" to="/przejazdy/">Moje zgłoszenia</b-nav-item>
        <b-nav-item v-if="login_type === 1" exact exact-active-class="active" to="/archiwum/">Archiwum</b-nav-item>
        <b-nav-item v-if="login_type === 2" exact exact-active-class="active" to="/cms/delivers/1/">Nowe</b-nav-item>
        <b-nav-item v-if="login_type === 2" exact exact-active-class="active" to="/cms/delivers/2/">Zaakceptowane</b-nav-item>
        <b-nav-item v-if="login_type === 2" exact exact-active-class="active" to="/cms/delivers/3/">W realizacji</b-nav-item>
        <b-nav-item v-if="login_type === 2" exact exact-active-class="active" to="/cms/delivers/4/">Zakończone</b-nav-item>
        <b-nav-item-dropdown
         id="my-nav-dropdown"
         text="Odwołane"
         toggle-class="nav-link-custom"
         right
         v-if="login_type === 2"

       >
         <b-dropdown-item exact exact-active-class="active" to="/cms/delivers/5/">Odwołane przez użytkownika</b-dropdown-item>
         <b-dropdown-item exact exact-active-class="active" to="/cms/delivers/6/">Odwołane przez admin</b-dropdown-item>
         <b-dropdown-item exact exact-active-class="active" to="/cms/delivers/7/">Odwołane przez kierowcę</b-dropdown-item>
       </b-nav-item-dropdown>

       <b-nav-item-dropdown
        id="my-nav-dropdown"
        text="Statystyki"
        toggle-class="nav-link-custom"
        right
        v-if="login_type === 2"

      >
        <b-dropdown-item exact exact-active-class="active" to="/cms/stats_by_days/">liczby przejazdów</b-dropdown-item>
        <b-dropdown-item exact exact-active-class="active" to="/cms/stats_by_users/">użytkowników</b-dropdown-item>
        <b-dropdown-item exact exact-active-class="active" to="/cms/stats_by_transports/">przewozów</b-dropdown-item>
        <b-dropdown-item exact exact-active-class="active" to="/cms/stats_by_drivers/">kierowców</b-dropdown-item>


      </b-nav-item-dropdown>

        <b-nav-item v-if="login_type === 2" exact exact-active-class="active" to="/cms/users/">Pasażerowie</b-nav-item>
        <b-nav-item v-if="login_type === 2" exact exact-active-class="active" to="/cms/drivers/">Kierowcy</b-nav-item>
        <b-nav-item v-if="login_type === 2" exact exact-active-class="active" to="/cms/remarks/">Uwagi</b-nav-item>
        <b-nav-item v-if="login_type === 2" exact exact-active-class="active" to="/cms/propositions/">Propozycje</b-nav-item>
        <b-nav-item v-if="login_type === 2" exact exact-active-class="active" to="/cms/quizes_after_transport/">Ankiety</b-nav-item>
        <b-nav-item v-if="login_type === 3" exact exact-active-class="active" to="/zlecenia/?today=yes" >Moje zlecenia na dzisiaj</b-nav-item>
        <b-nav-item v-if="login_type === 3" exact exact-active-class="active" to="/zlecenia/" >Moje zlecenia</b-nav-item>
        <b-nav-item v-if="login_type === 3" exact exact-active-class="active" to="/archiwum/">Archiwum</b-nav-item>
        <b-nav-item v-if="!user" exact exact-active-class="active"  to="/login">Zaloguj się</b-nav-item>
        <b-nav-item v-if="!user" exact exact-active-class="active" to="/register">Zarejestruj się</b-nav-item>

      <b-nav-item-dropdown
       id="my-nav-dropdown"
       text="Konto"
       toggle-class="nav-link-custom"
       right
       v-if="user"
     >
       <b-dropdown-item to="/zmien_haslo/">Zmień hasło</b-dropdown-item>
       <b-dropdown-item to="/konto/">Zmień dane</b-dropdown-item>
     </b-nav-item-dropdown>
     <b-nav-item exact exact-active-class="active" to="/pytania-i-odpowiedzi"><b-icon-question-circle></b-icon-question-circle> Pytania i odpowiedzi</b-nav-item>
     <b-nav-item exact exact-active-class="active" to="/kontakt"><b-icon-telephone></b-icon-telephone> Kontakt</b-nav-item>

     <button @click="darkThemeSwitch"><b-icon-brightness-high-fill></b-icon-brightness-high-fill> Zmień kontrast</button>
     <button v-on:click="fontSize += 0.25">A+</button>
     <button v-on:click="fontSize < 0.5? fontSize = 0.25: fontSize -= 0.25">A-</button>

      <b-nav-item v-if="user"><a style="color:#999"  href="#" v-on:click="logout()"><b-icon-arrow-return-right></b-icon-arrow-return-right> Wyloguj się ({{ user.username}})</a>  </b-nav-item>

   </b-nav>
</b-card-header>

  <hr>
    <b-card-body>
    <router-view />
    </b-card-body>
     </b-card>
    <p></p>
    <b-navbar toggleable="sm" type="light" style="margin-top:60px">

      <b-navbar-nav>
        <b-button-group size="sm">
          <b-button variant="outline-secondary" target="_blank" href="/docx/regulamin_dtd2.docx">Regulamin</b-button>
          <b-button variant="outline-secondary" to="/polityka-prywatnosci-i-cookies">Polityka prywatności i cookies</b-button>
          <b-button variant="outline-secondary" v-if="user" to="/proposition_for_transport/">Zgłoś propozycję lub uwagę do usługi</b-button>

        </b-button-group>




      </b-navbar-nav>

    </b-navbar>

    <b-row class="justify-content-md-center" >
      <b-col col lg="2"><img :src="require('./assets/FE_POWER.jpg')"/></b-col>
      <b-col cols="12" md="auto"><img :src="require('./assets/logo_polska_flaga.png')"/></b-col>
      <b-col col lg="2"><img :src="require('./assets/UE_EFS.jpg')"/></b-col>
    </b-row>
    </b-container>

    <footer>
      <cookie-law>
        <div slot-scope="props">


    <div class="Cookie Cookie--bottom Cookie--base"><div class="Cookie__content"><div> W ramach naszej witryny stosujemy pliki cookies w celu świadczenia Państwu usług na najwyższym poziomie, w tym w sposób dostosowany do indywidualnych potrzeb. Korzystanie z witryny bez zmiany ustawień dotyczących cookies oznacza, że będą one zamieszczane w Państwa urządzeniu końcowym. Możecie Państwo dokonać w każdym czasie zmiany ustawień dotyczących cookies. Więcej szczegółów w naszej <router-link to="/polityka-prywatnosci-i-cookies">polityce prywatności</router-link>. </div></div> <div class="Cookie__buttons"><!----> <!----> <!----> <button  class="Cookie__button" @click="props.accept">Akceptuję</button></div></div>


  </div>
  </cookie-law>
  </footer>
  </div>


</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'
import { check_user } from '/src/_helpers/auth-header.js'
import CookieLaw from 'vue-cookie-law'

export default {
  name: 'App',
  data() {
    return {
      user: "",
      login_type: null,
      fontSize: 1,
    }
  },
  components: { CookieLaw },
  created: function () {
    // `this` points to the vm instance

    this.user = check_user()
    this.login_type = this.user.account_type
    let theme = localStorage.getItem('theme')
    if (theme == "dark") {
      this._addDarkTheme()
    }
  },
  methods: {
    logout: function() {
      localStorage.removeItem('user')
      location.reload()
      return true;
    },
    _addDarkTheme() {
      let darkThemeLinkEl = document.createElement("link");
      darkThemeLinkEl.setAttribute("rel", "stylesheet");
      darkThemeLinkEl.setAttribute("href", "/css/darktheme.css");
      darkThemeLinkEl.setAttribute("id", "dark-theme-style");

      let docHead = document.querySelector("head");
      docHead.append(darkThemeLinkEl);
    },
    _removeDarkTheme() {
      let darkThemeLinkEl = document.querySelector("#dark-theme-style");
      let parentNode = darkThemeLinkEl.parentNode;
      parentNode.removeChild(darkThemeLinkEl);
    },
    darkThemeSwitch() {
      let darkThemeLinkEl = document.querySelector("#dark-theme-style");
      if (!darkThemeLinkEl) {
        this._addDarkTheme()
        localStorage.setItem('theme', "dark")
      } else {
        this._removeDarkTheme()
        localStorage.removeItem('theme')
      }
    }

  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 20px;
}
</style>

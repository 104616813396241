<script>
  import { check_user, authHeader } from '/src/_helpers/auth-header.js'



  export default {
    name: 'PropositionTransport',
    data() {
      return {
        user: null,
        form: {
          address: {}
        },
        errors: 0,
        success: 0,
        show: true,
        status: ""
      }
    },
    mounted() {
      this.user = check_user()

    },
    methods: {
     onSubmit(event) {
       event.preventDefault()

       let URL = "https://apiprzewoz.cuslapy.pl/quiz/proposition/"

       const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(this.form)
        };
        fetch(URL, requestOptions)
            .then(response => response.json())
            .then(json => {
              if (json.status != "ok") {
                this.errors = json.message;
                console.log(json.message)
                return;
              }
              console.log(json);
              this.errors = 0;
              this.success = 1;
              this.show = false;
              // this.user = json.user;
              // localStorage.setItem('user', JSON.stringify(json.user));
              // this.$router.push('/')
            });

         },
         onReset(event) {
           event.preventDefault()
           // Reset our form values
           // TODO
         },
   },
   computed: {
   },
  }

</script>

<template>

  <div class="text-left">

    <h3>
      Formularz zgłaszania uwag, propozycji co do zakresu i sposobu świadczenia usług indywidualnego transportu door-to-door
    </h3>

    <p>
    </p>

    <b-alert v-if="errors" show variant="danger">Błąd {{errors}}</b-alert>
    <b-alert v-if="status" show variant="info">{{status}}</b-alert>
    <b-alert show variant="success" v-if="success">Dziękujemy za zgłoszone uwagi</b-alert>

    <b-form @submit="onSubmit" @reset="onReset" v-if="show">

    <!-- <b-card bg-variant="light">
      <b-form-group
        label-cols-lg="3"
        label="Dane osoby zgłaszającej"
        label-size="lg"
        label-class="font-weight-bold pt-0"
        class="mb-0"
      >

      <b-form-group
        label="Imię i nazwisko"
        label-for="name"
        label-cols-sm="3"
        label-align-sm="right"
      >
      <b-form-input
        id="name"
        v-model="form.address.name"
        type="text"
        placeholder=""
        required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="Miejscowość"
        label-for="city"
        label-cols-sm="3"
        label-align-sm="right"
      >
      <b-form-input
        id="name"
        v-model="form.address.city"
        type="text"
        placeholder=""
        required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="Ulica i numer domu"
        label-for="street"
        label-cols-sm="3"
        label-align-sm="right"
      >
      <b-form-input
        id="name"
        v-model="form.address.street"
        type="text"
        placeholder=""
        required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="input-group-2"
        label="Email"
        label-for="email"
        description=""
      >
        <b-form-input
          id="email"
          v-model="form.address.email"
          type="email"
          placeholder=""
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="input-group-2"
        label="Telefon"
        label-for="phone"
        description=""
      >
        <b-form-input
          id="phone"
          v-model="form.address.phone"
          type="tel"
          placeholder=""
          required
        ></b-form-input>
      </b-form-group>

      </b-form-group>
    </b-card> -->
<p></p>

    <b-form-textarea
     id="textarea1"
     v-model="form.text1"
     placeholder="Zgłaszane uwagi:"
     rows="3"
     max-rows="6"
     required=true
   ></b-form-textarea>

   <p></p>

   <b-form-textarea
    id="textarea2"
    v-model="form.text2"
    placeholder="Zgłaszane propozycje co do zakresu i sposobu świadczenia usług indywidualnego transportu door-to-door :"
    rows="3"
    max-rows="6"
    required=true
  ></b-form-textarea>

  <p></p>

  <b-form-checkbox
    id="rules3"
    v-model="form.rules3"
    name="rules3"
    value=true
    unchecked-value=false
    required
  >
    Zostałem/am poinformowany/poinformowana o celu i zakresie gromadzonych danych osobowych oraz o administratorze tych danych – zgodnie z przepisami wynikającymi z Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych), oraz ustawy z dnia 10 maja 2018 r. o ochronie danych osobowych (Dz. U. z 2019 r. poz. 1781).
  </b-form-checkbox>

  <p></p>









      <p></p>

      <b-button type="submit" variant="primary">Wyślij ankietę</b-button>
      <!--<b-button type="reset" variant="danger">Wyczyść</b-button>-->
    </b-form>

  </div>
</template>

<template>
    <div class="text-left">
	     <h3><b-icon-house></b-icon-house> Kontakt</h3>



       <b-card
    title="Miejski Ośrodek Pomocy Społecznej"
    img-src="http://www.mopslapy.pl/uploads/2021/05/810_470_crop_356_20210506_120127.jpg"
    img-alt="cus łapy"
    img-top
    tag="article"
    style="max-width: 30rem;"
    class="mb-2"
  >
    <b-card-text>
      Miejski Ośrodek Pomocy Społecznej<br>
       ul. Główna 50<br>
       18-100 Łapy<br>

    </b-card-text>

    <b-button target="_blank" href="http://www.mopslapy.pl" variant="primary">www.mopslapy.pl</b-button>

    <hr>

    <b-card-text>

      <b>Zamówienie usługi:</b><br>
      tel. 715-25-50<br>
      tel. 786-044-041<br>
      e-mail: przewoz@cuslapy.pl<br>

    </b-card-text>

  </b-card>





    </div>
</template>


<script>

export default {
  name: 'Question',
  data () {
    return {
      user: {},
    }
  },

}
</script>

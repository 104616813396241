<script>
  import { check_user, authHeader } from '/src/_helpers/auth-header.js'
  import { help_options, destination_options, need_help_in_deliver_options, rules1_options, type_help_options, destination_name_options } from '/src/_helpers/forms.js'


  export default {
    name: 'New',
    data() {
      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      const minDate = new Date(today)

      return {
        user: null,
        status: "",
        form: {
          info: '',
          address: {},
          date_start: '',
          hour_start: '',
          whence: {},
          where: {},
          type_help_list: [],
          destination_list: [],
        },
        type_help_selected: null,
        type_help_options: type_help_options(),
        need_help_in_deliver_options: need_help_in_deliver_options(),
        destination_selected: null,
        destination_options: destination_options(),
        rules1_options: rules1_options(),
        destination_name_options: destination_name_options(),
        errors: 0,
        success: 0,
        show: true,
        date_start_label: "Dzień wyjazdu",
        hour_start_label: "Godzina wyjazdu",
        label_h2: "Dodaj przejazd",
        labels: {
          pl: {
            labelCloseButton: 'Zamknij'
          },
        },
        min: minDate,
      }
    },
    mounted() {
      this.user = check_user()
      this.public_uid = this.$route.params.id
      this.checkStatus()
      if (this.public_uid && this.show) {
        this.getTransport(this.public_uid)
      }
    },
    methods: {
     checkStatus() {
       let URL = "https://apiprzewoz.cuslapy.pl/quiz/check_status/"
       fetch(URL, {'headers': authHeader()})
         .then(function(response) {
           if (response.status == 401) {
             console.log(response.status);
             localStorage.removeItem('user')
             location.reload()
           }
           else if (response.status != 200) {
             response.json().then(function(json) {
               this.show = false
               this.status = json["message"]
             }.bind(this))
           }
           else {
             response.json().then(function(json) {
               let user_data = json["data"]
               this.form.address = user_data.user.address
               this.show = true
             }.bind(this))
           }
         }.bind(this));
     },
     getTransport(public_uid) {
       let URL = "https://apiprzewoz.cuslapy.pl/transport/client_transport/"+public_uid
       fetch(URL, {'headers': authHeader()})
         .then(function(response) {
           if (response.status != 200) {
             response.json().then(function(json) {
               this.show = false
               this.status = json["message"]
             }.bind(this))
           }
           else {
             response.json().then(function(json) {
               this.form.need_help_in_deliver = json["need_help_in_deliver"]
               this.form.date_start = json["date_start"]
               let whence = json["whence"]
               let where = json["where"]
               let hour_start = json["hour_start"]
               this.form.whence = where
               if (!where.street) {
                 this.form.whence.street = ""
               }
               this.form.where = whence
               if (!whence.street) {
                 this.form.where.street = ""
               }
               this.form.rules1 = false
               this.form.rules3 = false
               this.form.hour_start = ""
               this.form.origin_public_uid = public_uid
               this.date_start_label = "Dzień powrotu"
               this.hour_start_label = "Godzina powrotu"
               this.label_h2 = "Dodaj powrót"
               this.form.destination_name = json["destination_name"]
               this.form.destination_list = json["destination_list"]
             }.bind(this))
           }
         }.bind(this));
     },
     onSubmit(event) {
       event.preventDefault()

       if (this.public_uid)
         var URL = "https://apiprzewoz.cuslapy.pl/transport/client_transport/" + this.public_uid
       else {
          var URL = "https://apiprzewoz.cuslapy.pl/transport/client_transport/"
       }

       const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(this.form)
        };
        fetch(URL, requestOptions)
            .then(response => response.json())
            .then(json => {
              if (json.status != "ok") {
                this.errors = json.message;
                console.log(json.message)
                return;
              }
              console.log(json);
              this.errors = 0;
              this.success = 1;
              this.show = false;
              // this.user = json.user;
              // localStorage.setItem('user', JSON.stringify(json.user));
              // this.$router.push('/')
            });

         },
         onReset(event) {
           event.preventDefault()
           // Reset our form values
           // TODO
         },
   },
   computed: {
      state_date_start() {
        if (this.form.date_start) {
          return true
        }
        return null
      },
      state_hour_start() {
        if (this.form.hour_start) {
          return true
        }
        return null
      }
   },
  }

</script>

<template>

  <div class="text-left">

    <h2>{{label_h2}}</h2>

    <b-alert v-if="errors" show variant="danger">Błąd {{errors}}</b-alert>
    <b-alert v-if="status" show variant="info" v-html="status"></b-alert>
    <b-alert show variant="success" v-if="success">Zlecenie zostało dodane, na swój e-mail otrzymasz powiadomienie o zmianie statusu</b-alert>

    <b-form @submit="onSubmit" @reset="onReset" v-if="show">

      <b-form-group id="input-group-2" v-slot="{ ariaDescribedby }" :label="date_start_label" label-for="date_start" description="Podaj datę wyjazdu">
        <b-form-datepicker
          id="date_start"
          v-model="form.date_start"
          placeholder=""
          class="mb-2"
          locale="pl"
          :state="state_date_start"
          :aria-describedby="ariaDescribedby"
          :min="min"
          required
        ></b-form-datepicker>

      </b-form-group>

      <b-form-group id="input-group-2" :label="hour_start_label" label-for="hour_start" description="Podaj godzinę wyjazdu">
        <b-form-timepicker
          id="hour_start"
          v-model="form.hour_start"
          placeholder=""
          class="mb-2"
          locale="pl"
          :state="state_hour_start"
          required
        ></b-form-timepicker>
      </b-form-group>

      <b-card bg-variant="light">
        <b-form-group
          label-cols-lg="3"
          label="Skąd"
          label-size="lg"
          label-class="font-weight-bold pt-0"
          class="mb-0"
        >


          <b-form-group
            label="Miejscowość:"
            label-for="nested-street"
            label-cols-sm="3"
            label-align-sm="right"
          >
          <b-form-input
            id="city"
            v-model="form.whence.city"
            type="text"
            placeholder=""
            required
          ></b-form-input>

          </b-form-group>

          <b-form-group
            label="Ulica:"
            label-for="nested-street"
            label-cols-sm="3"
            label-align-sm="right"
          >
          <b-form-input
            id="street"
            v-model="form.whence.street"
            type="text"
            placeholder=""
          ></b-form-input>
            </b-form-group>

            <b-form-group
              label="Numer domu:"
              label-for="nested-nr"
              label-cols-sm="3"
              label-align-sm="right"
            >
            <b-form-input
              id="nr"
              v-model="form.whence.nr"
              type="text"
              placeholder=""
              required
            ></b-form-input>
              </b-form-group>


              <b-form-group
                label="Numer lokalu:"
                label-for="nested-nr"
                label-cols-sm="3"
                label-align-sm="right"
              >
              <b-form-input
                id="second_number"
                v-model="form.whence.second_number"
                type="text"
                placeholder=""
              ></b-form-input>
              </b-form-group>


        </b-form-group>
       </b-card>

       <p></p>

       <b-card bg-variant="light">
         <b-form-group
           label-cols-lg="3"
           label="Dokąd"
           label-size="lg"
           label-class="font-weight-bold pt-0"
           class="mb-0"
         >


           <b-form-group
             label="Miejscowość:"
             label-for="nested-street"
             label-cols-sm="3"
             label-align-sm="right"
           >
           <b-form-input
             id="city"
             v-model="form.where.city"
             type="text"
             placeholder=""
             required
           ></b-form-input>

           </b-form-group>

           <b-form-group
             label="Ulica:"
             label-for="nested-street"
             label-cols-sm="3"
             label-align-sm="right"
           >
           <b-form-input
             id="street"
             v-model="form.where.street"
             type="text"
             placeholder=""
           ></b-form-input>
             </b-form-group>


             <b-form-group
               label="Numer domu:"
               label-for="nested-street"
               label-cols-sm="3"
               label-align-sm="right"
             >
             <b-form-input
               id="street"
               v-model="form.where.nr"
               type="text"
               placeholder=""
               required
             ></b-form-input>
               </b-form-group>


               <b-form-group
                 label="Numer lokalu:"
                 label-for="nested-street"
                 label-cols-sm="3"
                 label-align-sm="right"
               >
               <b-form-input
                 id="second_number"
                 v-model="form.where.second_number"
                 type="text"
                 placeholder=""
               ></b-form-input>
            </b-form-group>

         </b-form-group>
        </b-card>




        <hr>

        <b-card bg-variant="light">
              <b-form-group
                label-cols-lg="3"
                label="Podaj swój adres zamieszkania"
                label-size="lg"
                label-class="font-weight-bold pt-0"
                class="mb-0"
              >

              <b-form-group
                label="Miejscowość:"
                label-for="nested-street"
                label-cols-sm="3"
                label-align-sm="right"
              >
              <b-form-input
                id="city"
                v-model="form.address.city"
                type="text"
                placeholder=""
                required
              ></b-form-input>
              </b-form-group>

              <b-form-group
                label="Ulica:"
                label-for="nested-street"
                label-cols-sm="3"
                label-align-sm="right"
              >
              <b-form-input
                id="street"
                v-model="form.address.street"
                type="text"
                placeholder=""

              ></b-form-input>
                </b-form-group>

              <b-form-group
                label="Numer budynku:"
                label-for="nested-street"
                label-cols-sm="3"
                label-align-sm="right"
              >
              <b-form-input
                id="number"
                v-model="form.address.number"
                type="text"
                placeholder=""
                required
              ></b-form-input>
              </b-form-group>


              <b-form-group
                label="Nume lokalu:"
                label-for="nested-street"
                label-cols-sm="3"
                label-align-sm="right"
              >
              <b-form-input
                id="second_number"
                v-model="form.address.second_number"
                type="text"
                placeholder=""
              ></b-form-input>
              </b-form-group>





                <b-form-group
                  label="Gmina:"
                  label-for="nested-street"
                  label-cols-sm="3"
                  label-align-sm="right"
                >
                <b-form-input
                  id="code"
                  v-model="form.address.code"
                  type="text"
                  placeholder=""
                  required
                ></b-form-input>
                </b-form-group>

              </b-form-group>
             </b-card>

        <hr>

<!--
      <b-form-group id="input-group-2" label="Dodatkowe informacje" label-for="info">
        <b-form-input
          id="info"
          v-model="form.info"
          placeholder=""
        ></b-form-input>
      </b-form-group>
-->

    <b-form-group label="Czy potrzebna jest pomoc w dotarciu z mieszkania do pojazdu?" >
      <b-form-radio-group
        id="need_help_in_deliver"
        v-model="form.need_help_in_deliver"
        :options="need_help_in_deliver_options"
        required
      ></b-form-radio-group>
    </b-form-group>

      <b-form-group
     label="Czy zamawiający będzie korzystać z usługi transportowej:"
     v-slot="{ ariaDescribedby }"
   >
     <b-form-checkbox-group
       v-model="form.type_help_list"
       :options="type_help_options"
       :aria-describedby="ariaDescribedby"
       name="flavour-2a"
       stacked
     ></b-form-checkbox-group>
   </b-form-group>

   <b-form-group  id="input-group-2" label="Dane osoby (imię i nazwisko)" label-for="info" v-if="form.type_help_list.includes(2)">
     <b-form-input
       id="type_help_text"
       v-model="form.type_help_text"
       placeholder=""
       type="text"
     ></b-form-input>
   </b-form-group>


   <b-form-group
  label="Przypisz cel podróży. Do usług aktywnej integracji należą usługi o charakterze:"
  v-slot="{ ariaDescribedby }"
>
  <b-form-checkbox-group
    v-model="form.destination_list"
    :options="destination_options"
    :aria-describedby="ariaDescribedby"
    name="flavour-2a"
    stacked
  ></b-form-checkbox-group>
</b-form-group>


<b-form-group label="Podaj miejsce docelowe:" >
  <b-form-radio-group
    id="destination_name"
    v-model="form.destination_name"
    :options="destination_name_options"
    required
  ></b-form-radio-group>
</b-form-group>

<hr>

<b-form-checkbox
     id="checkbox-1"
     v-model="form.rules1"
     name="checkbox-1"
     value=true
     unchecked-value=false
     required
   >
     Oświadczam, iż moje dane kontaktowe nie zmieniły się. W przypadku zmiany danych prosimy o poinformowanie nas na adres przewoz@cuslapy.pl
   </b-form-checkbox>

   <b-form-checkbox
     id="rules3"
     v-model="form.rules3"
     name="rules3"
     value=true
     unchecked-value=false
     required
   >
     Zostałem/am poinformowany/poinformowana o celu i zakresie gromadzonych danych osobowych oraz o administratorze tych danych – zgodnie z przepisami wynikającymi z Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych), oraz ustawy z dnia 10 maja 2018 r. o ochronie danych osobowych (Dz. U. z 2019 r. poz. 1781).
   </b-form-checkbox>

   <p></p>

      <b-button type="submit" variant="primary">Dodaj zlecenie</b-button>
      <!--<b-button type="reset" variant="danger">Wyczyść</b-button>-->
    </b-form>


  </div>
</template>



<template>

<div v-if="item.date_start">

    <b-alert v-if="errors" show variant="danger">{{errors}}</b-alert>
    <b-alert v-if="status" show variant="info">{{status}}</b-alert>
    <div class="text-center" v-if="spinner">
        <b-spinner variant="primary" label="Text Centered"></b-spinner>
    </div>

    <b-container class="bv-example-row">
        <b-row>
            <b-col>
                <b-jumbotron header-level="5">
                    <template #header>Zlecenie #{{item.id}} {{item.testy}}</template>

                    <template #lead>
                        <b-button variant="info" v-b-modal.modal-edit-transport>Edytuj zlecenie</b-button>
                    </template>

                    <hr class="my-4">

                    <p>
                        <b>Adres początkowy:</b> {{ item.whence.city }}, {{ item.whence.street }} nr. bud: {{ item.whence.nr }} nr lok: {{ item.whence.second_number }}
                    </p>

                    <hr class="my-4">

                    <p>
                        <b>Adres końcowy:</b> {{ item.where.city }}, {{ item.where.street }} nr. bud: {{ item.where.nr }} nr lok: {{ item.where.second_number }}
                    </p>

                    <hr class="my-4">

                    <p>
                        <b>Dzień:</b> {{item.date_start}}
                    </p>

                    <p>
                        <b>Godzina:</b> {{item.hour_start}}
                    </p>

                    <p v-if="comeback_button()">
                        Powrót: <b-button  variant="outline-primary" :to="{ path: comeback_button() }" size="sm">Zobacz powrót</b-button>

                    </p>
                    <p v-if="show_comeback_button()" >
                      <b-button variant="outline-primary" :to="{ path: '/cms/order/return/' + item.public_uid }" size="sm">Dodaj powrót</b-button>
                    </p>
                    <p v-if="original_button()">
                        Powrót: <b-button  variant="outline-primary" :to="{ path: original_button() }" size="sm">Zobacz pierwotny przyjazd</b-button>

                    </p>

                    <hr class="my-4">

                    <p>
                        Czy potrzebna jest pomoc w dotarciu z mieszkania do pojazdu: <b>{{item.need_help_in_deliver}}</b>
                    </p>

                    <p>
                        Czy osoba z potrzebuje wsparcia
                        <b>{{item.type_help_list_human}}</b>
                    </p>

                    <p>
                        Opiekun: <b>{{item.type_help_text}}</b>
                    </p>


                    <p>
                        Przypisz cel podróży do możliwych form aktywizacji społeczno-zawodowej
                        <b>{{item.destination_list_human}}</b>
                    </p>

                    <p>
                        Cel podróży/nazwa instytucji:
                        <b>{{item.destination_name_human}}</b>
                    </p>

                    <p>
                        Oświadczam, iż moje dane kontaktowe nie zmieniły się <b>{{item.rules1}}</b>
                    </p>

                    <!-- <b-button v-if="show_accept_work" v-on:click="onAcceptWork" variant="success">Bierz zadanie</b-button>
            <b-button v-if="show_finish_work" v-on:click="onFinishWork" variant="danger">Zakończ zadanie</b-button> -->
                </b-jumbotron>
                <b-button v-on:click="deleteTransport">Usuń</b-button>
            </b-col>
            <b-col>
                <b-jumbotron bg-variant="light" text-variant="grey" header-level=5 class="text-left">
                    <template #header>Użytkownik</template>

                    <b-container class="bv-example-row">
                        <b-row>
                            <b-col>Użytkownik:</b-col>
                            <b-col>{{item.user.username}} <b-link v-bind:to="{ path: '/cms/user/transports/' + item.user.public_id }">przejazdy</b-link><br> <b-link v-b-modal.modal-edit-user>edytuj</b-link></b-col>
                        </b-row>
                        <b-row>
                            <b-col>Email:</b-col>
                            <b-col>{{item.user.email}}</b-col>
                        </b-row>
                        <b-row>
                            <b-col>tel:</b-col>
                            <b-col>{{item.user.phone}} </b-col>
                        </b-row>
                        <b-row>
                            <b-col>wiek</b-col>
                            <b-col>{{item.user.age}}</b-col>
                        </b-row>
                        <b-row>
                            <b-col>płeć</b-col>
                            <b-col>{{item.user.sex_text}}</b-col>
                        </b-row>
                        <b-row>
                            <b-col>Niepełnospr.:</b-col>
                            <b-col>{{item.user.disab}}</b-col>
                        </b-row>

                        <b-row>
                            <b-col>Admin</b-col>
                            <b-col>{{item.user.admin_add}}</b-col>
                        </b-row>

                                      <b-row>
                                        <b-col><b>Informacja od CUS</b></b-col>
                                        <b-col>{{item.info}}</b-col>
                                      </b-row>

                    </b-container>

                </b-jumbotron>

                <b-jumbotron bg-variant="light" text-variant="grey" header-level=5>
                    <template #header>Kierowca</template>

                    <p v-if="item.driver">
                        {{item.driver.username}} (tel.{{item.driver.phone}})
                    </p>
                    <p v-else>
                        Nie wybrano kierowcy
                    </p>
                </b-jumbotron>
                <b-jumbotron v-if="item.driver" bg-variant="light" text-variant="grey" header-level=6>
                    <template #header>Dane</template>
                    <b-container class="bv-example-row">
                        <b-row>
                            <b-col>Liczba kilometrów: <b>{{item.distance}}</b></b-col>

                        </b-row>
                        <b-row>
                            <b-col>Czas przejazdu: <b>{{item.time_trevel}}</b></b-col>

                        </b-row>
                        <b-row>
                            <b-col>Czas oczekiwania na osobę: <b>{{item.waiting_time_for_person}}</b></b-col>

                        </b-row>
                        <b-link v-b-modal.modal-edit-transport-driver>edytuj</b-link>
                        <br>

                        <b-alert v-if="errors_edit_driver_data" show variant="danger">{{errors_edit_driver_data}}</b-alert>
                        <b-alert v-if="status_edit_driver_data" show variant="info">{{status_edit_driver_data}}</b-alert>
                    </b-container>

                </b-jumbotron>
            </b-col>
            <b-col class="text-left">
                <b-jumbotron bg-variant="light" text-variant="grey" header-level=5>
                    <template #header>Zarządzaj</template>

                    <b-button v-if="item.status.button_text" v-on:click="onChangeStatus" variant="success">Zmień status na: {{item.status.button_text}}</b-button>
                    <br>
                    <b-button variant="danger" v-b-modal.modal-prevent-closing>Anuluj zgłoszenie</b-button>
                    <br>
                    <b-button variant="info" v-b-modal.modal-quiz-after-transport>Wypełnij ankietę</b-button>
                    <hr>
                    <label for="inline-form-input-name">Wybierz/zmień kierowcę</label>
                    <b-form-select name="driver_sel" v-model="driver_selected" :options="drivers"></b-form-select>
                    <b-button v-on:click="onChangeDriver()" variant="secondary">przypisz</b-button>

                    <hr>
                    <p>
                        <b>Status:</b>
                        <b-alert :variant="item.status.color" show>{{item.status.text}}</b-alert>
                    </p>
                    <div>
                        <b-button size="sm" v-on:click="fetchTransportOnThisDay()">Pokaż zlecenia w dniu {{this.item.date_start}}</b-button>
                    </div>

                    <div v-if="items.length">
                      <br>
                        <h5 v-if="items.length">Lista przejazdów:</h5>

                        <b-list-group>
                            <b-list-group-item class="flex-column align-items-start" v-for="t in items" :key="t.public_uid">
                              <b-icon-arrow-right v-if="t.public_uid == this_public_uid"></b-icon-arrow-right>  <formatDate :value="t.datetime_start" fn="datetime"/> {{t.status.text}}
                            </b-list-group-item>

                        </b-list-group>
                    </div>

                    <div v-if="item.all_annotations.length">
                        <h5 v-if="item.all_annotations.length">Powód anulowania:</h5>

                        <b-list-group>
                            <b-list-group-item class="flex-column align-items-start" v-for="item in item.all_annotations" :key="item.id">
                                <p class="mb-1">
                                    <formatDate :value="item.added" fn="datetime"/> <b>{{ item.user.username }}</b>
                                    <br>
                                    {{ item.text }}
                                </p>
                            </b-list-group-item>

                        </b-list-group>


                    </div>

                </b-jumbotron>
            </b-col>
        </b-row>
    </b-container>

    <div>
        <b-modal id="modal-prevent-closing" ref="modal" title="Podaj powód anulacji zgłoszenia." @show="resetModal" @hidden="resetModal" @ok="handleOk">
            <form ref="form_cancel" @submit.stop.prevent="handleSubmit">
                <b-form-group label="Tutaj wpisz treść" label-for="name-input" invalid-feedback="Podaj powód rezygnacji" :state="annotation_text_state">
                    <b-form-input id="name-input" v-model="form.annotation_text" :state="annotation_text_state" required></b-form-input>
                </b-form-group>
            </form>
        </b-modal>
    </div>
    <div>
        <b-modal id="modal-quiz-after-transport" ref="modal" title="Ankieta po zakończeniu" @show="resetModal" @hidden="resetModal" @ok="handleOkQuiz">
            <form ref="form_quiz" @submit.stop.prevent="handleSubmitQuiz">
              <b-form-group label="W jakim stopniu jest Pan/Pani zadowolona z realizacji usługi indywidualnego transportu door-to-door?" >
                <b-form-radio-group
                  id="answer1"
                  v-model="form_quiz.answer1"
                  :options="answer_options"
                  :state="form_quiz_state.answer1"
                  required
                >
                <b-form-invalid-feedback :state="form_quiz_state.answer1">Wybierz</b-form-invalid-feedback>
              </b-form-radio-group>
              </b-form-group>
              <small>
                gdzie:<br>
                  1 oznacza: nie jestem zadowolona,<br>
                  5 oznacza: jestem bardzo zadowolona<br>
                <br>
              </small>

              <hr>

              <b-form-group label="W jakim stopniu usługa została dopasowana do Pani/Pana potrzeb?" >
                <b-form-radio-group
                  id="answer2"
                  v-model="form_quiz.answer2"
                  :options="answer_options"
                  required
                >
                <b-form-invalid-feedback :state="form_quiz_state.answer2">Wybierz</b-form-invalid-feedback>
              </b-form-radio-group>
              </b-form-group>
              <small>
                gdzie:<br>
                  1 oznacza: usługa nie została dopasowana do moich potrzeb<br>
                  5 oznacza: usługa została dopasowana do moich potrzeb<br>
                <br>
              </small>

              <hr>

              <b-form-group label="Jak ocenia Pan/Pani jakość sprzętu używanego do realizacji usługi indywidualnego transportu door-to-door?" >
                <b-form-radio-group
                  id="answer3"
                  v-model="form_quiz.answer3"
                  :options="answer_options"
                  required
                >
                <b-form-invalid-feedback :state="form_quiz_state.answer3">Wybierz</b-form-invalid-feedback>
              </b-form-radio-group>
              </b-form-group>
              <small>
                gdzie:<br>
                  1 oznacza: bardzo źle<br>
                  5 oznacza: bardzo dobrze<br>
                <br>
              </small>

              <hr>

              <b-form-group label="Jak ocenia Pan/Pani postępowanie obsługi pojazdu: kierowcy i asystenta?" >
                <b-form-radio-group
                  id="answer4"
                  v-model="form_quiz.answer4"
                  :options="answer_options"
                  required
                >
                <b-form-invalid-feedback :state="form_quiz_state.answer4">Wybierz</b-form-invalid-feedback>
              </b-form-radio-group>
              </b-form-group>
              <small>
                gdzie:<br>
                  1 oznacza: nie jestem zadowolona<br>
                  5 oznacza: jestem bardzo zadowolona<br>
                <br>
              </small>

            </form>
        </b-modal>
    </div>
    <div>
        <b-modal id="modal-edit-transport-driver" ref="modal" title="Edycja danych przejazdu" @show="resetModal" @hidden="resetModal" @ok="handleOkEditTransportDriver">
            <form ref="form_transport_driver_edit" @submit.stop.prevent="handleSubmitEditDriverTransport">


              <b-card bg-variant="light">

                  <b-alert v-if="form_edit_driver_transport.errors.length" show variant="danger">
                    <ul>
                      <li v-bind:key="error" v-for="error in form_edit_driver_transport.errors">{{ error }}</li>
                    </ul>
                  </b-alert>

                  <b-form-group
                    label="Dystans:"
                    label-for="nested-street"
                    label-cols-sm="3"
                    label-align-sm="right"
                  >
                  <b-form-input
                    id="street"
                    v-model="form_edit_driver_transport.distance"
                    type="text"
                    placeholder="podajemy w km"
                    required
                  ></b-form-input>
                    </b-form-group>

                    <b-form-group
                      label="Czas przejazdu:"
                      label-for="nested-nr"
                      label-cols-sm="3"
                      label-align-sm="right"
                    >
                    <b-form-input
                      id="nr"
                      v-model="form_edit_driver_transport.time_trevel"
                      type="text"
                      placeholder="minuty"
                      required
                    ></b-form-input>
                      </b-form-group>

                  <b-form-group
                    label="Czas oczekiwania na osobę:"
                    label-for="nested-street"
                    label-cols-sm="3"
                    label-align-sm="right"
                  >
                  <b-form-input
                    id="city"
                    v-model="form_edit_driver_transport.waiting_time_for_person"
                    type="text"
                    placeholder="minuty"
                    required
                  ></b-form-input>

                  </b-form-group>


               </b-card>





            </form>
        </b-modal>
    </div>
    <div>
        <b-modal id="modal-edit-transport" ref="modal" title="Edycja zlecenia." @show="resetModal" @hidden="resetModal" @ok="handleOkEditTransport">
            <form ref="form_transport_edit" @submit.stop.prevent="handleSubmitEditTransport">
              <b-form-group id="input-group-2" label="Na kiedy transport (data)" label-for="date_start" description="Podaj datę wyjazdu">
                <b-form-datepicker
                  id="date_start"
                  v-model="form_edit_transport.date_start"
                  placeholder=""
                  class="mb-2"
                  locale="pl"
                  required
                ></b-form-datepicker>
              </b-form-group>

              <b-form-group id="input-group-2" label="Na kiedy transport (godzina)" label-for="hour_start" description="Podaj godzinę wyjazdu">
                <b-form-timepicker
                  id="hour_start"
                  v-model="form_edit_transport.hour_start"
                  placeholder=""
                  class="mb-2"
                  locale="pl"
                  required
                ></b-form-timepicker>
              </b-form-group>


              <b-card bg-variant="light">
                <b-form-group
                  label-cols-lg="3"
                  label="Skąd"
                  label-size="lg"
                  label-class="font-weight-bold pt-0"
                  class="mb-0"
                >
                  <b-form-group
                    label="Ulica:"
                    label-for="nested-street"
                    label-cols-sm="3"
                    label-align-sm="right"
                  >
                  <b-form-input
                    id="street"
                    v-model="form_edit_transport.whence.street"
                    type="text"
                    placeholder=""
                  ></b-form-input>
                    </b-form-group>

                    <b-form-group
                      label="Nr:"
                      label-for="nested-nr"
                      label-cols-sm="3"
                      label-align-sm="right"
                    >
                    <b-form-input
                      id="nr"
                      v-model="form_edit_transport.whence.nr"
                      type="text"
                      placeholder=""
                      required
                    ></b-form-input>
                      </b-form-group>

                  <b-form-group
                    label="Miejscowość:"
                    label-for="nested-street"
                    label-cols-sm="3"
                    label-align-sm="right"
                  >
                  <b-form-input
                    id="city"
                    v-model="form_edit_transport.whence.city"
                    type="text"
                    placeholder=""
                    required
                  ></b-form-input>

                  </b-form-group>

                </b-form-group>
               </b-card>

               <p></p>

               <b-card bg-variant="light">
                 <b-form-group
                   label-cols-lg="3"
                   label="Dokąd"
                   label-size="lg"
                   label-class="font-weight-bold pt-0"
                   class="mb-0"
                 >
                   <b-form-group
                     label="Ulica:"
                     label-for="nested-street"
                     label-cols-sm="3"
                     label-align-sm="right"
                   >
                   <b-form-input
                     id="street"
                     v-model="form_edit_transport.where.street"
                     type="text"
                     placeholder=""
                   ></b-form-input>
                     </b-form-group>


                     <b-form-group
                       label="Nr:"
                       label-for="nested-nr"
                       label-cols-sm="3"
                       label-align-sm="right"
                     >
                     <b-form-input
                       id="nr"
                       v-model="form_edit_transport.where.nr"
                       type="text"
                       placeholder=""
                       required
                     ></b-form-input>
                       </b-form-group>

                   <b-form-group
                     label="Miejscowość:"
                     label-for="nested-street"
                     label-cols-sm="3"
                     label-align-sm="right"
                   >
                   <b-form-input
                     id="city"
                     v-model="form_edit_transport.where.city"
                     type="text"
                     placeholder=""
                     required
                   ></b-form-input>

                   </b-form-group>

                 </b-form-group>
                </b-card>

                <b-form-group label="Czy potrzebna jest pomoc w dotarciu z mieszkania do pojazdu" >
                  <b-form-radio-group
                    id="form_edit_transport_need_help_in_deliver"
                    v-model="form_edit_transport.need_help_in_deliver"
                    :options="need_help_in_deliver_options"
                    required
                  ></b-form-radio-group>
                </b-form-group>


                <b-form-group
               label="Czy osoba z potrzebą wsparcia w zakresie mobilności będzie korzystać z usługi transportowej:"
               v-slot="{ ariaDescribedby }"
             >
               <b-form-checkbox-group
                 v-model="form_edit_transport.type_help_list"
                 :options="type_help_options"
                 :aria-describedby="ariaDescribedby"
                 name="flavour-2a"
                 stacked
               ></b-form-checkbox-group>
             </b-form-group>

             <b-form-group  id="input-group-2" label="Dane osoby (imię i nazwisko)" label-for="info" v-if="form_edit_transport.type_help_list.includes(2)">
               <b-form-input
                 id="form_edit_transport_type_help_text"
                 v-model="form_edit_transport.type_help_text"
                 placeholder=""
                 type="text"
               ></b-form-input>
             </b-form-group>


                <b-form-group
                  label="Przypisz cel podróży do możliwych form aktywizacji społeczno-zawodowej / usług aktywnej integracji"
                  v-slot="{ ariaDescribedby }"
                >
               <b-form-checkbox-group
                 v-model="form_edit_transport.destination_list"
                 :options="destination_options"
                 :aria-describedby="ariaDescribedby"
                 name="flavour-2a"
                 stacked
               ></b-form-checkbox-group>
             </b-form-group>

             <b-form-group label="Podaj miejsce docelowe:" >
               <b-form-radio-group
                 id="destination_name"
                 v-model="form_edit_transport.destination_name"
                 :options="destination_name_options"
                 required
               ></b-form-radio-group>
             </b-form-group>

            </form>
        </b-modal>
    </div>
    <div>
      <b-modal id="modal-edit-user" ref="modal" title="Edycja użytkownika." @show="resetModal" @hidden="resetModal" @ok="handleOkEditUser">
          <form ref="form_user_edit" @submit.stop.prevent="handleSubmitEditUser">
            <b-card bg-variant="light">
            <b-form-group
              label-cols-lg="3"
              label="Pasażer"
              label-size="lg"
              label-class="font-weight-bold pt-0"
              class="mb-0"
            >

            <b-form-group
              id="input-group-1"
              label="Imię i nazwisko"
              label-for="form_edit_user_username"
              description=""
            >
              <b-form-input
                id="username"
                v-model="form_edit_user.username"
                type="text"
                placeholder=""
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-2"
              label="Email/login"
              label-for="email"
              description="Wymagany do logowania się w serwisie."
            >
              <b-form-input
                id="email"
                v-model="form_edit_user.email"
                type="email"
                placeholder=""
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-2"
              label="Wiek"
              label-for="age"
              description=""
            >
              <b-form-input
                id="age"
                v-model="form_edit_user.age"
                type="number"
                placeholder=""
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-2"
              label="Telefon"
              label-for="phone"
              description=""
            >
              <b-form-input
                id="phone"
                v-model="form_edit_user.phone"
                type="tel"
                placeholder=""
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Czy jesteś osobą niepełnosprawną?" >
              <b-form-radio-group
                id="niepelno"
                v-model="form_edit_user.is_disab"
                :options="niepelno_options"
                required
              ></b-form-radio-group>
            </b-form-group>


            <b-form-group
              id="input-group-2"
              label=""
              label-for="disab"
              description=""
              v-if="form_edit_user.is_disab == true"
            >
              <b-form-input
                id="disab"
                v-model="form_edit_user.disab"
                type="text"
                placeholder="Podaj kod niepełnosprawności "
                description="Wskazanie potrzeby wsparcia w zakresie mobilności uzasadniającej skorzystanie z usługi (np. niepełnosprawność lub inne)."
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-2"
              label=""
              label-for="disab"
              description=""
              v-if="form_edit_user.is_disab == false"
            >
              <b-form-input
                id="disab"
                v-model="form_edit_user.disab"
                type="text"
                placeholder="Podaj inne ograniczenia w zakresie poruszania się"
                description="Wskazanie potrzeby wsparcia w zakresie mobilności uzasadniającej skorzystanie z usługi (np. niepełnosprawność lub inne)."
                required
              ></b-form-input>
            </b-form-group>


            <b-form-group label="Czy osoba zamawiająca porusza się na wózku inwalidzkim?" >
              <b-form-radio-group
                id="wheelchair"
                v-model="form_edit_user.is_wheelchair"
                :options="niepelno_options"
                required
              ></b-form-radio-group>
            </b-form-group>

            <b-form-group label="Płeć" v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                id="sex"
                v-model="form_edit_user.sex"
                :options="sex_options"
                :aria-describedby="ariaDescribedby"
                name="radio-options"
                required
              ></b-form-radio-group>
            </b-form-group>

            </b-form-group>
          </b-card>

          <b-card bg-variant="light" >
                <b-form-group
                  label-cols-lg="3"
                  label="Pasażer - adres zamieszkania"
                  label-size="lg"
                  label-class="font-weight-bold pt-0"
                  class="mb-0"
                >

                <b-form-group
                  label="Miejscowość:"
                  label-for="nested-street"
                  label-cols-sm="3"
                  label-align-sm="right"
                >
                <b-form-input
                  id="city"
                  v-model="form_edit_user.address.city"
                  type="text"
                  placeholder=""
                  required
                ></b-form-input>
                </b-form-group>

                <b-form-group
                  label="Ulica:"
                  label-for="nested-street"
                  label-cols-sm="3"
                  label-align-sm="right"
                >
                <b-form-input
                  id="street"
                  v-model="form_edit_user.address.street"
                  type="text"
                  placeholder=""

                ></b-form-input>
                  </b-form-group>

                <b-form-group
                  label="Numer budynku:"
                  label-for="nested-street"
                  label-cols-sm="3"
                  label-align-sm="right"
                >
                <b-form-input
                  id="number"
                  v-model="form_edit_user.address.number"
                  type="text"
                  placeholder=""
                  required
                ></b-form-input>
                </b-form-group>


                <b-form-group
                  label="Nume lokalu:"
                  label-for="nested-street"
                  label-cols-sm="3"
                  label-align-sm="right"
                >
                <b-form-input
                  id="second_number"
                  v-model="form_edit_user.address.second_number"
                  type="text"
                  placeholder=""
                ></b-form-input>
                </b-form-group>





                  <b-form-group
                    label="Gmina:"
                    label-for="nested-street"
                    label-cols-sm="3"
                    label-align-sm="right"
                  >
                  <b-form-input
                    id="code"
                    v-model="form_edit_user.address.code"
                    type="text"
                    placeholder=""
                    required
                  ></b-form-input>
                  </b-form-group>

                </b-form-group>
               </b-card>




          </form>
      </b-modal>
    </div>

    <b-button onclick="window.history.go(-1); return false;" variant="outline-primary">Powrót</b-button>

</div>
<div v-else>
    <div class="text-center">
        <b-spinner variant="primary" label="Text Centered"></b-spinner>
    </div>
</div>

</template>

<script>

import {
    authHeader, authHeaderGet
}
from '/src/_helpers/auth-header.js'
import { transport_user_options, help_options, destination_name_options, destination_options, need_help_in_deliver_options, rules1_options, type_help_options, rue_false_options, man_woman_options, register_question1_options, true_false_options} from '/src/_helpers/forms.js'
import {quiz_answers} from '/src/_helpers/forms.js'



export default {
    name: 'CmsOrder',
    data() {
        return {
            show: true,
            title: '...',
            address: null,
            show_finish_work: true,
            show_accept_work: true,
            item: {
                date_start: null,
                destination_list: "",
                all_annotations: [],
                user: {}
            },
            drivers: [],
            driver_selected: "",
            errors: null,
            status: null,
            errors_edit_driver_data: null,
            status_edit_driver_data: null,
            annotation_text_state: null,
            form: {
                annotation_text: "",
            },
            spinner: true,
            answer_options: quiz_answers(),
            form_quiz: {},
            form_quiz_state: {answer1: null},
            form_edit_transport: {whence: {}, where: {}, type_help_list: []},
            form_edit_driver_transport: {errors: []},
            items: [],
            public_uid: null,
            form_edit_user: {address: {}},
            niepelno_options: true_false_options(),
            question1_options: register_question1_options(),
            sex_options: man_woman_options(),
            need_help_in_deliver_options: need_help_in_deliver_options(),
            destination_options: destination_options(),
            type_help_options: type_help_options(),
            destination_name_options: destination_name_options(),
        }
    },
    watch: {
        '$route': 'fetchData',
        //'$route': 'fetchDataDrivers'
    },
    created() {
        this.fetchData()
        this.fetchDataDrivers()
        this.spinner = false
    },
    methods: {
      handleOkEditUser(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.handleSubmitEditUser()
      },
      handleSubmitEditUser() {
          // Exit when the form isn't valid
          if (!this.checkFormValidityEditUser()) {

              return
          }
          this.sendEditUser()
          this.$nextTick(() => {
              this.$bvModal.hide('modal-edit-user')
          })
      },
      checkFormValidityEditUser() {
          const valid = this.$refs.form_user_edit.checkValidity()


          // this.annotation_text_state = valid
          return valid
      },
      sendEditUser() {
        this.status = ""

        const URL = "https://apiprzewoz.cuslapy.pl/user/admin/change_data/" + this.item.user.public_id + "/"
        const requestOptions = {
            method: 'PUT',
            headers: authHeader(),
            body: JSON.stringify(this.form_edit_user)
        };
        fetch(URL, requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.status != "ok") {
                    this.errors = json.message;
                    return;
                }
                this.fetchData()
                this.item.user_can_add_annotation = false
                this.status = "Użytkownik został zmodyfikowane"
            });
      },
      handleOkEditTransportDriver(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.handleSubmitEditDriverTransport()
      },
      handleSubmitEditDriverTransport() {
          // Exit when the form isn't valid
          this.form_edit_driver_transport.errors = []
          this.errors_edit_driver_data = null
          this.status_edit_driver_data = null
          if (!this.form_edit_driver_transport.distance) {
            this.form_edit_driver_transport.errors.push("Podaj liczbę przejechanych kilometrów")
          }
          if (!this.form_edit_driver_transport.time_trevel) {
            this.form_edit_driver_transport.errors.push("Podaj czas podróży")
          }
          if (!this.form_edit_driver_transport.waiting_time_for_person) {
            this.form_edit_driver_transport.errors.push("Podaj czas oczekiwania na osobę")
          }
          if (!this.checkFormValidityEditDriverTransport()) {
              return
          }
          this.sendEditDriverTransport()
          this.$nextTick(() => {
              this.$bvModal.hide('modal-edit-transport-driver')
          })
      },
      checkFormValidityEditDriverTransport() {

          const valid = this.$refs.form_transport_driver_edit.checkValidity()

          return valid
      },
      sendEditDriverTransport() {
        this.status = ""
        const URL = "https://apiprzewoz.cuslapy.pl/transport/admin_transport/change_driver_data/" + this.public_uid + "/"
        const requestOptions = {
            method: 'PUT',
            headers: authHeader(),
            body: JSON.stringify(this.form_edit_driver_transport)
        };
        fetch(URL, requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.status != "ok") {
                    this.errors_edit_driver_data = json.message;
                    return;
                }
                this.fetchData()
                this.status_edit_driver_data = "Dane kierowcy zostały dodane"
            });
      },
      handleOkEditTransport(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.handleSubmitEditTransport()
      },
      handleSubmitEditTransport() {
          // Exit when the form isn't valid
          if (!this.checkFormValidityEditTransport()) {
              return
          }
          this.sendEditTransport()
          this.$nextTick(() => {
              this.$bvModal.hide('modal-edit-transport')
          })
      },
      checkFormValidityEditTransport() {
          const valid = this.$refs.form_transport_edit.checkValidity()


          // this.annotation_text_state = valid
          return valid
      },
      sendEditTransport() {
        this.status = ""
        const URL = "https://apiprzewoz.cuslapy.pl/transport/admin_transport/edit_transport/" + this.public_uid + "/"
        const requestOptions = {
            method: 'PUT',
            headers: authHeader(),
            body: JSON.stringify(this.form_edit_transport)
        };
        fetch(URL, requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.status != "ok") {
                    this.errors = json.message;
                    // console.log(json.message)
                    return;
                }
                this.fetchData()
                this.item.user_can_add_annotation = false
                this.status = "Zlecenie zostało zmodyfikowane"
            });
      },
        handleOkQuiz(bvModalEvt) {
              bvModalEvt.preventDefault()
              this.handleSubmitQuiz()
        },
        handleSubmitQuiz() {
            // Exit when the form isn't valid
            if (!this.checkFormValidityQuiz()) {
                return
            }
            this.sendQuizData()
            this.$nextTick(() => {
                this.$bvModal.hide('modal-quiz-after-transport')
            })
        },
        checkFormValidityQuiz() {
            const valid = this.$refs.form_quiz.checkValidity()

            if (!this.form_quiz.answer1) {
            this.form_quiz_state.answer1 = false
            }
            if (!this.form_quiz.answer2) {
            this.form_quiz_state.answer2 = false
            }
            if (!this.form_quiz.answer3) {
            this.form_quiz_state.answer3 = false
            }
            if (!this.form_quiz.answer4) {
            this.form_quiz_state.answer4 = false
            }
            // this.annotation_text_state = valid
            return valid
        },
        sendQuizData() {
          this.status = ""
          const URL = "https://apiprzewoz.cuslapy.pl/quiz/admin_quiz/after_transport/" + this.public_uid + "/"
          const requestOptions = {
              method: 'POST',
              headers: authHeader(),
              body: JSON.stringify(this.form_quiz)
          };
          fetch(URL, requestOptions)
              .then(response => response.json())
              .then(json => {
                  if (json.status != "ok") {
                      this.errors = json.message;
                      // console.log(json.message)
                      return;
                  }
                  //this.fetchData()
                  this.item.user_can_add_annotation = false
                  this.status = "Ankieta dodana"
              });
        },
        handleOk(bvModalEvt) {
                bvModalEvt.preventDefault()
                this.handleSubmit()
            },
            resetModal() {
                this.form.annotation_text = ""
                this.annotation_text_state = null
            },
            handleSubmit() {
                // Exit when the form isn't valid
                if (!this.checkFormValidity()) {
                    return
                }
                this.sendCancelData()
                this.$nextTick(() => {
                    this.$bvModal.hide('modal-prevent-closing')
                })
            },
            checkFormValidity() {
                const valid = this.$refs.form_cancel.checkValidity()
                this.annotation_text_state = valid
                return valid
            },
            sendCancelData() {
                this.status = ""
                const URL = "https://apiprzewoz.cuslapy.pl/transport/admin_transport/" + this.public_uid + "/cancel/"
                const requestOptions = {
                    method: 'POST',
                    headers: authHeader(),
                    body: JSON.stringify({
                        "annotation_text": this.form.annotation_text
                    })
                };
                fetch(URL, requestOptions)
                    .then(response => response.json())
                    .then(json => {
                        if (json.status != "ok") {
                            this.errors = json.message;
                            // console.log(json.message)
                            return;
                        }
                        this.status = "Anulowano zgłoszenie - OK"
                        this.fetchData()
                        this.item.user_can_add_annotation = false
                    });

            },
            deleteTransport() {
                this.status = ""
                const URL = "https://apiprzewoz.cuslapy.pl/transport/admin_transport/" + this.public_uid + "/delete/"
                const requestOptions = {
                    method: 'POST',
                    headers: authHeader(),
                    body: JSON.stringify({
                        "annotation_text": ""
                    })
                };
                fetch(URL, requestOptions)
                    .then(response => response.json())
                    .then(json => {
                        if (json.status != "ok") {
                            this.errors = json.message;
                            // console.log(json.message)
                            return;
                        }
                        this.status = "Zgłoszenie zostało usunięte"
                        window.scrollTo(0,0);

                    });

            },
            onChangeStatus(event) {
                this.status = ""
                let URL = "https://apiprzewoz.cuslapy.pl/transport/admin_transport/change_status/" + this.public_uid + "/"
                const requestOptions = {
                    method: 'PUT',
                    headers: authHeader(),
                    body: JSON.stringify({
                        "state": this.item.status.value
                    })
                };
                fetch(URL, requestOptions)
                    .then(response => response.json())
                    .then(json => {
                        // console.log("json:", json);
                        if (json.status != "ok") {
                            this.errors = json.message
                            this.$forceUpdate()
                            return;
                        }
                        this.status = "Status zmieniony"
                        this.item.status = json["data"]
                        this.$forceUpdate()
                    });
            },
            onFinishWork(event) {
                event.preventDefault()
                alert('Work finished', event.target.tagName)
            },
            onDeleteWork(event) {
                event.preventDefault()
                alert('Usuwanie nie wdrożone', event.target.tagName)
            },
            onChangeDriver(event) {
              this.status = ""
              this.spinner = true
                // event.preventDefault()
                let URL = "https://apiprzewoz.cuslapy.pl/transport/admin_transport/change_driver/" + this.public_uid + "/"
                const requestOptions = {
                    method: 'PUT',
                    headers: authHeader(),
                    body: JSON.stringify({
                        "public_id": this.driver_selected
                    })
                };
                fetch(URL, requestOptions)
                    .then(response => response.json())
                    .then(json => {
                        // console.log("json:", json);
                        this.spinner = false;
                        if (json.status != "ok") {
                            this.status = json.message
                            this.$forceUpdate()
                            return;
                        }
                        this.status = "Kierowca przypisany"
                        this.item.driver = json["data"]
                        this.$forceUpdate()
                    });
            },
            fetchDataDrivers() {
                let URL = "https://apiprzewoz.cuslapy.pl/user/drivers/"
                fetch(URL, {
                        'headers': authHeader()
                    })
                    .then(function(response) {
                        if (response.status == 401) {
                            // console.log(response.status);
                            localStorage.removeItem('user')
                            location.reload()
                        } else if (response.status != 200) {
                            // console.log("400 error:")
                            // console.log(response.json())
                        } else {
                            response.json().then(function(json) {
                                this.drivers = json
                            }.bind(this))
                        }
                    }.bind(this));

            },
            fetchData() {
                this.spinner = true
                this.public_uid = this.$route.params.id
                let URL = "https://apiprzewoz.cuslapy.pl/transport/admin_transport/"

                URL += this.public_uid + "/";

                fetch(URL, {
                        'headers': authHeader()
                    })
                    .then(function(response) {
                        this.spinner = false
                        if (response.status == 401) {
                            // console.log(response.status);
                            localStorage.removeItem('user')
                            location.reload()
                        } else if (response.status != 200) {
                            // console.log(response.json())
                        } else {
                            response.json().then(function(json) {
                                // console.log(json);
                                this.item.id = json["id"];
                                this.item.public_uid = json["public_uid"];
                                this.item.date_start = json["date_start"]
                                this.item.hour_start = json["hour_start"]
                                this.item.hour_end = json["hour_end"]
                                this.item.whence = json["whence"]
                                this.item.where = json["where"]
                                this.item.status = json["status"]
                                this.item.user = json["client"]
                                this.item.driver = json["driver"]
                                this.item.rules1 = this.response_status(json["rules1"])
                                this.item.destination_list_human = json["destination_list_human"]
                                this.item.destination_name_human = json["destination_name_human"]
                                this.item.type_help_list_human = json["type_help_list_human"]
                                this.item.need_help_in_deliver = this.response_status(json["need_help_in_deliver"])
                                this.item.type_help_text = json["type_help_text"]
                                this.item.distance = json["distance"]
                                this.item.time_trevel = json["time_trevel"]
                                this.item.waiting_time_for_person = json["waiting_time_for_person"]
                                this.item.all_annotations = json["all_annotations"]
                                this.form_edit_transport.whence = this.item.whence
                                this.form_edit_transport.where = this.item.where
                                this.form_edit_transport.date_start = this.item.date_start
                                this.form_edit_transport.hour_start = this.item.hour_start
                                this.form_edit_transport.hour_end = this.item.hour_end
                                this.form_edit_transport.need_help_in_deliver = json["need_help_in_deliver"]
                                this.form_edit_transport.destination_list = json["destination_list"]
                                this.form_edit_transport.type_help_list = json["type_help_list"]
                                this.form_edit_transport.type_help_text = json["type_help_text"]
                                this.form_edit_transport.destination_name = json["destination_name"]

                                this.item.comeback = json["comeback"]
                                this.item.original = json["original"]
                                this.item.info = json["info"]

                                this.form_edit_user.username = this.item.user.username
                                this.form_edit_user.email = this.item.user.email
                                this.form_edit_user.phone = this.item.user.phone
                                this.form_edit_user.age = this.item.user.age
                                this.form_edit_user.sex = this.item.user.sex
                                this.form_edit_user.is_wheelchair = this.item.user.is_wheelchair
                                this.form_edit_user.is_disab = this.item.user.is_disab
                                if (this.item.user.disab) {
                                  this.form_edit_user.disab = this.item.user.disab
                                }
                                else {
                                  this.form_edit_user.disab = ""
                                }
                                this.form_edit_user.address = this.item.user.address

                                this.form_edit_driver_transport.distance = this.item.distance
                                this.form_edit_driver_transport.time_trevel = this.item.time_trevel
                                this.form_edit_driver_transport.waiting_time_for_person = this.item.waiting_time_for_person
                                this.$forceUpdate()
                            }.bind(this))
                        }
                    }.bind(this));
            },

            fetchTransportOnThisDay  () {
              this.spinner = true
              let URL = "https://apiprzewoz.cuslapy.pl/transport/admin_transport/transport_day/?date_start=" + this.item.date_start

              fetch(URL, {'headers': authHeaderGet()})
                .then(function(response) {
                  if (response.status == 401) {
                    localStorage.removeItem('user')
                    location.reload()
                  }
                  else {
                    response.json().then(function(json) {
                      this.items = json.data["items"]
                      this.items_length = this.items.length
                      this.show = true ? (this.items_length) : false
                      this.spinner = false
                    }.bind(this))
                  }
                }.bind(this));


            },
            response_status(state) {
                if (Boolean(state)) {
                    return "tak"
                }
                return "nie"
            },
            show_comeback_button() {
              return this.item.original == null && this.item.comeback.length == 0
            },
            comeback_button() {
              if (this.item.comeback.length == 0) {
                return false;
              }
              return "/cms/order/" + this.item.comeback[0]["public_uid"]
            },
            original_button() {
              if (this.item.original == null) {
                return false;
              }
              return "/cms/order/" + this.item.original.public_uid
            }
    },
    computed: {
      this_public_uid() {
        return this.public_uid
      },
    },
}

</script>

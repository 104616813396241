<template>
    <div class="text-left">

      <b-button v-b-toggle.stats1 >Statystyki: kobiet/meżczyzn</b-button>
      <b-collapse id="stats1" class="mt-2">
        <b-card>
          <h5>Mężczyzn <b-badge>{{results.stats1.mans}}</b-badge></h5>
          <h5>Kobiet <b-badge>{{results.stats1.womens}}</b-badge></h5>
        </b-card>
      </b-collapse>

      <hr>

      <b-button v-b-toggle.stats2 >Statystyki: wiek</b-button>
      <b-collapse id="stats2" class="mt-2">
        <b-card>
          <h5>18-30 <b-badge>{{results.stats2.res1}}</b-badge></h5>
          <h5>21-60 <b-badge>{{results.stats2.res2}}</b-badge></h5>
          <h5>61-74 <b-badge>{{results.stats2.res3}}</b-badge></h5>
          <h5>75+ <b-badge>{{results.stats2.res4}}</b-badge></h5>
        </b-card>
      </b-collapse>

        <hr>

      <b-button v-b-toggle.stats3 >Statystyki: Skad dowiedziałeś się ...</b-button>
      <b-collapse id="stats3" class="mt-2">
        <b-card>
          <h5>Gazeta Łapska <b-badge>{{results.stats3.answers.answer1}}</b-badge></h5>
          <h5>strona internetowa: www.lapy.pl <b-badge>{{results.stats3.answers.answer2}}</b-badge></h5>
          <h5>strona internetowa: www.mopslapy.pl <b-badge>{{results.stats3.answers.answer3}}</b-badge></h5>
          <h5>ulotki, plakaty <b-badge>{{results.stats3.answers.answer4}}</b-badge></h5>
          <h5>pracownicy UM, MOPS <b-badge>{{results.stats3.answers.answer5}}</b-badge></h5>
          <h5>radni Rady Miejskiej, sołtysi <b-badge>{{results.stats3.answers.answer6}}</b-badge></h5>
          <h5>inne (jakie?) <b-badge>{{results.stats3.answers.answer7}}</b-badge> {{ results.stats3.answers_text}}</h5>
        </b-card>
      </b-collapse>

        <hr>

      <b-button v-b-toggle.stats4 >Statystyki: Osoby na wóżku inwalidzkim</b-button>
      <b-collapse id="stats4" class="mt-2">
        <b-card>
          <h5>Liczba <b-badge>{{results.stats4.yes}}/{{results.stats4.all}}</b-badge></h5>
        </b-card>
      </b-collapse>

      <hr>

      <b-button v-b-toggle.stats5 >Statystyki: Ile osób niepełnosprawnych</b-button>
      <b-collapse id="stats5" class="mt-2">
        <b-card>
          <h5>Niepełnosprawnych <b-badge>{{results.stats5.disab_count}}</b-badge></h5>
        </b-card>
      </b-collapse>

      <div class="text-center" v-if="spinner">
          <b-spinner variant="primary" label="Text Centered"></b-spinner>
      </div>
    </div>
</template>


<script>

import { authHeaderGet } from '/src/_helpers/auth-header.js'

export default {
  name: 'StatsByUsers',
  data () {
    return {
      user: {},
      spinner: false,
      stats1: {},
      results: {"stats1": {}, "stats2": {}, "stats3": {"answers": {}, "answers_text": []}, "stats4": {}, "stats5": {}},
    }
  },
  mounted() {
   this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
     if ( collapseId=="stats1" && isJustShown) {
        var url = "https://apiprzewoz.cuslapy.pl/stats/admin/stats_by_man_woman/"
        this.fetchStats("stats1", url)
     }
     else if ( collapseId=="stats2" && isJustShown) {
        var url = "https://apiprzewoz.cuslapy.pl/stats/admin/stats_by_person_age/"
        this.fetchStats("stats2", url)
     }
     else if ( collapseId=="stats3" && isJustShown) {
        var url = "https://apiprzewoz.cuslapy.pl/stats/admin/stats_by_from_you_know/"
        this.fetchStats("stats3", url)
     }
     else if ( collapseId=="stats4" && isJustShown) {
        var url = "https://apiprzewoz.cuslapy.pl/stats/admin/stats_by_count_wheelchair/"
        this.fetchStats("stats4", url)
     }
     else if ( collapseId=="stats5" && isJustShown) {
        var url = "https://apiprzewoz.cuslapy.pl/stats/admin/stats_by_count_disab/"
        this.fetchStats("stats5", url)
     }


     console.log('collapseId:', collapseId)
     console.log('isJustShown:', isJustShown)
   })
 },
  methods: {
    fetchStats(name_stats, url) {
      this.spinner = true
      fetch(url, {'headers': authHeaderGet()})
        .then(function(response) {
          response.json().then(function(json) {
            console.log(json.data);
            this.spinner = false
            this.results[name_stats] = json
          }.bind(this))

        }.bind(this));


    }

  }

}
</script>

<script>

  import { check_user, authBasicHeader } from '/src/_helpers/auth-header.js'
  import { true_false_options, man_woman_options, register_question1_options } from '/src/_helpers/forms.js'
  import VueRecaptcha from 'vue-recaptcha'
  const URL = "https://apiprzewoz.cuslapy.pl/user/register"

  export default {
    name: 'Register',
    components: { VueRecaptcha },
    data() {
      return {
        user: "",
        form: {"sex": null, "rules1": false, "address": {}, "password": ""},
        success: false,
        errors: "",
        show: true,
        sex_options: man_woman_options(),
        question1_options: register_question1_options(),
        rules: [
				{ message:'przynajmniej jedna mała litera,', regex:/[a-z]+/ },
				{ message:"przynajmniej jedna wielka litera,",  regex:/[A-Z]+/ },
				{ message:"minimum 8 znaków,", regex:/.{8,}/ },
				{ message:"wymagana jedna cyfra,", regex:/[0-9]+/ }
			  ],
        submitted: false,
        valid: true,
        msg: {"password2": null, "email": null},
        spinner: false,
        niepelno_options: true_false_options(),
        captcha_status: false,
      }
    },
    mounted() {
      this.user = check_user()
    },
    methods: {
      onVerifyCaptcha: function(response) {
        if (response) {
          this.captcha_status = true
        }
        console.log('Verify: ' + response)
      },
      onSubmit(event) {
        this.errors = false
        event.preventDefault()
        if (!this.captcha_status) {
          this.errors = 'Zaznacz kod wyeryfikujący, że nie jesteś robotem'
          return false
        }
        if (!this.form.answers_list) {
          this.errors = 'Odpowiedz na pytanie: Skąd dowiedziałeś/dowiedziałaś się o usłudze door-to-door'
          return false
        }
        this.spinner = true;
        this.msg = {"password2": null, "email": null}

        this.submitted = true
        this.validate()

        if (!this.valid) {
          this.spinner = false;
          return
        }

        this.form.rules3 = Boolean(this.form.rules3)
        this.form.rules1 = Boolean(this.form.rules1)
        this.form.rules2 = Boolean(this.form.rules2)
        this.form.rules4 = Boolean(this.form.rules4)
        this.form.rules5 = Boolean(this.form.rules5)
        this.form.rules6 = Boolean(this.form.rules6)
        this.form.rules7 = Boolean(this.form.rules7)

        const requestOptions = {
         method: 'POST',
         headers: authBasicHeader(),
         body: JSON.stringify(this.form)
         };
         fetch(URL, requestOptions)
             .then(response => response.json())
             .then(json => {
               if (json.status != "ok") {
                 if (json.message == "user_already_exists") {
                   this.errors = "Popraw błędy, podany użytkownik już istneje"
                   this.msg.email = true
                 }
                 else {
                   this.errors = json.message
                 }
                 this.spinner = false;
                 return;
               }
               console.log(json);
               this.errors = false;
               this.success = true;
               this.show = false;
               this.spinner = false;
               // this.user = json.user;
               // localStorage.setItem('user', JSON.stringify(json.user));
               // this.$router.push('/')
             });

      },
      validate() {
        if (!this.validate_passwordValidation()) {
          this.valid = false
        }
        this.valid = true
      },
      validate_passwordValidation () {
    			let errors = []
          if (this.form.password !== this.form.password2) {
            return false
          }
    			for (let condition of this.rules) {
    				if (!condition.regex.test(this.form.password)) {
    					errors.push(condition.message)
    				}
    			}
    			if (errors.length === 0) {
    				return true
    			} else {
    				return false
    			}
    		}
    },
    computed: {
      password_validation() {
        if (this.form.password)
          return this.form.password == this.form.password2
        return null
      },
      state_rules1() {
        if (this.form.rules1) {
          return true
        }
       return null
       },
       state_rules2() {
         if (this.form.rules2) {
           return true
         }
        return null
      },
      state_rules4() {
        if (this.form.rules4) {
          return true
        }
       return null
     },
     state_rules5() {
       if (this.form.rules5) {
         return true
       }
      return null
    },
    state_rules6() {
      if (this.form.rules6) {
        return true
      }
     return null
   },
   state_rules7() {
     if (this.form.rules7) {
       return true
     }
    return null
  },
      state_rules3() {
        if (this.form.rules3) {
          return true
        }
       return null
     },
     form_sex_state() {
        return Boolean(this.form.sex)
      },
    passwordValidation() {
  			let errors = []
  			for (let condition of this.rules) {
  				if (!condition.regex.test(this.form.password)) {
  					errors.push(condition.message)
  				}
  			}
  			if (errors.length === 0) {
  				return { valid: true, errors }
  			} else {
  				return { valid: false, errors }
  			}
  		},
      passwordValidationStatus() {
    			let errors = []
          if (this.form.password == "") {
            return null
          }
    			for (let condition of this.rules) {
    				if (!condition.regex.test(this.form.password)) {
    					errors.push(condition.message)
    				}
    			}
    			if (errors.length === 0) {
    				return true
    			} else {
    				return false
    			}
    		},

    }

  }

</script>

<template>



  <div v-if="user">

    <p>Jesteś już zarejestrowany</p>

  </div>
  <div v-else>
    <div class="text-left">
      <b-alert v-if="errors" show variant="danger">{{errors}}</b-alert>
      <b-alert show variant="success" v-if="success">Rejestracja prawidłowa za chwilę otrzymasz email, aby potwierdzic rejestrację.</b-alert>

        <b-form @submit="onSubmit" v-if="show">

          <b-form-group
            id="input-group-1"
            label="Imię i nazwisko"
            label-for="username"
            description=""
          >
            <b-form-input
              id="username"
              v-model="form.username"
              type="text"
              placeholder=""
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-2"
            label="Email/login"
            label-for="email"
            description="Wymagany do logowania się w serwisie."
          >
            <b-form-input
              id="email"
              v-model="form.email"
              type="email"
              placeholder=""
              required
            ></b-form-input>

            <div class="d-block invalid-feedback" v-if="msg.email">Użytkownik z takim adresem email, już istnieje. </div>

          </b-form-group>

          <b-container class="bv-example-row">
          <b-row>
            <b-col>

              <b-form-group
                id="input-group-3"
                label="Hasło"
                label-for="password"
                description=""
              >
                <b-form-input
                  id="password"
                  v-model="form.password"
                  type="password"
                  placeholder=""
                  :state="passwordValidationStatus"
                  required
                ></b-form-input>
              </b-form-group>

              <div v-if='passwordValidation.errors.length > 0 && submitted' class="d-block invalid-feedback">
                <small v-for='error in passwordValidation.errors' v-bind:key="error">{{error}} </small>
              </div>

              <div v-if='passwordValidation.errors.length > 0 && !submitted' class="d-block ">
        			<small v-for='error in passwordValidation.errors' v-bind:key="error">{{error}} </small>
        		</div></b-col>
            <b-col>

              <b-form-group
              id="input-group-3"
              label="Powtórz hasło"
              label-for="password2"
              description=""
            >
              <b-form-input
                id="password2"
                v-model="form.password2"
                type="password"
                placeholder=""
                :state="password_validation"
                required
              ></b-form-input>

              <b-form-invalid-feedback :state="password_validation">
                Pole hasło i powtórz hasło muszą być identyczne.
              </b-form-invalid-feedback>
              <b-form-valid-feedback :state="password_validation">
                Ok. Hasła są identyczne.
              </b-form-valid-feedback>

              </b-form-group></b-col>
          </b-row>
        </b-container>

        <br>
        <hr>

            <b-form-group
              id="input-group-2"
              label="Wiek"
              label-for="age"
              description=""
            >
              <b-form-input
                id="age"
                v-model="form.age"
                type="number"
                placeholder=""
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-2"
              label="Telefon"
              label-for="phone"
              description=""
            >
              <b-form-input
                id="phone"
                v-model="form.phone"
                type="tel"
                placeholder=""
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Czy jesteś osobą niepełnosprawną?" >
              <b-form-radio-group
                id="niepelno"
                v-model="form.is_disab"
                :options="niepelno_options"
                required
              ></b-form-radio-group>
            </b-form-group>


            <b-form-group
              id="input-group-2"
              label=""
              label-for="disab"
              description=""
              v-if="form.is_disab == true"
            >
              <b-form-input
                id="disab"
                v-model="form.disab"
                type="text"
                placeholder="Podaj kod niepełnosprawności"
                description="Wskazanie potrzeby wsparcia w zakresie mobilności uzasadniającej skorzystanie z usługi (np. niepełnosprawność lub inne)."
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-2"
              label=""
              label-for="disab"
              description=""
              v-if="form.is_disab == false"
            >
              <b-form-input
                id="disab"
                v-model="form.disab"
                type="text"
                placeholder="Podaj inne ograniczenia w zakresie poruszania się"
                description="Wskazanie potrzeby wsparcia w zakresie mobilności uzasadniającej skorzystanie z usługi (np. niepełnosprawność lub inne)."
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Czy osoba zamawiająca porusza się na wózku inwalidzkim?" >
              <b-form-radio-group
                id="wheelchair"
                v-model="form.is_wheelchair"
                :options="niepelno_options"
                required
              ></b-form-radio-group>
            </b-form-group>

            <b-form-group label="Płeć" >
              <b-form-radio-group
                id="sex"
                v-model="form.sex"
                :options="sex_options"
                required
              ></b-form-radio-group>
            </b-form-group>

            <hr>

            <b-form-checkbox
              id="rules1"
              v-model="form.rules1"
              name="rules1"
              unchecked-value=false
              :state="state_rules1"
              required
            >
              Wyrażam zgodę na przetwarzanie moich danych osobowych: imienia, nazwiska i adresu poczty elektronicznej w celu rejestracji konta w portalu przewoz.cuslapy.pl oraz umożliwienie rezerwacji usługi indywidualnego transportu door-to-door w Gminie Łapy za pośrednictwem tego portalu. Szczegółowe informacje o przetwarzaniu danych osobowych dostępnę są <a target="_blank" href="/rejestracja-klauzura">TUTAJ</a>.
            </b-form-checkbox>

            <b-form-checkbox
              id="rules2"
              v-model="form.rules2"
              name="rules2"
              value=true
              unchecked-value=false
              :state="state_rules2"
              required
            >
              Oświadczam, iż spełniam kryteria do skorzystania z usługi transportu door-to-door.
            </b-form-checkbox>

            <b-form-checkbox
              id="rules4"
              v-model="form.rules4"
              name="rules4"
              value=true
              unchecked-value=false
              :state="state_rules4"
              required
            >
              Oświadczam, iż jestem mieszkańcem Gminy Łapy.
            </b-form-checkbox>

            <b-form-checkbox
              id="rules5"
              v-model="form.rules5"
              name="rules5"
              value=true
              unchecked-value=false
              :state="state_rules5"
              required
            >
              Oświadczam, iż jestem osobą z potrzebą wsparcia w zakresie mobilności. Osoby z potrzebą wsparcia w zakresie mobilności -
              <a href="javascript:;" id="tooltip-target-1">
                CZYTAJ WIĘCEJ
              </a>.
              <b-tooltip target="tooltip-target-1" triggers="hover">
                <b>Osoby z potrzebą wsparcia w zakresie mobilności</b> - osoby, które mają trudności w samodzielnym przemieszczaniu się np. ze względu na ograniczoną sprawność (w tym: poruszające się na wózkach inwalidzkich, poruszające się o kulach, niewidome, słabowidzące i in.). Będą to zarówno osoby z potrzebą wsparcia w zakresie mobilności posiadające orzeczenie o stopniu niepełnosprawności (lub równoważne), jak i osoby nieposiadające takiego orzeczenia.)
              </b-tooltip>
            </b-form-checkbox>

            <b-form-checkbox
              id="rules6"
              v-model="form.rules6"
              name="rules6"
              value=true
              unchecked-value=false
              :state="state_rules6"
              required
            >
              Oświadczam, że powyższe dane są prawdziwe. Oświadczam, iż jestem świadomy/świadoma odpowiedzialności karnej za złożenie fałszywego oświadczenia wynikającego z art. 233 § 1 Kodeksu karnego.
            </b-form-checkbox>


            <b-form-checkbox
              id="rules7"
              v-model="form.rules7"
              name="rules7"
              value=true
              unchecked-value=false
              :state="state_rules7"
              required
            >
              Oświadczam, iż zapoznałam się z Regulaminem świadczenia usług transportowych door-to-door w ramach projektu pt. "Usługi indywidualnego transportu door-to-door w Gminie Łapy" (dostępny <a target="_blank" href="http://www.mopslapy.pl/uploads/2021/04/4423_5_Regulamin_%C5%9Bwiadczenia_us%C5%82_trans_door_to_door_za%C5%82_do_umowy_3a_Za%C5%82_do_zarz%C4%85dzenia_kolor.pdf">TUTAJ</a>) i akceptuję jego warunki.
            </b-form-checkbox>

            <b-form-checkbox
              id="rules3"
              v-model="form.rules3"
              name="rules3"
              value=true
              unchecked-value=false
              :state="state_rules3"
              required
            >
              Zostałem/am poinformowany/poinformowana o celu i zakresie gromadzonych danych osobowych oraz o administratorze tych danych – zgodnie z przepisami wynikającymi z Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych), oraz ustawy z dnia 10 maja 2018 r. o ochronie danych osobowych (Dz. U. z 2019 r. poz. 1781). <a target="_blank" href="/rejestracja-klauzura">TUTAJ</a>
            </b-form-checkbox>

            <hr>

            <b-form-group label="Skąd dowiedziałeś/dowiedziałaś się o usłudze door-to-door (proszę o zaznaczenie właściwej odpowiedzi):" v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group
                id="checkbox-group-1-question_1"
                v-model="form.answers_list"
                :options="question1_options"
                :aria-describedby="ariaDescribedby"
                name="flavour-1"
                stacked
              ></b-form-checkbox-group>
            </b-form-group>

            <b-form-group
              id="checkbox-group-1-question_1_addional"
              label=""
              label-for="question_1_additional"
              description=""
            >
              <b-form-input
                id="question_1_additional"
                v-model="form.answers_additional"
                type="text"
                placeholder=""
              ></b-form-input>
            </b-form-group>

            <p>
            <vue-recaptcha @verify="onVerifyCaptcha" sitekey="6LdEQNIaAAAAADc_zixlNhMGdF95wFaANiTMQtE9" :loadRecaptchaScript="true"></vue-recaptcha>
          </p>

          <p>
          <b-alert v-if="errors" show variant="danger">{{errors}}</b-alert>
          </p>

          <b-button type="submit" variant="primary">Zarejestruj się</b-button>

        </b-form>

        <div class="clearfix" v-if="spinner">
          <b-spinner class="float-right" label="Floated Right"></b-spinner>
        </div>

    </div>


  </div>



</template>

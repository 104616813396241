

<template>

<div>
    <h2>Dane konta</h2>
    <div class="text-left">

        <b-alert v-if="errors" show variant="danger">{{errors}}</b-alert>
        <b-alert show variant="success" v-if="success">Dane zmienione</b-alert>

        <b-form @submit="onSubmit" v-if="show">

            <b-form-group id="input-group-1" label="Imię i nazwisko" label-for="username" description="">
                <b-form-input id="username" v-model="form.username" type="text" placeholder="" required></b-form-input>
            </b-form-group>


            <b-card bg-variant="light">
                <b-form-group label-cols-lg="3" label="Adres zamieszkania" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">

                  <b-form-group label="Miejscowość:" label-for="nested-street" label-cols-sm="3" label-align-sm="right">
                      <b-form-input id="city" v-model="form.address.city" type="text" placeholder="" required></b-form-input>
                  </b-form-group>

                    <b-form-group label="Ulica:" label-for="nested-street" label-cols-sm="3" label-align-sm="right">
                        <b-form-input id="street" v-model="form.address.street" type="text" placeholder=""></b-form-input>
                    </b-form-group>

                    <b-form-group label="Numer domu:" label-for="nested-street" label-cols-sm="3" label-align-sm="right">
                        <b-form-input id="number" v-model="form.address.number" type="text" placeholder="" required></b-form-input>
                    </b-form-group>

                    <b-form-group label="Numer lokalu:" label-for="nested-street" label-cols-sm="3" label-align-sm="right">
                        <b-form-input id="second_number" v-model="form.address.second_number" type="text" placeholder=""></b-form-input>
                    </b-form-group>

                    <b-form-group label="Gmina:" label-for="nested-street" label-cols-sm="3" label-align-sm="right">
                        <b-form-input id="code" v-model="form.address.code" type="text" placeholder="" required></b-form-input>
                    </b-form-group>

                </b-form-group>
            </b-card>

            <br>

            <b-form-group id="input-group-2" label="Wiek" label-for="age" description="">
                <b-form-input id="age" v-model="form.age" type="number" placeholder="" required></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-2" label="Telefon" label-for="phone" description="">
                <b-form-input id="phone" v-model="form.phone" type="tel" placeholder="" required></b-form-input>
            </b-form-group>


            <b-form-group id="input-group-2" label="Czy jesteś osobą niepełnosprawną ?" label-for="disab" description="">
                <b-form-input id="disab" v-model="form.disab" type="text" placeholder="Podaj kod niepełnosprawności lub inne wskazanie w zakresie mobilności" description="Wskazanie potrzeby wsparcia w zakresie mobilności uzasadniającej skorzystanie z usługi (np. niepełnosprawność lub inne)"></b-form-input>
            </b-form-group>

            <b-form-group label="Płeć">
                <b-form-radio-group id="sex" v-model="form.sex" :options="sex_options" required></b-form-radio-group>
            </b-form-group>

            <hr>

            <b-form-checkbox id="rules1" v-model="form.rules1" name="rules1" unchecked-value=false :state="state_rules1" required>
                Zobowiązuję się do przestrzegania zasad <a target="_blank" href="http://www.mopslapy.pl/uploads/2021/04/4423_5_Regulamin_%C5%9Bwiadczenia_us%C5%82_trans_door_to_door_za%C5%82_do_umowy_3a_Za%C5%82_do_zarz%C4%85dzenia_kolor.pdf">Regulaminu</a>                świadczenia usług transportowych door-to-door w ramach projektu pt. „Usługi indywidualnego transportu door-to-door w Gminie Łapy”.
            </b-form-checkbox>

            <b-form-checkbox id="rules2" v-model="form.rules2" name="rules2" value=true unchecked-value=false :state="state_rules2" required>
                Oświadczam, iż spełniam kryteria do skorzystania z usługi transportu door-to-door
            </b-form-checkbox>

            <b-form-checkbox id="rules3" v-model="form.rules3" name="rules3" value=true unchecked-value=false :state="state_rules3" required>
                Zostałem/am poinformowany/poinformowana o celu i zakresie gromadzonych danych osobowych oraz o administratorze tych danych – zgodnie z przepisami wynikającymi z Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w
                sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych), oraz ustawy z dnia 10 maja 2018 r.
                o ochronie danych osobowych (Dz. U. z 2019 r. poz. 1781).
            </b-form-checkbox>


            <p></p>

            <b-button type="submit" variant="primary">Zmień dane</b-button>

        </b-form>

        <div class="clearfix" v-if="spinner">
          <b-spinner class="float-right" label="Floated Right"></b-spinner>
        </div>

    </div>
</div>

</template>

<script>

import {
    check_user, authHeader
}
from '/src/_helpers/auth-header.js'
const URL = "https://apiprzewoz.cuslapy.pl/user/change_data/"

export default {
    name: 'ChangeData',
    data() {
        return {
            user: "",
            form: {
                "sex": null,
                "rules1": false,
                "address": {},
                "password": ""
            },
            success: false,
            errors: "",
            show: true,
            sex_options: [{
                text: 'Kobieta',
                value: 1
            }, {
                text: 'Męższczyzna',
                value: 2
            }, ],
            submitted: false,
            valid: true,
            msg: {},
            spinner: false,
        }
    },
    watch: {
      // call again the method if the route changes
      '$route': 'fetchData'
    },
    mounted() {
        this.user = check_user()
    },
    created() {
      this.fetchData()
    },
    methods: {
      fetchData() {
        this.spinner = true
        fetch(URL, {'headers': authHeader()})
          .then(function(response) {
            if (response.status == 401) {
              console.log(response.status)
              localStorage.removeItem('user')
              location.reload()
            }
            else if (response.status != 200) {
              console.log(response.json())
            }
            else {
              response.json().then(function(json) {
                this.form = json
              }.bind(this))
            }
          }.bind(this));
          this.spinner = false
        },
        onSubmit(event) {
                event.preventDefault()
                this.submitted = true
                this.validate()

                if (!this.valid) {
                    return
                }
                this.form.rules3 = Boolean(this.form.rules3)
                this.form.rules1 = Boolean(this.form.rules1)
                this.form.rules2 = Boolean(this.form.rules2)

                const requestOptions = {
                    method: 'POST',
                    headers: authHeader(),
                    body: JSON.stringify(this.form)
                };
                fetch(URL, requestOptions)
                    .then(response => response.json())
                    .then(json => {
                        if (json.status != "ok") {
                            this.errors = json.message
                            return;
                        }
                        console.log(json)
                        this.errors = 0
                        this.success = true
                        this.show = false
                        // this.user = json.user;
                        // localStorage.setItem('user', JSON.stringify(json.user));
                        // this.$router.push('/')
                    });

            },
            validate() {
                this.valid = true
            },

    },
    computed: {

            state_rules1() {
                return Boolean(this.form.rules1)
            },
            state_rules2() {
                return Boolean(this.form.rules2)
            },
            state_rules3() {
                return Boolean(this.form.rules3)
            },
            form_sex_state() {
                return Boolean(this.form.sex)
            },


    }

}

</script>

<template>
    <div  class="text-left">

  <div class="politykaPrywatnosci">
	<h1>Polityka prywatności</h1>
	<hr>
	<h2>Polityka prywatności opisuje zasady przetwarzania przez nas informacji na Twój temat, w tym danych osobowych oraz ciasteczek, czyli tzw. cookies.</h2>
	<hr>
	<div class="lh166">
    <h2>1. Informacje ogólne</h2>
    <ol>
      <li>Niniejsza polityka dotyczy Serwisu www, funkcjonującego pod adresem url: <b>przewoz.cuslapy.pl</b></li>
      <li>Operatorem serwisu oraz Administratorem danych osobowych jest: Miejski Ośrodek Pomocy Społecznej w Łapach, reprezentowane przez Dyrektora, z siedzibą przy ul. Głównej 50, 18-100 Łapy, tel. +48 (85) 715 25 50, e-mail: kontakt@cuslapy.pl</li>
      <li>Administrator, zgodnie z art. 37 ust. 1 lit. a) RODO, powołał Inspektora Ochrony Danych, z którym w sprawach związanych z przetwarzaniem danych osobowych, może się Pani/Pan kontaktować za pomocą poczty elektronicznej pod adresem: kontakt@cuslapy.pl</li>
      <li>Serwis wykorzystuje dane osobowe w następujących celach:</li>
        <ul>
          <li>rejestracji konta oraz umożliwienia rezerwacji usługi indywidualnego transportu door-to-door w Gminie Łapy, na podstawie wyrażonej zgody (art. 6 ust. 1 lit. a RODO);</li>
          <li>w celu świadczenia usług transportowych door-to-door w ramach projektu pt. „Usługi indywidualnego transportu door-to-door w Gminie Łapy”, w tym w celu zamówienia usługi transportu indywidualnego door-to-door zgłaszania uwag, propozycji co do zakresu i sposobu świadczenia usług indywidualnego transportu door-to-door, zgłaszania skarg i reklamacji dotyczących jakości usług indywidualnego transportu door-to-door oraz ankietowania po zakończeniu usługi na podstawie art. 6 ust. 1 lit. e RODO oraz art. 9 ust. 2 lit. b RODO.</li>
        </ul>
      <li>Serwis realizuje funkcje pozyskiwania informacji o użytkownikach i ich zachowaniu w następujący sposób:</li>
        <ol>
          <li>Poprzez dobrowolnie wprowadzone w formularzach dane, które zostają wprowadzone do systemów Operatora.</li>
          <li>Poprzez zapisywanie w urządzeniach końcowych plików cookie (tzw. „ciasteczka”).</li>
        </ol>
    </ol>
  <h2>2. Wybrane metody ochrony danych stosowane przez Operatora</h2>
    <ol>
      <li>Miejsca logowania i wprowadzania danych osobowych są chronione w warstwie transmisji (certyfikat SSL). Dzięki temu dane osobowe i dane logowania, wprowadzone na stronie, zostają zaszyfrowane w komputerze użytkownika i mogą być odczytane jedynie na docelowym serwerze.</li>
      <li>Hasła użytkowników są przechowywane w postaci hashowanej. Funkcja hashująca działa jednokierunkowo - nie jest możliwe odwrócenie jej działania, co stanowi obecnie współczesny standard w zakresie przechowywania haseł użytkowników.</li>
      <li>W celu ochrony danych Operator regularnie wykonuje kopie bezpieczeństwa.</li>
      <li>Istotnym elementem ochrony danych jest regularna aktualizacja wszelkiego oprogramowania, wykorzystywanego przez Operatora do przetwarzania danych osobowych, co w szczególności oznacza regularne aktualizacje komponentów programistycznych.</li>
    </ol>
  <h2>3. Hosting</h2>
      <ol>
      <li>Serwis jest hostowany (technicznie utrzymywany) na serwera operatora: hosting.domena.pl</li>
      </ol>
  <h2>4. Twoje prawa i dodatkowe informacje o sposobie wykorzystania danych</h2>
    <ol>
      <li>W niektórych sytuacjach Administrator ma prawo przekazywać Twoje dane osobowe innym odbiorcom. Dotyczy to takich grup odbiorców jak: upoważnieni pracownicy i współpracownicy, dostawcy usług informatycznych Administratora, dostawcy usług pocztowych i kurierskich, obsługa prawna Administratora oraz inne podmioty uprawnione na podstawie przepisów prawa.</li>
      <li>Przetwarzanie danych osobowych na podstawie wyrażonej zgody odbywać się będzie do czasu cofnięcia zgody lub zaprzestania przez Administratora świadczenia usługi indywidualnego transportu door-to-door w Gminie Łapy. W celu usunięcia konta należy wysłać email na adres kontakt@cuslapy.pl z prośbą o usunięcie konta z adresu, na którym nastąpiła rejestracja w serwisie, podając też login.</li>
      <li>Przetwarzanie danych osobowych w celu świadczenia usług transportowych door-to-door w ramach projektu pt. „Usługi indywidualnego transportu door-to-door w Gminie Łapy” odbywać się będzie przez okres niezbędny do realizacji tego celu oraz przez okres wynikający z przepisów prawa dotyczący archiwizacji.</li>
      <li>Przysługuje Pani/Panu prawo dostępu do treści swoich danych, do sprostowania danych, do usunięcia danych oraz do ograniczenia przetwarzania danych.</li>
      <li>Przysługuje Pani/Panu prawo do cofnięcia zgody w dowolnym momencie bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem.</li>
      <li>Gdy uzna Pani/Pan, że przetwarzanie danych osobowych narusza powszechnie obowiązujące przepisy w tym zakresie, przysługuje Pani/Panu prawo do wniesienia skargi do organu nadzorczego - Prezes Urzędu Ochrony Danych Osobowych.</li>
      <li>Podanie danych osobowych w celu rejestracji konta oraz umożliwienia rezerwacji usługi indywidualnego transportu door-to-door w Gminie Łapy jest dobrowolne, jednak niezbędne do rejestracji w Serwisie oraz umożliwienia rezerwacji usługi indywidualnego transportu door-to-door w Gminie Łapy za pośrednictwem tego Serwisu.</li>
      <li>Podanie danych osobowych w celu świadczenia usług transportowych door-to-door w ramach projektu pt. „Usługi indywidualnego transportu door-to-door w Gminie Łapy” jest niezbędne do świadczenia usług transportowych door-to-door i jest Pani/Pan zobowiązana/y do ich podania. Konsekwencją niepodania danych osobowych będzie brak możliwości korzystania z usług transportowych door-to-door.</li>
      <li>Dane osobowe nie będą wykorzystywane do zautomatyzowanego podejmowania decyzji ani profilowania, o którym mowa w art. 22.</li>
    </ol>
  <h2>5. Informacje w formularzach</h2>
    <ol>
      <li>Serwis zbiera informacje podane dobrowolnie przez użytkownika, w tym dane osobowe, o ile zostaną one podane.</li>
      <li>Serwis może zapisać informacje o parametrach połączenia (oznaczenie czasu, adres IP).</li>
      <li>Serwis, w niektórych wypadkach, może zapisać informację ułatwiającą powiązanie danych w formularzu z adresem e-mail użytkownika wypełniającego formularz.</li>
    </ol>
  <h2>6. Logi Administratora</h2>
    <ol>
      <li>Informacje zachowaniu użytkowników w serwisie mogą podlegać logowaniu. Dane te są wykorzystywane w celu administrowania serwisem.</li>
    </ol>
  <h2>7. Informacja o plikach cookies</h2>
    <ol>
      <li>Serwis korzysta z plików cookies.</li>
      <li>Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w szczególności pliki tekstowe, które przechowywane są w urządzeniu końcowym Użytkownika Serwisu i przeznaczone są do korzystania ze stron internetowych Serwisu. Cookies zazwyczaj zawierają nazwę strony internetowej, z której pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny numer.</li>
      <li>Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika Serwisu pliki cookies oraz uzyskującym do nich dostęp jest operator Serwisu.</li>
      <li>Pliki cookies wykorzystywane są w następujących celach:</li>
        <ol>
          <li>utrzymanie sesji użytkownika Serwisu (po zalogowaniu), dzięki której użytkownik nie musi na każdej podstronie Serwisu ponownie wpisywać loginu i hasła;</li>
          <li>konfiguracji serwisu/aplikacji;</li>
        </ol>
      <li>W ramach Serwisu stosowane są dwa zasadnicze rodzaje plików cookies: „sesyjne” (session cookies) oraz „stałe” (persistent cookies). Cookies „sesyjne” są plikami tymczasowymi, które przechowywane są w urządzeniu końcowym Użytkownika do czasu wylogowania, opuszczenia strony internetowej lub wyłączenia oprogramowania (przeglądarki internetowej). „Stałe” pliki cookies przechowywane są w urządzeniu końcowym Użytkownika przez czas określony w parametrach plików cookies lub do czasu ich usunięcia przez Użytkownika.</li>
      <li>Oprogramowanie do przeglądania stron internetowych (przeglądarka internetowa) zazwyczaj domyślnie dopuszcza przechowywanie plików cookies w urządzeniu końcowym Użytkownika. Użytkownicy Serwisu mogą dokonać zmiany ustawień w tym zakresie. Przeglądarka internetowa umożliwia usunięcie plików cookies. Możliwe jest także automatyczne blokowanie plików cookies Szczegółowe informacje na ten temat zawiera pomoc lub dokumentacja przeglądarki internetowej.</li>
      <li>Ograniczenia stosowania plików cookies mogą wpłynąć na niektóre funkcjonalności dostępne na stronach internetowych Serwisu.</li>
     </ol>
  <h2>8. Zarządzanie plikami cookies – jak w praktyce wyrażać i cofać zgodę?</h2>
    <ol>
      <li>Jeśli użytkownik nie chce otrzymywać plików cookies, może zmienić ustawienia przeglądarki. Zastrzegamy, że wyłączenie obsługi plików cookies niezbędnych dla procesów uwierzytelniania, bezpieczeństwa, utrzymania preferencji użytkownika może utrudnić, a w skrajnych przypadkach może uniemożliwić korzystanie ze stron www</li>
      <li>W celu zarządzania ustawienia cookies wybierz z listy poniżej przeglądarkę internetową, której używasz i postępuj zgodnie z instrukcjami:</li>
      <ul>
        <li><a href="https://support.microsoft.com/pl-pl/help/10607/microsoft-edge-view-delete-browser-history">Edge</a></li>
        <li><a href="https://support.microsoft.com/pl-pl/help/278835/how-to-delete-cookie-files-in-internet-explorer">Internet Explorer</a></li>
        <li><a href="http://support.google.com/chrome/bin/answer.py?hl=pl&amp;answer=95647">Chrome</a></li>
        <li><a href="http://support.apple.com/kb/PH5042">Safari</a></li>
        <li><a href="http://support.mozilla.org/pl/kb/W%C5%82%C4%85czanie%20i%20wy%C5%82%C4%85czanie%20obs%C5%82ugi%20ciasteczek">Firefox</a></li>
        <li><a href="http://help.opera.com/Windows/12.10/pl/cookies.html">Opera</a></li>
      </ul>
      <p>Urządzenia mobilne:</p>
      <ul>
        <li><a href="http://support.google.com/chrome/bin/answer.py?hl=pl&amp;answer=95647">Android</a></li>
        <li><a href="http://support.apple.com/kb/HT1677?viewlocale=pl_PL">Safari (iOS)</a></li>
        <li><a href="http://www.windowsphone.com/pl-pl/how-to/wp7/web/changing-privacy-and-other-browser-settings">Windows Phone</a></li>
      </ul>
    </ol>




	</div>
	</div>

    </div>
</template>


<script>

export default {
  name: 'Legal',
  data () {
    return {
      user: {},
    }
  },

}
</script>

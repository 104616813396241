<script>

  import { authBasicHeader } from '/src/_helpers/auth-header.js'
  import VueRecaptcha from 'vue-recaptcha'

  const URL_LOGIN = 'https://apiprzewoz.cuslapy.pl/user/reset_password/'

  export default {
    name: 'ResetPassword',
    components: { VueRecaptcha },
    data() {
      return {
        user: null,
        form: {
          email: '',
          password: ''
        },
        errors: null,
        success: false,
        submitted: false,
        show: true,
        spinner: false,
        captcha_status: false
      }
    },
    mounted() {},
    methods: {
      onVerifyCaptcha: function(response) {
        if (response) {
          this.captcha_status = true
        }
        console.log('Verify: ' + response)
      },
      onSubmit(event) {
        event.preventDefault()
        if (!this.captcha_status) {
          this.errors = 'Zaznacz kod wyeryfikujący, że nie jesteś robotem'
          return false
        }
        this.spinner = true
        this.submitted = true

        const requestOptions = {
          method: 'POST',
          headers: authBasicHeader(),
          body: JSON.stringify(this.form)
        }
        fetch(URL_LOGIN, requestOptions)
          .then(response => response.json())
          .then(json => {
            console.log(json)
            if (json.status != 'ok') {
              this.errors = json['message']
            }
            this.errors = false
            this.success = true
            this.show = false
            this.spinner = false
          })
      }
    },
    computed: {}
  }

</script>

<template>

  <div class="text-left">

    <h2>Resetuj hasło</h2>

    <b-alert v-if="errors" show variant="danger">{{this.errors}}</b-alert>
    <b-alert show variant="success" v-if="success">Twoje hasło zresetowane. Na email otrzymasz intrukcję resetowania hasła.</b-alert>

    <b-form @submit="onSubmit" v-if="!success">

      <br>
      <br>
      <label for="feedback-user">Email</label>
      <b-form-input id="email"
                    v-model="form.email"
                    type="email"
                    placeholder=""
                    aria-describedby="email-help-block"
                    required></b-form-input>
      <b-form-text id="email-help-block">
        Podaj email, który podałeś przy rejestracji w serwisie
      </b-form-text>
      <p>
      </p>
      <p>
        <vue-recaptcha @verify="onVerifyCaptcha" sitekey="6LdEQNIaAAAAADc_zixlNhMGdF95wFaANiTMQtE9" :loadRecaptchaScript="true"></vue-recaptcha>
      </p>
      <b-button type="submit" variant="primary">Resetuj hasło</b-button>

    </b-form>

    <div class="clearfix" v-if="spinner">
      <b-spinner class="float-right" label="Floated Right"></b-spinner>
    </div>

  </div>

</template>

<template>
    <div class="home">

        <b-button v-if="can_add_order" to="/new" variant="outline-primary">Dodaj nowe zgłoszenie</b-button>

        <hr class="my-4">
        <div v-if="show" class="text-left">
          <h5>Lista aktywnych zamówień {{this.today_name}} ({{length}})</h5>
          <b-list-group>
              <b-list-group-item :id="item.public_uid" :variant="item.status.color" v-bind:to="get_order_url + item.public_uid" :border-variant="item.state_color" class="flex-column align-items-start" v-for="item in items" :key="item.public_uid">

                <div  class="d-flex w-100 justify-content-between">
                  <h5 class="mb-1"><b-icon-arrow-right-square></b-icon-arrow-right-square> [{{ item.id }}] {{item.where.city}}, {{item.where.street}} {{item.where.nr}} <small>({{item.client.username}})</small></h5>
                  <small><timeago :datetime="item.datetime_start"></timeago> <b-icon-hourglass-split></b-icon-hourglass-split></small>
                </div>

                <div  class="d-flex w-100 justify-content-between">
                  <h6 class="mb-1"><b-icon-stop></b-icon-stop>  {{item.whence.city}}, {{item.whence.street}} {{item.whence.nr}}</h6>
                  <span>{{item.status.text}}</span>

                </div>
                <div  class="d-flex w-100 justify-content-between">
                  <h6 class="mb-1"><b-icon-info-square></b-icon-info-square>  {{item.info}}</h6>
                  <span></span>

                </div>
                <div v-if="item.original !== null"><b-icon-arrow-return-left></b-icon-arrow-return-left> przejazd powrotny</div>
                <div v-if="item.comeback.length !== 0"><b-icon-arrow-return-right></b-icon-arrow-return-right> posiada powrót</div>




              </b-list-group-item>
          </b-list-group>
        </div>
        <div v-else>
          Nie posiadasz jeszcze żadnych zgłoszeń.
        </div>

        <div class="clearfix" v-if="spinner">
          <b-spinner class="float-right" label="Floated Right"></b-spinner>
        </div>
    </div>
</template>


<script>
import { authHeader, check_user } from '/src/_helpers/auth-header.js'


export default {
  name: 'DriverDelivers',
  data () {
    return {
      items: [],
      show: false,
      user: {},
      spinner: true,
      today: null,
      today_name: "",
      length: 0,
    }
  },
  watch: {
    '$route': 'fetchData'
  },
  methods: {
    fetchData() {
      this.user = check_user()
      this.today_name = ""
      var URL = "https://apiprzewoz.cuslapy.pl/transport/client_transport/"

      this.today = this.$route.query.today

      if (this.today) {
        URL += "?today=true"
        this.today_name = "na dzisiaj"
      }

      if (this.user.account_type == 2) {
        window.location.href = '/cms/delivers/1/'
      }

      fetch(URL, {'headers': authHeader()})
        .then(function(response) {
          if (response.status == 401) {
            console.log(response.status);
            localStorage.removeItem('user')
            location.reload()
          }
          else {
            response.json().then(function(json) {
              console.log(json);
              this.items = json.data
              this.length = json.data.length
              this.show = true ? (json.data.length > 0) : false
            }.bind(this))
          }
        }.bind(this));
    }
  },
  mounted () {
    this.fetchData()
    this.spinner = false
  },
    // get_noactive_list () {
    //   return {"items": [{"message": "Zamówienie 3", "state": "disabled"}]}
    // }
    computed: {
      get_order_url() {
        if (this.user.account_type == 3) {
          return "/driver_order/"
        }
        return "/order/"
      },
      can_add_order() {
        return this.user.account_type == 1
      }
    },
}
</script>

<template>
    <div class="text-left">

      <b-button v-b-toggle.stats1 >Statystyki: miejsca docelowe</b-button>
      <b-collapse id="stats1" class="mt-2">
        <b-card>
          <h5>Centrum Integracji Społecznej (CIS) <b-badge>{{results.stats1.k_1}}</b-badge></h5>
          <h5>Klub Integracji Społecznej (KIS) <b-badge>{{results.stats1.k_2}}</b-badge></h5>
          <h5>Dzienny Dom Senior + <b-badge>{{results.stats1.k_3}}</b-badge></h5>
          <h5>Klub Senior + <b-badge>{{results.stats1.k_4}}</b-badge></h5>
          <h5>Środowiskowy Dom Samopomocy (ŚDS) <b-badge>{{results.stats1.k_12}}</b-badge></h5>
          <h5>Uniwersytet III Wieku <b-badge>{{results.stats1.k_5}}</b-badge></h5>
          <h5>Centrum opiekuńczo - mieszkalne <b-badge>{{results.stats1.k_6}}</b-badge></h5>
          <h5>OKF <b-badge>{{results.stats1.k_7}}</b-badge></h5>
          <h5>Placówki służby zdrowia <b-badge>{{results.stats1.k_8}}</b-badge></h5>
          <h5>Placówki kulturalne (Dom Kultury, kino, teatr) <b-badge>{{results.stats1.k_9}}</b-badge></h5>
          <h5>Placówki edukacyjne <b-badge>{{results.stats1.k_10}}</b-badge></h5>
          <h5>Inne <b-badge>{{results.stats1.k_11}}</b-badge></h5>
        </b-card>
      </b-collapse>

      <hr>

      <b-button v-b-toggle.stats2 >Statystyki: cel podróży</b-button>
      <b-collapse id="stats2" class="mt-2">
        <b-card>
          <h5>społeczny <b-badge>{{results.stats2.k_1}}</b-badge></h5>
          <h5>zawodowy <b-badge>{{results.stats2.k_2}}</b-badge></h5>
          <h5>edukacyjny <b-badge>{{results.stats2.k_3}}</b-badge></h5>
          <h5>zdrowotny <b-badge>{{results.stats2.k_4}}</b-badge></h5>

        </b-card>
      </b-collapse>

      <hr>

      <b-button v-b-toggle.stats3 >Statystyki: czy zamawiający będzie korzystać z usługi transportowej</b-button>
      <b-collapse id="stats3" class="mt-2">
        <b-card>
          <h5>samodzielnie <b-badge>{{results.stats3.k_1}}</b-badge></h5>
          <h5>z opiekunem/opiekunką asystentem/asystentką osoby z niepełnosprawnościami, osobą zależną od użytkownika/użytkowniczki <b-badge>{{results.stats3.k_2}}</b-badge></h5>
          <h5>z psem asystującym <b-badge>{{results.stats3.k_3}}</b-badge></h5>

        </b-card>
      </b-collapse>

        <hr>

      <b-button v-b-toggle.stats4 >Statystyki: czy potrzebna jest pomoc w dotarciu z mieszkania do pojazdu</b-button>
      <b-collapse id="stats4" class="mt-2">
        <b-card>
          <h5>Tak <b-badge>{{results.stats4.k_1}}</b-badge></h5>
          <h5>Nie <b-badge>{{results.stats4.k_2}}</b-badge></h5>

        </b-card>
      </b-collapse>
  <hr>

      <b-button v-b-toggle.stats5 >Statystyki: najczęstrze miasta</b-button>
      <b-collapse id="stats5" class="mt-2">
        <b-card>
          <ul>
          <li v-for="(val, index) in results.stats5" v-bind:key="index">
              <h5>{{index}} <b-badge>{{val}}</b-badge></h5>
          </li>
          </ul>

        </b-card>
      </b-collapse>

      <hr>

      <b-button v-b-toggle.stats6 >Statystyki: częstotliwość kursów w poszczególne dni tygodnia</b-button>
      <b-collapse id="stats6" class="mt-2">
        <b-card>
          <ul>
          <li v-for="(val, index) in results.stats6.datas" v-bind:key="index">
              <h5>{{index}} <b-badge>{{val}}</b-badge></h5>
          </li>
          </ul>

        </b-card>
      </b-collapse>



      <div class="text-center" v-if="spinner">
          <b-spinner variant="primary" label="Text Centered"></b-spinner>
      </div>
    </div>
</template>


<script>

import { authHeaderGet } from '/src/_helpers/auth-header.js'

export default {
  name: 'StatsByTransports',
  data () {
    return {
      user: {},
      spinner: false,
      stats1: {},
      results: {"stats1": {}, "stats2": {}, "stats3": {}, "stats4": {}, "stats5": {}, "stats6": {}},
    }
  },
  mounted() {
   this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
     if ( collapseId=="stats1" && isJustShown) {
        var url = "https://apiprzewoz.cuslapy.pl/stats/admin/stats_by_transports/destination_name/"
        this.fetchStats("stats1", url)
     }
     else if ( collapseId=="stats2" && isJustShown) {
        var url = "https://apiprzewoz.cuslapy.pl/stats/admin/stats_by_transports/destination_list/"
        this.fetchStats("stats2", url)
     }
     else if ( collapseId=="stats3" && isJustShown) {
        var url = "https://apiprzewoz.cuslapy.pl/stats/admin/stats_by_transports/type_help_list/"
        this.fetchStats("stats3", url)
     }
     else if ( collapseId=="stats4" && isJustShown) {
        var url = "https://apiprzewoz.cuslapy.pl/stats/admin/stats_by_transports/need_help_in_deliver/"
        this.fetchStats("stats4", url)
     }
     else if ( collapseId=="stats5" && isJustShown) {
        var url = "https://apiprzewoz.cuslapy.pl/stats/admin/stats_by_transports/where_city/"
        this.fetchStats("stats5", url)
     }
     else if ( collapseId=="stats6" && isJustShown) {
        var url = "https://apiprzewoz.cuslapy.pl/stats/admin/stats_freq_by_week_and_hours/"
        this.fetchStats("stats6", url)
     }


     //console.log('collapseId:', collapseId)
     //console.log('isJustShown:', isJustShown)
   })
 },
  methods: {
    fetchStats(name_stats, url) {
      this.spinner = true
      fetch(url, {'headers': authHeaderGet()})
        .then(function(response) {
          response.json().then(function(json) {
            this.spinner = false
            this.results[name_stats] = json
          }.bind(this))

        }.bind(this));


    }

  }

}
</script>

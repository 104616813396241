<script>
  import { check_user, authBasicHeader } from '/src/_helpers/auth-header.js'

  const URL_LOGIN = "https://apiprzewoz.cuslapy.pl/user/login"

  export default {
    name: 'Login',
    data() {
      return {
        user: null,
        form: {
          email: '',
          password: '',
        },
        errors: 0,
        success: 0,
      }
    },
    mounted() {
      this.user = check_user()
    },
    methods: {
     onSubmit(event) {
       event.preventDefault()
       //alert(JSON.stringify(this.form))

       const requestOptions = {
        method: 'POST',
        headers: authBasicHeader(),
        body: JSON.stringify(this.form)
        };
        fetch(URL_LOGIN, requestOptions)
            .then(response => response.json())
            .then(json => {
              console.log(json);
              if (json.status != "ok") {
                this.errors = "Błąd logowania"
                if (json.message == "bad_password") {
                  this.errors = "Podany email lub hasło są nieprawidłowe"
                }
                else if (json.message == "user_not_exists") {
                  this.errors = "Podany użytkownik nie istnieje"
                }
                else if (json.message == "account_not_confirmed") {
                  this.errors = "Nie potwierdziłeś jeszcze aktywacji konta. Potwierdź klikając na link przesłany w wiadomości e-mail"
                }
                return;
              }
              console.log(json);
              this.errors = 0;
              this.success = 1;
              this.user = json.user;
              localStorage.setItem('user', JSON.stringify(json.user))
              //this.$router.push('/')
              let redirect_url = localStorage.getItem('redirect_url')
              if (redirect_url) {
                localStorage.removeItem('redirect_url')
                window.location.href = redirect_url
              }
              else {
                window.location.href = '/'
              }
            });

         },
         onReset(event) {
           event.preventDefault()
           // Reset our form values
           this.form.email = ''
           this.form.password = ''
         },
   },
   computed: {
    validation_email() {
      return this.form.email.length > 4 && this.form.email.length < 13
    }
  }
  }

</script>

<template>

  <div v-if="user">
    <div class="text-center">
      Jesteś zalogowany
    </div>

  </div>
  <div  v-else>
    <h2>Logowanie</h2>
    <b-alert v-if="errors" show variant="danger">{{this.errors}}</b-alert>
    <b-alert show variant="success" v-if="success">Zostałeś zalogowany</b-alert>

    <b-form @submit="onSubmit" @reset="onReset" v-if="!user">
        <label for="feedback-user">Email</label>
        <b-form-input id="email"
                      v-model="form.email"
                      type="email"
                      placeholder="Podaj swój email"
                      aria-describedby="email-help-block"
                      required></b-form-input>
        <b-form-text id="email-help-block">
          Twój email podczas rejestarcji w serwisie
        </b-form-text>


    <label for="text-password">Hasło</label>
     <b-form-input type="password" id="text-password" v-model="form.password" aria-describedby="password-help-block"></b-form-input>

     <p>
     </p>
     <p>
       <b-button type="submit" variant="primary">Zaloguj się</b-button>
       <b-button to="/resetuj_haslo/"  variant="light">Resetuj hasło</b-button>
    </p>


    </b-form>
  </div>

</template>

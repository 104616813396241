<template>
    <div class="text-left">





<b>Informacja Administratora w związku z przetwarzaniem danych osobowych – zgodnie z art. 13 ust. 1 i 2 Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (zwanego dalej RODO):</b>
<br><br>
  <ol>
        <li>Administratorem Pani/Pana danych osobowych jest Miejski Ośrodek Pomocy Społecznej w Łapach, reprezentowane przez Dyrektora, z siedzibą przy ul. Głównej 50, 18-100 Łapy, tel. +48 (85) 715 25 50, e-mail: kontakt@cuslapy.pl </li>
        <li>Administrator, zgodnie z art. 37 ust. 1 lit. a) RODO, powołał Inspektora Ochrony Danych, z którym w sprawach związanych z przetwarzaniem danych osobowych, może się Pani/Pan kontaktować za pomocą poczty elektronicznej pod adresem: kontakt@cuslapy.pl </li>
        <li>Pani/Pana dane osobowe przetwarzane będą w celu rejestracji konta w portalu przewoz.cuslapy.pl oraz umożliwienie rezerwacji usługi indywidualnego transportu door-to-door w Gminie Łapy za pośrednictwem tego portalu, na podstawie wyrażonej zgody (art. 6 ust. 1 lit. a RODO).</li>
        <li>Odbiorcami Pani/Pana danych osobowych mogą być dostawcy usług informatycznych Administratora, obsługa prawna Administratora oraz inne podmioty uprawnione na podstawie przepisów prawa. </li>
        <li>Dane osobowe będą przetwarzane do czasu cofnięcia zgody lub zaprzestania przez Administratora świadczenia usługi indywidualnego transportu door-to-door w Gminie Łapy. W celu usunięcia konta należy wysłać email na adres kontakt@cuslapy.pl z prośbą o usunięcie konta z adresu na którym nastąpiła rejestracja w serwisie, podając też login</li>
        <li>Przysługuje Pani/Panu prawo dostępu do treści swoich danych, do sprostowania danych, do usunięcia danych oraz do ograniczenia przetwarzania danych.</li>
        <li>Przysługuje Pani/Panu prawo do cofnięcia zgody w dowolnym momencie bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem.</li>
        <li>Gdy uzna Pani/Pan, że przetwarzanie danych osobowych narusza powszechnie obowiązujące przepisy w tym zakresie, przysługuje Pani/Panu prawo do wniesienia skargi do organu nadzorczego - Prezes Urzędu Ochrony Danych Osobowych.</li>
        <li>Podanie danych osobowych jest dobrowolne, jednak niezbędne do rejestracji w portalu przewoz.cuslapy.pl oraz umożliwienia rezerwacji usługi indywidualnego transportu door-to-door w Gminie Łapy za pośrednictwem tego portalu.</li>
        <li>Dane osobowe nie będą wykorzystywane do zautomatyzowanego podejmowania decyzji ani profilowania, o którym mowa w art. 22.</li>
</ol>
    </div>
</template>


<script>

export default {
  name: 'Clausure',
  data () {
    return {
      user: {},
    }
  },

}
</script>

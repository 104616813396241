<script>

  import { check_user, authHeader } from '/src/_helpers/auth-header.js'

  const URL_LOGIN = 'https://apiprzewoz.cuslapy.pl/user/change_password/'

  export default {
    name: 'ChangePassword',
    data() {
      return {
        user: null,
        form: {
          email: '',
          password: ''
        },
        errors: null,
        success: false,
        rules: [
				{ message:'przynajmniej jedna mała litera,', regex:/[a-z]+/ },
				{ message:"przynajmniej jedna wielka litera,",  regex:/[A-Z]+/ },
				{ message:"minimum 8 znaków,", regex:/.{8,}/ },
				{ message:"wymagana jedna cyfra,", regex:/[0-9]+/ }
			  ],
        submitted: false,
        show: true
      }
    },
    mounted() {
      this.user = check_user()
    },
    methods: {
      onSubmit(event) {
        this.submitted = true
        event.preventDefault()
        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(this.form)
        }
        fetch(URL_LOGIN, requestOptions)
          .then(response => response.json())
          .then(json => {
            console.log(json)
            if (json.status != 'ok') {
              this.errors = json['message']
            }
            this.user = json.user;
            localStorage.setItem('user', JSON.stringify(json.user))
            this.errors = false
            this.success = true
            this.show = false
          })
      },
      onReset(event) {
        event.preventDefault()
        // Reset our form values
        this.form.password2 = ''
        this.form.password = ''
      },
      validate() {
        if (!this.validate_passwordValidation()) {
          this.valid = false
        }
        this.valid = true
      },
      validate_passwordValidation() {
        let errors = []
        if (this.form.password !== this.form.password2) {
          return false
        }
        for (let condition of this.rules) {
          if (!condition.regex.test(this.form.password)) {
            errors.push(condition.message)
          }
        }
        if (errors.length === 0) {
          return true
        } else {
          return false
        }
      }
    },
    computed: {
      password_validation() {
        if (this.form.password)
          return this.form.password == this.form.password2
        return null
      },
      passwordValidation() {
        let errors = []
        for (let condition of this.rules) {
          if (!condition.regex.test(this.form.password)) {
            errors.push(condition.message)
          }
        }
        if (errors.length === 0) {
          return {
            valid: true,
            errors
          }
        } else {
          return {
            valid: false,
            errors
          }
        }
      },
      passwordValidationStatus() {
        let errors = []
        if (this.form.password == '') {
          return null
        }
        for (let condition of this.rules) {
          if (!condition.regex.test(this.form.password)) {
            errors.push(condition.message)
          }
        }
        if (errors.length === 0) {
          return true
        } else {
          return false
        }
      }
    }
  }

</script>

<template>

  <div v-if="user">

    <h2>Zmiana hasła</h2>

    <b-alert v-if="errors" show variant="danger">{{this.errors}}</b-alert>
    <b-alert show variant="success" v-if="success">Twoje hasło zostało poprawnie zmienione. Może wylogować się i zalogować ponownie.</b-alert>

    <b-form @submit="onSubmit" @reset="onReset" v-if="!success">
      <b-form-group
        id="input-group-3"
        label="Hasło"
        label-for="password"
        description=""
      >
        <b-form-input
          id="password"
          v-model="form.password"
          type="password"
          placeholder=""
          :state="passwordValidationStatus"
          required
        ></b-form-input>
      </b-form-group>

      <div v-if='passwordValidation.errors.length > 0 && submitted' class="d-block invalid-feedback">
        <small v-for='error in passwordValidation.errors' v-bind:key="error">{{error}} </small>
      </div>

      <div v-if='passwordValidation.errors.length > 0 && !submitted' class="d-block ">
      <small v-for='error in passwordValidation.errors' v-bind:key="error">{{error}} </small>
    </div>
    <br>

      <b-form-group
        id="input-group-3"
        label="Powtórz hasło"
        label-for="password2"
        description=""
      >
        <b-form-input
          id="password2"
          v-model="form.password2"
          type="password"
          placeholder=""
          :state="password_validation"
          required
        ></b-form-input>

        <b-form-invalid-feedback :state="password_validation">
          Pole hasło i powtórz hasło muszą być identyczne
        </b-form-invalid-feedback>
        <b-form-valid-feedback :state="password_validation">
          Hasła są identyczne
        </b-form-valid-feedback>

        </b-form-group>
      <b-button type="submit" variant="primary">Zmień hasło</b-button>
      <b-button type="reset" variant="danger">Wyczyść</b-button>

    </b-form>
  </div>

</template>
